package com.koduok.lists.component.content

import androidx.compose.foundation.ScrollState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.Surface
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppShapes

@Composable
fun SmallContentSurface(
    modifier: Modifier = Modifier,
    scrollState: ScrollState = rememberScrollState(),
    content: @Composable () -> Unit,
) = Surface(
    modifier,
    color = AppColors.background,
    contentColor = AppColors.onBackground,
    shape = AppShapes.large,
    content = {
        Box(Modifier.verticalScroll(scrollState).padding(horizontal = 24.dp, vertical = 32.dp)) {
            content()
        }
    },
)
