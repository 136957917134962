package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppColors
import lists.shared_compose.generated.resources.Res
import lists.shared_compose.generated.resources.facebook
import lists.shared_compose.generated.resources.instagram
import lists.shared_compose.generated.resources.logo
import org.jetbrains.compose.resources.vectorResource

@Composable
internal fun HomeScreenFooter(
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
) {
    Row(Modifier.padding(horizontal = 16.dp).fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween, verticalAlignment = Alignment.CenterVertically) {
        Icon(vectorResource(Res.drawable.logo), "Logo", Modifier.size(56.dp), tint = AppColors.primary)
        Column(horizontalAlignment = Alignment.End) {
            Row {
                IconButton(onClickSocialFacebook) {
                    Icon(vectorResource(Res.drawable.facebook), "Facebook", Modifier.size(24.dp))
                }
                IconButton(onClickSocialInstagram) {
                    Icon(vectorResource(Res.drawable.instagram), "Instagram", Modifier.size(24.dp))
                }
            }
            SelectionContainer {
                Text("dovanusarasas@gmail.com")
            }
        }
    }
}
