package com.koduok.lists.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import com.arkivanov.essenty.instancekeeper.InstanceKeeper
import com.arkivanov.essenty.instancekeeper.getOrCreateSimple
import com.koduok.lists.ext.LocalComponentContext
import com.koduok.lists.feature.LoadedValue
import com.koduok.lists.routes.ResultRoute
import com.koduok.lists.routes.Route

internal const val REQUESTS_KEY = "REQUESTS_KEY"

@Composable
fun <Result, R> rememberResultRequest(
    onNoResult: () -> Unit = {},
    onResult: (Result) -> Unit,
): ResultRequest<Result, R> where R : Route, R : ResultRoute<Result> {
    val router = LocalRouter.current
    val instanceKeeper = LocalComponentContext.current.instanceKeeper

    DisposableEffect(Unit) {
        // If there is a passive request, trigger it immediately with the result
        val requestsOnEnter = instanceKeeper.getRequestsOrNull()
        if (requestsOnEnter != null) {
            val passiveRequest = requestsOnEnter.remove<Result>() as? PassiveRequest<Result>
            if (passiveRequest != null) {
                if (passiveRequest.hasResult()) {
                    onResult(passiveRequest.requireResult())
                } else {
                    onNoResult()
                }
            }
        }

        onDispose {
            // If there is an active request, replace it with a passive request
            val requestsOnExit = instanceKeeper.getRequestsOrNull()
            if (requestsOnExit != null) {
                val activeRequest = requestsOnExit.remove<Result>() as? ActiveRequest<Result>
                if (activeRequest != null) {
                    requestsOnExit.put(PassiveRequest<Result>())
                }
            }
        }
    }

    return remember { ResultRequest(router, instanceKeeper, onResult) }
}

class ResultRequest<Result, R>(
    private val router: Router,
    private val instanceKeeper: InstanceKeeper,
    private val onResult: (Result) -> Unit,
) where R : Route, R : ResultRoute<Result> {
    fun request(route: R) {
        val requests = instanceKeeper.getOrCreateSimple(REQUESTS_KEY) { Requests() }
        requests.put(ActiveRequest(onResult))
        router.push(route)
    }
}

@Suppress("UNCHECKED_CAST")
class Requests : InstanceKeeper.Instance {
    private val requests: MutableList<Request<*>> = mutableListOf()

    fun <Result> put(request: Request<Result>) {
        requests.removeAll { (it as? Request<Result>) != null }
        requests.add(request)
    }

    fun <Result> remove(): Request<Result>? {
        val request = requests.firstOrNull { it as? Request<Result> != null } as? Request<Result>
        if (request != null) {
            requests.remove(request)
        }

        return request
    }

    fun <Result> setResult(result: Result) {
        requests.forEach { (it as? Request<Result>)?.setResult(result) }
        requests.removeAll { (it as? ActiveRequest<Result>) != null }
    }
}

sealed class Request<Result> {
    abstract fun setResult(result: Result)
}

private data class ActiveRequest<Result>(val onResult: (Result) -> Unit) : Request<Result>() {
    override fun setResult(result: Result) {
        onResult(result)
    }
}

private class PassiveRequest<Result> : Request<Result>() {
    private var result: LoadedValue<Result>? = null

    override fun setResult(result: Result) {
        this.result = LoadedValue(result)
    }

    fun hasResult(): Boolean = result != null

    fun requireResult(): Result = result!!.value
}

internal fun InstanceKeeper.getRequestsOrNull() = (get(REQUESTS_KEY) as? InstanceKeeper.SimpleInstance<*>)?.instance as? Requests
