package com.koduok.lists.ext

import com.koduok.lists.logging.Logger
import kotlinx.coroutines.CancellationException

inline fun <T> Result<T>.onFailureLogNonFatal(
    errorDescription: String? = null,
    action: (exception: Throwable) -> Unit = {},
): Result<T> =
    onFailure {
        if (it is CancellationException) return@onFailure
        it.logNonFatal(errorDescription)
        action(it)
    }

fun Throwable.logNonFatal(description: String? = null) {
    runCatching {
        Logger.error(description ?: "Unknown error", this)
    }
}