package com.koduok.lists.component

import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.LinkAnnotation
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextLinkStyles
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.style.TextAlign
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppType

@Composable
fun TermsAndPrivacyText(
    onClickPrivacyPolicy: () -> Unit,
    onClickTermsAndConditions: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val primaryColor = AppColors.primary
    Text(
        text = buildAnnotatedString {
            val privacyPolicy = "Privatumo Politika"
            val termsAndConditions = "Naudojimosi sąlygomis"
            val text = "Prisijungdami patvirtinate, kad sutinkate su $privacyPolicy ir $termsAndConditions"
            val privacyPolicyIndex = text.indexOf(privacyPolicy)
            val termsAndConditionsIndex = text.indexOf(termsAndConditions)
            append(text)
            addLink(
                clickable = LinkAnnotation.Clickable(
                    tag = "privacy-policy",
                    styles = TextLinkStyles(style = SpanStyle(color = primaryColor)),
                    linkInteractionListener = { onClickPrivacyPolicy() }
                ),
                start = privacyPolicyIndex,
                end = privacyPolicyIndex + privacyPolicy.length,
            )

            addLink(
                clickable = LinkAnnotation.Clickable(
                    tag = "terms-and-conditions",
                    styles = TextLinkStyles(style = SpanStyle(color = primaryColor)),
                    linkInteractionListener = { onClickTermsAndConditions() }
                ),
                start = termsAndConditionsIndex,
                end = termsAndConditionsIndex + termsAndConditions.length,
            )
        },
        style = AppType.bodySmall,
        textAlign = TextAlign.Center,
        modifier = modifier,
    )
}