package com.koduok.lists.model

import com.koduok.lists.errors.NotLoggedInError
import com.koduok.lists.ext.fBoolean
import com.koduok.lists.ext.fRandomListOf
import com.koduok.lists.ext.fRandomOf
import com.koduok.lists.ext.fTextWord
import com.koduok.lists.model.AuthenticationProvider.*

sealed class AppUser {
    val isLoggedIn get() = this is AuthenticatedAppUser
    val pictureOrNull get() = (this as? AuthenticatedAppUser)?.picture
    val idOrNull get() = (this as? AuthenticatedAppUser)?.id
    val requireId get() = (this as? AuthenticatedAppUser)?.id ?: throw NotLoggedInError

    fun matches(userId: UserId?) = userId != null && this is AuthenticatedAppUser && this.id == userId
}

data object LoadingAppUser : AppUser()
data object NotAuthenticatedAppUser : AppUser()
data object FailedAppUser : AppUser()
data class AuthenticatedAppUser(
    val id: UserId,
    val providers: List<AuthenticationProvider>,
) : AppUser() {
    val emailProvider = providers.filterIsInstance<EmailProvider>().firstOrNull()
    val googleProvider = providers.filterIsInstance<GoogleProvider>().firstOrNull()
    val facebookProvider = providers.filterIsInstance<FacebookProvider>().firstOrNull()

    val picture = googleProvider?.picture ?: facebookProvider?.picture
    val email = emailProvider?.email ?: googleProvider?.email ?: facebookProvider?.email
    val name = googleProvider?.name ?: facebookProvider?.name
}

sealed class AuthenticationProvider {
    data class EmailProvider(
        val email: Email,
        val emailConfirmed: Boolean,
    ) : AuthenticationProvider()

    data class GoogleProvider(
        val email: Email,
        val name: String,
        val picture: UrlImage?,
    ) : AuthenticationProvider()

    data class FacebookProvider(
        val email: Email,
        val name: String,
        val picture: UrlImage?,
    ) : AuthenticationProvider()
}

val AppUser?.isLoggedIn get() = this is AuthenticatedAppUser

fun fAuthenticatedAppUser(
    providers: List<AuthenticationProvider> = fRandomListOf(min = 1, max = 3) { fAuthenticationProvider() }.distinctBy { it::class },
) = AuthenticatedAppUser(
    id = fUserId(),
    providers = providers,
)

fun fAuthenticationProvider() = fRandomOf(
    { fEmailProvider() },
    { fGoogleProvider() },
    { fFacebookProvider() },
)

fun fEmailProvider() = EmailProvider(
    email = fEmail(),
    emailConfirmed = fBoolean(),
)

fun fGoogleProvider(
    picture: UrlImage? = fUrlImage(),
) = GoogleProvider(
    email = fEmail(),
    name = fTextWord(),
    picture = picture,
)

fun fFacebookProvider() = FacebookProvider(
    email = fEmail(),
    name = fTextWord(),
    picture = fUrlImage(),
)
