package com.koduok.lists.ext

import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.drawscope.ContentDrawScope
import androidx.compose.ui.node.DrawModifierNode
import androidx.compose.ui.node.ModifierNodeElement
import androidx.compose.ui.platform.InspectorInfo

fun Modifier.optionalDraw(draw: Boolean) = this then OptionalDrawElement(draw)

private data class OptionalDrawElement(val draw: Boolean) : ModifierNodeElement<OptionalDrawNode>() {
    override fun create(): OptionalDrawNode = OptionalDrawNode(draw)

    override fun update(node: OptionalDrawNode) {
        node.draw = draw
    }

    override fun InspectorInfo.inspectableProperties() {
        name = "optionalDraw"
        properties["draw"] = draw
    }
}

private class OptionalDrawNode(var draw: Boolean) : DrawModifierNode, Modifier.Node() {
    override fun ContentDrawScope.draw() {
        if (draw) {
            drawContent()
        }
    }
}
