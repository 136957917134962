package com.koduok.lists.navigation

data class Path(private val full: String) {
    val size get() = pathParts.size

    private val pathParts = full.removePrefix("/")
        .let {
            when {
                it.contains("?") -> it.split("?").first()
                it.contains("#") -> it.split("#").first()
                else -> it
            }
        }
        .split("/")

    private val queryParams = full
        .let {
            when {
                it.contains("?") -> it.split("?").last()
                it.contains("#") -> it.split("#").last()
                else -> null
            }
        }
        ?.split("&")
        ?.mapNotNull {
            val param = it.split("=")
            val key = param.getOrNull(0)
            val value = param.getOrNull(1)
            if (key != null && value != null) {
                key to value
            } else {
                null
            }
        }
        ?.toMap()
        .orEmpty()

    val fragment = full
        .takeIf { it.contains("#") }
        ?.split("#")
        ?.last()

    val queryParamsSize = queryParams.size

    operator fun get(index: Int) = pathParts.getOrNull(index)
    fun queryParam(key: String): String? = queryParams[key]
}