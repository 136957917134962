package com.koduok.lists.feature.login

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.rounded.Email
import androidx.compose.material.icons.rounded.Password
import androidx.compose.material.icons.rounded.Visibility
import androidx.compose.material.icons.rounded.VisibilityOff
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.ComponentContext
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.SectionDivider
import com.koduok.lists.component.TermsAndPrivacyText
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.button.OutlinedButton
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.content.Content
import com.koduok.lists.component.dialog.Dialog
import com.koduok.lists.component.form.Form
import com.koduok.lists.component.textField.PasswordTransformation
import com.koduok.lists.component.textField.TextField
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.login.LoginViewModel.Effect.*
import com.koduok.lists.feature.login.LoginViewModel.LoginReason
import com.koduok.lists.model.Action
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.routes.ForgotPasswordRoute
import com.koduok.lists.routes.LoginRoute2
import com.koduok.lists.routes.RegisterRoute
import com.koduok.lists.routes.TermsRoute
import com.koduok.lists.theme.AppIcons
import org.koin.core.parameter.parametersOf

@Composable
fun ComponentContext.rememberLoginViewModel(route: LoginRoute2): LoginViewModel {
    return rememberViewModel<LoginViewModel> { parametersOf(route) }
}

@Composable
fun LoginDialog(viewModel: LoginViewModel) {
    val router = LocalRouter.current
    val state by viewModel.collectAsStateAndEffects {
        when (it) {
            ShowForgotPassword -> router.push(ForgotPasswordRoute)
            ShowRegister -> router.push(RegisterRoute)
            is OpenTerms -> router.push(TermsRoute(it.type))
            LoggedIn -> Unit
        }
    }

    if (state.visible) {
        Dialog(
            onDismissRequest = viewModel::hideLogin,
            content = {
                LoginForm(
                    state = state,
                    onClickGoogle = viewModel::onClickGoogleLogin,
                    onClickFacebook = viewModel::onClickFacebookLogin,
                    onEmailChange = viewModel::onEmailChanged,
                    onPasswordChange = viewModel::onPasswordChanged,
                    onToggleShowPassword = viewModel::onToggleShowPassword,
                    onClickLogin = viewModel::onClickLogin,
                    onClickRegister = viewModel::onClickRegister,
                    onClickForgotPassword = viewModel::onClickForgotPassword,
                    onClickErrorAction = viewModel::onClickErrorAction,
                    onClickPrivacyPolicy = viewModel::onClickPrivacyPolicy,
                    onClickTermsAndConditions = viewModel::onClickTermsAndConditions,
                    modifier = Modifier.verticalScroll(rememberScrollState()).padding(24.dp)
                )
            }
        )
    }
}

@Composable
private fun LoginForm(
    state: LoginViewModel.State,
    onClickGoogle: () -> Unit,
    onClickFacebook: () -> Unit,
    onEmailChange: (String) -> Unit,
    onPasswordChange: (String) -> Unit,
    onToggleShowPassword: () -> Unit,
    onClickLogin: () -> Unit,
    onClickRegister: () -> Unit,
    onClickForgotPassword: () -> Unit,
    onClickErrorAction: () -> Unit,
    onClickPrivacyPolicy: () -> Unit,
    onClickTermsAndConditions: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Content(
        state.loginState,
        primaryAction = Action(onClickErrorAction),
        modifier = modifier,
        loaded = {
            Form(
                title = state.reason.title,
                message = state.reason.subtitle,
                fields = {
                    TermsAndPrivacyText(
                        onClickPrivacyPolicy = onClickPrivacyPolicy,
                        onClickTermsAndConditions = onClickTermsAndConditions,
                        modifier = Modifier.padding(bottom = 16.dp),
                    )

                    Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                        OutlinedButton(onClickGoogle, Modifier.weight(1f)) {
                            Text("Google")
                        }

                        OutlinedButton(onClickFacebook, Modifier.weight(1f)) {
                            Text("Facebook")
                        }
                    }

                    SectionDivider("Arba naudok el. paštą")

                    TextField(
                        value = state.email,
                        onValueChange = onEmailChange,
                        modifier = Modifier.fillMaxWidth(),
                        leadingIcon = { Icon(AppIcons.Email, "El. paštas") },
                        singleLine = true,
                        label = { Text("El. paštas") },
                        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Email),
                    )
                    TextField(
                        value = state.password,
                        onValueChange = onPasswordChange,
                        modifier = Modifier.fillMaxWidth().padding(top = 8.dp),
                        leadingIcon = { Icon(AppIcons.Password, "Slaptažodis") },
                        trailingIcon = {
                            IconButton(onToggleShowPassword) {
                                Icon(if (state.showPassword) AppIcons.VisibilityOff else AppIcons.Visibility, null)
                            }
                        },
                        singleLine = true,
                        label = { Text("Slaptažodis") },
                        keyboardOptions = KeyboardOptions(autoCorrectEnabled = false),
                        visualTransformation = if (state.showPassword) VisualTransformation.None else PasswordTransformation,
                    )
                    TextButton(
                        onClick = onClickForgotPassword,
                        modifier = Modifier.align(Alignment.End)
                    ) { Text("Pamiršai slaptažodį?") }

                },
                actions = {
                    Button(
                        onClick = onClickLogin,
                        modifier = Modifier.fillMaxWidth()
                    ) { Text("Prisijungti") }

                    SectionDivider("Neturi paskyros?")

                    TextButton(
                        onClick = onClickRegister,
                        modifier = Modifier.fillMaxWidth()
                    ) { Text("Registruokis") }
                },
            )
        }
    )
}

private val LoginReason.title
    @Composable
    @ReadOnlyComposable
    get() = when (this) {
        LoginReason.UserInitiated -> "Labas!"
        LoginReason.CreateRegistry -> "Reikia prisijungti!"
        LoginReason.CopyRegistry -> "Reikia prisijungti!"
        LoginReason.Finish -> "Labas!"
    }

private val LoginReason.subtitle
    @Composable
    @ReadOnlyComposable
    get() = when (this) {
        LoginReason.UserInitiated -> "Prisijunk ir sukurk savo sąrašą labai paprastai ir nemokamai."
        LoginReason.CreateRegistry -> "Prisijunk, kad neprarastum savo sąrašo."
        LoginReason.CopyRegistry -> "Prisijunk, kad neprarastum savo sąrašo"
        LoginReason.Finish -> "Prisijunk ir sukurk savo sąrašą labai paprastai ir nemokamai."
    }

@Preview
@Composable
private fun Preview() = PreviewContainer {
    LoginForm(
        state = LoginViewModel.State(
            visible = true,
            reason = LoginReason.entries.random(),
            email = "",
            password = "",
            showPassword = false,
            loginState = LoadState.Loaded(Unit)
        ),
        onClickGoogle = {},
        onClickFacebook = {},
        onEmailChange = {},
        onPasswordChange = {},
        onToggleShowPassword = {},
        onClickLogin = {},
        onClickRegister = {},
        onClickForgotPassword = {},
        onClickErrorAction = {},
        onClickPrivacyPolicy = {},
        onClickTermsAndConditions = {},
    )
}
