package com.koduok.lists.ext

import androidx.compose.runtime.Composable
import org.koin.compose.koinInject
import org.koin.core.parameter.ParametersDefinition
import org.koin.core.qualifier.Qualifier

//import androidx.compose.runtime.Composable
//import androidx.compose.runtime.Stable
//import androidx.compose.runtime.remember
//import org.koin.core.parameter.ParametersDefinition
//import org.koin.core.qualifier.Qualifier
//
@Composable
inline fun <reified T : Any> rememberGetInstance(
    qualifier: Qualifier? = null,
    noinline parameters: ParametersDefinition? = null,
): T = koinInject(qualifier, parameters = parameters)

//@Composable
//inline fun <reified T> rememberKoinInject(
//    qualifier: Qualifier? = null,
//    noinline parameters: ParametersDefinition? = null,
//): T {
//    val st = rememberStableParametersDefinition(parameters)
//    return remember(qualifier) {
//        koin.get(qualifier, st.parametersDefinition)
//    }
//}
//
//@Stable
//class StableParametersDefinition(val parametersDefinition: ParametersDefinition?)
//
//@Composable
//fun rememberStableParametersDefinition(
//    parametersDefinition: ParametersDefinition?,
//): StableParametersDefinition = remember { StableParametersDefinition(parametersDefinition) }
