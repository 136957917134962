package com.koduok.lists.model

interface ApiEnvironment {
    val scheme: String
    val host: String
    val port: Int

    fun buildUrl(path: String) = "$scheme://$host:$port/${path.removePrefix("/")}"
}

data object LocalApiEnvironment : ApiEnvironment {
    override val scheme = "http"
    override val host = "localhost"
    override val port = 8080
}

data object RemoteApiEnvironment : ApiEnvironment {
    override val scheme = "https"
    override val host = "party-plan-1337.ey.r.appspot.com"
    override val port = 443
}
