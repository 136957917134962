package com.koduok.lists.component.content

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.model.Action
import com.koduok.lists.theme.AppType

@Composable
fun FullScreenSmallContentMessageLayout(
    modifier: Modifier = Modifier,
    icon: (@Composable () -> Unit)? = null,
    title: (@Composable () -> Unit)? = null,
    message: (@Composable () -> Unit)? = null,
    primaryAction: Action? = null,
    secondaryAction: Action? = null,
) = FullScreenSmallContentBox(modifier) {
    SmallContentSurface {
        Column(
            Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            if (icon != null) {
                icon()
            }

            if (title != null) {
                CompositionLocalProvider(LocalTextStyle provides AppType.titleLarge.copy(textAlign = TextAlign.Center)) {
                    if (icon != null) {
                        Spacer(Modifier.height(16.dp))
                    }
                    title()
                }
            }

            if (message != null) {
                CompositionLocalProvider(LocalTextStyle provides AppType.bodyLarge.copy(textAlign = TextAlign.Center)) {
                    when {
                        title != null -> Spacer(Modifier.height(8.dp))
                        icon != null -> Spacer(Modifier.height(16.dp))
                        else -> Unit
                    }

                    message()
                }
            }

            if (primaryAction != null || secondaryAction != null) {
                Spacer(Modifier.height(24.dp))

                if (primaryAction != null) {
                    Button(primaryAction.action, Modifier.fillMaxWidth()) {
                        Text(primaryAction.label)
                    }
                }

                if (secondaryAction != null) {
                    if (primaryAction != null) {
                        Spacer(Modifier.height(4.dp))
                    }

                    TextButton(secondaryAction.action, Modifier.fillMaxWidth()) {
                        Text(secondaryAction.label)
                    }
                }
            }
        }

    }
}

@Composable
fun FullScreenSmallContentMessage(
    modifier: Modifier = Modifier,
    icon: ImageVector?,
    iconTint: Color = Color.Unspecified,
    title: String? = null,
    message: String? = null,
    primaryAction: Action? = null,
    secondaryAction: Action? = null,
) = FullScreenSmallContentMessageLayout(
    modifier,
    icon = icon?.let { { Icon(it, null, Modifier.size(64.dp), tint = iconTint) } },
    title = title?.let { { Text(it) } },
    message = message?.let { { Text(it) } },
    primaryAction = primaryAction,
    secondaryAction = secondaryAction,
)
