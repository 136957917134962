package com.koduok.lists.ext

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.remember
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.instancekeeper.getOrCreate
import com.koduok.lists.feature.ViewModel
import org.koin.compose.getKoin
import org.koin.core.parameter.ParametersDefinition
import org.koin.core.qualifier.Qualifier

val LocalComponentContext = compositionLocalOf<ComponentContext> {
    throw IllegalStateException("LocalComponentContext is not initialized")
}

@Composable
inline fun <reified T : ViewModel<*, *>> ComponentContext.rememberViewModel(
    qualifier: Qualifier? = null,
    noinline parameters: ParametersDefinition? = null,
): T {
    val koin = getKoin()
    return remember { instanceKeeper.getOrCreate { koin.get(qualifier, parameters) } }
}
