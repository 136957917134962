package com.koduok.lists.supabase.model

import com.koduok.lists.model.*
import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put

@Serializable
data class SupabaseEntry(
    val id: String,
    @SerialName("registry_id") val registryId: String,
    @SerialName("reserved_user_id") val reservedUserId: String?,
    val reserved: Boolean,
    val title: String,
    val url: String?,
    @SerialName("image_url") val imageUrl: String?,
    val price: String?,
    val order: Int,
    @SerialName("created_at") val createdAt: String,
) {
    companion object {
        val columns get() = Columns.raw("id, registry_id, reserved_user_id, reserved, title, url, image_url, price, order, created_at")

        suspend fun save(registryId: RegistryId, entryEdits: List<EntryEdit>, deleteEntryIds: List<EditEntryId>) {
            val json = entryEdits.mapIndexed { index, entryEdit -> createJson(entryEdit, registryId, index) }

            val newEntries = json.filter { it["id"] == null }
            val existingEntries = json.filter { it["id"] != null }

            if (newEntries.isNotEmpty()) {
                runSupabase { postgrest.from("entries").upsert(newEntries) }
            }

            if (existingEntries.isNotEmpty()) {
                runSupabase { postgrest.from("entries").upsert(existingEntries) }
            }

            if (deleteEntryIds.isNotEmpty()) {
                runSupabase {
                    postgrest.from("entries").delete {
                        filter {
                            isIn("id", deleteEntryIds.map { it.value })
                        }
                    }
                }
            }
        }

        private fun createJson(entryEdit: EntryEdit, registryId: RegistryId, order: Int) = buildJsonObject {
            when (entryEdit.editEntryId) {
                is StoredEditEntryId -> put("id", entryEdit.editEntryId.value)
                is NewEditEntryId -> Unit
            }
            put("registry_id", registryId.value)
            put("title", entryEdit.title)
            put("url", entryEdit.url.takeIf { it.isNotBlank() })
            put("image_url", entryEdit.imageUrl.takeIf { it.isNotBlank() })
            put("price", entryEdit.price.takeIf { it.isNotBlank() }?.let { "EUR $it" })
            put("order", order)
        }
    }

    fun toEntry() = Entry(
        id = EntryId(id),
        title = title,
        url = url,
        image = imageUrl?.let { UrlImage(it) },
        reserved = reserved,
        reservedUserId = reservedUserId?.let { UserId(it) },
        price = Money.from(price),
        order = order,
    )
}
