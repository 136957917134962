package com.koduok.lists.feature.registryEdit

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.rounded.Delete
import androidx.compose.material.icons.rounded.MoveDown
import androidx.compose.material.icons.rounded.MoveUp
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.Card
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.media.Image
import com.koduok.lists.ext.formatted
import com.koduok.lists.ext.hazeChildDefault
import com.koduok.lists.ext.urlDomain
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.model.Money
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.AppType
import dev.chrisbanes.haze.HazeDefaults
import dev.chrisbanes.haze.HazeState
import dev.chrisbanes.haze.haze

@Composable
fun EntryEditItem(
    entryEdit: EntryEdit,
    onClick: (EntryEdit) -> Unit,
    onClickMoveUp: (EntryEdit) -> Unit,
    onClickMoveDown: (EntryEdit) -> Unit,
    onClickDelete: (EntryEdit) -> Unit,
    modifier: Modifier = Modifier,
) = Column(modifier.background(AppColors.surfaceVariant, AppShapes.medium).clip(AppShapes.medium)) {
    Card(
        onClick = { onClick(entryEdit) },
        modifier = Modifier.fillMaxWidth().aspectRatio(1f),
        colors = CardDefaults.cardColors(containerColor = AppColors.surfaceVariant, contentColor = AppColors.onSurfaceVariant),
        shape = AppShapes.medium,
        content = {
            Box(Modifier.fillMaxSize()) {
                val hazeState = remember { HazeState() }

                Image(
                    entryEdit.imageUrl,
                    Modifier.matchParentSize().haze(hazeState, HazeDefaults.style(AppColors.surfaceVariant)),
                    contentScale = ContentScale.Crop,
                )

                val domain = entryEdit.url.takeIf { it.isNotEmpty() }?.urlDomain
                if (domain != null) {
                    Text(
                        domain,
                        Modifier.align(Alignment.TopStart).padding(8.dp).background(AppColors.surfaceVariant, shape = AppShapes.extraSmall).padding(4.dp),
                        style = AppType.labelSmall,
                        fontWeight = FontWeight.SemiBold,
                    )
                }

                val price = Money.from(entryEdit.price)
                if (price != null) {
                    Text(
                        price.formatted(),
                        Modifier.align(Alignment.BottomEnd).padding(8.dp).hazeChildDefault(hazeState, shape = AppShapes.extraSmall).padding(4.dp),
                        style = AppType.bodyMedium,
                        textAlign = TextAlign.Center,
                        fontWeight = FontWeight.SemiBold,
                        minLines = 1,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                    )
                }
            }

        }
    )

    Box(Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 16.dp), contentAlignment = Alignment.Center) {
        Text(
            "",
            style = AppType.bodyMedium,
            textAlign = TextAlign.Center,
            fontWeight = FontWeight.Medium,
            minLines = 2,
        )
        Text(
            entryEdit.title,
            style = AppType.bodyMedium,
            textAlign = TextAlign.Center,
            fontWeight = FontWeight.Medium,
            maxLines = 2,
            overflow = TextOverflow.Ellipsis,
        )
    }

    Row(Modifier.fillMaxWidth().padding(top = 8.dp), horizontalArrangement = Arrangement.SpaceEvenly) {
        IconButton(onClick = { onClickMoveUp(entryEdit) }) { Icon(AppIcons.MoveUp, "Move up") }
        IconButton(onClick = { onClickMoveDown(entryEdit) }) { Icon(AppIcons.MoveDown, "Move down") }
        IconButton(onClick = { onClickDelete(entryEdit) }) { Icon(AppIcons.Delete, "Delete") }
    }
}
