package com.koduok.lists.ext

import kotlin.random.Random
import kotlin.time.Duration.Companion.days

fun <T> fRandomOf(vararg generate: () -> T): T {
    return generate.random().invoke()
}

fun <T> fRandomOfOrNull(vararg generate: () -> T): T? {
    val generateNull: () -> T? = { null }
    return generate.toList().plus(generateNull).random().invoke()
}

fun <T> fRandomListOf(min: Int = 1, max: Int = 10, generate: () -> T): List<T> {
    return (1..Random.nextInt(min, max + 1)).map { generate() }
}

fun fDuration() = Random.nextInt(1, 100).days

fun fBoolean() = Random.nextBoolean()
fun fInt() = Random.nextInt()

fun <T> fValueOrNull(generate: () -> T) = if (fBoolean()) generate() else null
