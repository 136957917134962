package com.koduok.lists.model

import com.koduok.lists.navigation.Path
import kotlinx.serialization.Serializable

@Serializable
sealed class ResetPassword {
    companion object {
        fun from(path: Path): ResetPassword? {
            return ResetPasswordError.from(path)?.let { Failed(it) } ?: Pending.from(path)
        }
    }

    @Serializable
    data object Setup : ResetPassword()

    @Serializable
    data class Pending(
        val rawFragment: String,
    ) : ResetPassword() {
        companion object {
            fun from(path: Path): Pending? {
                val rawFragment = path.fragment ?: return null
                return Pending(rawFragment = rawFragment)
            }
        }
    }

    @Serializable
    data class Failed(val error: ResetPasswordError) : ResetPassword()
}

@Serializable
sealed class ResetPasswordError : IllegalStateException() {
    companion object {
        fun from(path: Path): ResetPasswordError? {
            val error = path.queryParam("error") ?: return if (path.queryParamsSize == 0) IllegalEntry else null
            val code = path.queryParam("error_code").orEmpty()
            val description = path.queryParam("error_description").orEmpty()

            return when (error) {
                "access_denied" -> AccessDenied(code, description)
                else -> Unknown(error, code, description)
            }
        }
    }

    @Serializable
    data object IllegalEntry : ResetPasswordError()

    @Serializable
    data class AccessDenied(val code: String, val description: String) : ResetPasswordError() {
        val error = "access_denied"
    }

    @Serializable
    data class Unknown(
        val error: String,
        val code: String,
        val description: String,
    ) : ResetPasswordError()
}
