package com.koduok.lists.feature.forgotPassword

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.rounded.Password
import androidx.compose.material.icons.rounded.Visibility
import androidx.compose.material.icons.rounded.VisibilityOff
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.content.FullScreenSmallContentBox
import com.koduok.lists.component.content.FullScreenSmallContentMessage
import com.koduok.lists.component.content.SmallContentSurface
import com.koduok.lists.component.form.Form
import com.koduok.lists.component.textField.PasswordTransformation
import com.koduok.lists.component.textField.TextField
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.forgotPassword.ResetPasswordViewModel.Effect.OpenHome
import com.koduok.lists.model.Action
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.HomeRoute
import com.koduok.lists.routes.ResetPasswordRoute
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons
import org.koin.core.parameter.parametersOf

val resetPasswordScreen
    get() = screen<ResetPasswordRoute> {
        val router = LocalRouter.current
        val viewModel = rememberViewModel<ResetPasswordViewModel> { parametersOf(it.resetPassword) }
        val state by viewModel.collectAsStateAndEffects { effect ->
            when (effect) {
                OpenHome -> if (router.any { it is HomeRoute }) {
                    router.popWhile { it !is HomeRoute }
                } else {
                    router.replaceAll(HomeRoute())
                }
            }
        }

        ResetPasswordScreen(
            state = state,
            onPasswordChange = viewModel::onPasswordChanged,
            onRepeatPasswordChange = viewModel::onRepeatPasswordChanged,
            onToggleShowPassword = viewModel::onToggleShowPassword,
            onResetClick = viewModel::onResetClick,
            onClickErrorPrimaryAction = viewModel::onClickErrorPrimaryAction,
            onClickSuccessAction = viewModel::onSuccessActionClick,
        )
    }

@Composable
private fun ResetPasswordScreen(
    state: ResetPasswordViewModel.State,
    onPasswordChange: (String) -> Unit,
    onRepeatPasswordChange: (String) -> Unit,
    onToggleShowPassword: () -> Unit,
    onResetClick: () -> Unit,
    onClickErrorPrimaryAction: () -> Unit,
    onClickSuccessAction: () -> Unit,
) {
    Scaffold(
        containerColor = AppColors.surfaceVariant,
        contentColor = AppColors.onSurfaceVariant,
    ) {
        FullContent(
            state = state.resetPasswordState,
            primaryAction = Action(onClickErrorPrimaryAction),
            modifier = Modifier.padding(it),
            idle = {
                FullScreenSmallContentBox {
                    ResetPasswordForm(
                        password = state.password,
                        repeatPassword = state.repeatPassword,
                        onPasswordChange = onPasswordChange,
                        onRepeatPasswordChange = onRepeatPasswordChange,
                        onToggleShowPassword = onToggleShowPassword,
                        onResetClick = onResetClick,
                        resetEnabled = state.resetEnabled,
                        showPassword = state.showPassword,
                        passwordMinLength = state.passwordMinLength
                    )
                }
            },
            loaded = { SuccessContent(onClickSuccessAction) }
        )
    }
}

@Composable
private fun SuccessContent(onCloseClick: () -> Unit) {
    FullScreenSmallContentMessage(
        icon = AppIcons.Password,
        iconTint = AppColors.primary,
        title = "Slaptažodis pakeistas sėkmingai",
        message = "Būsite automatiškai prijungti.",
        primaryAction = Action(onCloseClick, "Gerai")
    )
}

@Composable
private fun ResetPasswordForm(
    password: String,
    repeatPassword: String,
    onPasswordChange: (String) -> Unit,
    onRepeatPasswordChange: (String) -> Unit,
    onToggleShowPassword: () -> Unit,
    onResetClick: () -> Unit,
    resetEnabled: Boolean,
    showPassword: Boolean,
    passwordMinLength: Int,
    modifier: Modifier = Modifier,
) = SmallContentSurface(modifier) {
    Form(
        title = "Pakeisk slaptažodį",
        message = "Įvesk naują slaptažodį du kartus. Po sėkmingo pakeitimo galėsi prisijungti.",
        fields = {
            TextField(
                password,
                onPasswordChange,
                Modifier.fillMaxWidth(),
                singleLine = true,
                label = { Text("Slaptažodis") },
                keyboardOptions = KeyboardOptions(autoCorrectEnabled = false),
                visualTransformation = if (showPassword) VisualTransformation.None else PasswordTransformation,
                leadingIcon = { Icon(AppIcons.Password, "Slaptažodis") },
                trailingIcon = {
                    IconButton(onToggleShowPassword) {
                        Icon(if (showPassword) AppIcons.VisibilityOff else AppIcons.Visibility, null)
                    }
                },
                supportingText = { Text("Slaptažodis turi būti bent $passwordMinLength simbolių arba ilgesnis") }
            )

            TextField(
                repeatPassword,
                onRepeatPasswordChange,
                Modifier.fillMaxWidth().padding(top = 8.dp),
                singleLine = true,
                label = { Text("Pakartoti slaptažodį") },
                keyboardOptions = KeyboardOptions(autoCorrectEnabled = false),
                visualTransformation = PasswordTransformation,
                leadingIcon = { Icon(AppIcons.Password, "Slaptažodis") },
            )
        },
        actions = {
            Button(onResetClick, Modifier.fillMaxWidth(), enabled = resetEnabled) { Text("Pakeisti slaptažodį") }
        },
    )
}
