package com.koduok.lists.feature.register

import com.koduok.lists.ext.PasswordRequirements
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.asLoading
import com.koduok.lists.feature.register.RegisterViewModel.Effect
import com.koduok.lists.feature.register.RegisterViewModel.Effect.*
import com.koduok.lists.feature.register.RegisterViewModel.State
import com.koduok.lists.routes.TermsRoute
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class RegisterViewModel(
    private val service: RegisterService,
) : ViewModel<State, Effect>(State()) {

    fun onBackClick() = effect(Close)
    fun onEmailChanged(email: String) = updateState { state.copy(email = email) }
    fun onPasswordChanged(password: String) = updateState { state.copy(password = password) }
    fun onTryAgainClick() = updateState { state.idle() }
    fun onToggleShowPassword() {
        updateState { state.showPasswordToggled() }
    }

    fun onRegisterClick() = launchUniqueIfNotRunning("register") {
        updateState { state.loading() }

        val register = EmailRegister(state.email, state.password)
        runCatching { service.register(register) }.onSuccess { effect(CloseLoginFlow) }
            .onFailureLogNonFatal("Register failed") { updateState { state.failed(it) } }
    }

    fun onClickPrivacyPolicy() {
        effect(OpenTerms(TermsRoute.Type.Privacy))
    }

    fun onClickTermsAndConditions() {
        effect(OpenTerms(TermsRoute.Type.Terms))
    }

    data class State(
        val email: String = "",
        val password: String = "",
        val showPassword: Boolean = false,
        val registerState: LoadState<Unit> = LoadState.Idle,
    ) {
        val passwordMinLength = PasswordRequirements.MIN_LENGTH
        val registerEnabled = email.isNotEmpty() && password.length >= passwordMinLength

        internal fun idle() = copy(registerState = LoadState.Idle)
        internal fun loading() = copy(registerState = registerState.asLoading())
        internal fun failed(error: Throwable) = copy(registerState = error.asFailed())
        internal fun showPasswordToggled() = copy(showPassword = !showPassword)
    }

    sealed class Effect {
        data object Close : Effect()
        data object CloseLoginFlow : Effect()
        data class OpenTerms(val type: TermsRoute.Type) : Effect()
    }
}

internal fun Module.registerViewModel() {
    factoryOf(::RegisterViewModel)
}
