package com.koduok.lists

import com.koduok.lists.api.apiClient
import com.koduok.lists.feature.appUser.appUserRepository
import com.koduok.lists.feature.appUser.appUserViewModel
import com.koduok.lists.feature.forgotPassword.forgotPasswordService
import com.koduok.lists.feature.forgotPassword.forgotPasswordViewModel
import com.koduok.lists.feature.forgotPassword.resetPasswordViewModel
import com.koduok.lists.feature.home.homeRepository
import com.koduok.lists.feature.home.homeViewModel
import com.koduok.lists.feature.login.loginRepository
import com.koduok.lists.feature.login.loginViewModel2
import com.koduok.lists.feature.profile.profileRepository
import com.koduok.lists.feature.profile.profileViewModel
import com.koduok.lists.feature.register.registerRepository
import com.koduok.lists.feature.register.registerViewModel
import com.koduok.lists.feature.registry.registryRepository
import com.koduok.lists.feature.registry.registryViewModel
import com.koduok.lists.feature.registryEdit.editEntryService
import com.koduok.lists.feature.registryEdit.entryEditViewModel
import com.koduok.lists.feature.registryEdit.registryEditRepository
import com.koduok.lists.feature.registryEdit.registryEditViewModel
import com.koduok.lists.model.ApiEnvironment
import com.koduok.lists.model.RemoteApiEnvironment
import org.koin.dsl.module

val sharedClientModule = module {
    appUserRepository()
    appUserViewModel()
    homeViewModel()
    homeRepository()
    loginViewModel2()
    loginRepository()
    registerRepository()
    registerViewModel()
    profileRepository()
    profileViewModel()
    registryEditRepository()
    registryEditViewModel()
    entryEditViewModel()
    registryViewModel()
    registryRepository()
    forgotPasswordViewModel()
    forgotPasswordService()
    resetPasswordViewModel()
    editEntryService()
    apiClient()
    single<ApiEnvironment> { RemoteApiEnvironment }
}
