package com.koduok.lists.model

import com.koduok.lists.ext.fTextId
import com.koduok.lists.ext.fTextWord
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@JvmInline
@Serializable
value class UserId(val value: String)

@JvmInline
@Serializable
value class Email(val value: String)

fun fUserId() = UserId(fTextId())
fun fEmail() = Email(fTextWord() + "@email.com")
