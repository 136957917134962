package com.koduok.lists.model

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable

@Immutable
data class Action(
    val action: () -> Unit,
    val label: String,
)

@Composable
@ReadOnlyComposable
fun Action(action: () -> Unit) = Action(action, "Gerai")

@Composable
@ReadOnlyComposable
fun (() -> Unit).toAction() = Action(this)
