package com.koduok.lists.feature.registryEdit

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.*
import com.koduok.lists.errors.NotFoundError
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.asLoaded
import com.koduok.lists.feature.registry.RegistryService
import com.koduok.lists.feature.registryEdit.RegistryEditViewModel.Effect
import com.koduok.lists.feature.registryEdit.RegistryEditViewModel.Effect.OpenRegistry
import com.koduok.lists.feature.registryEdit.RegistryEditViewModel.State
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.model.RegistryEdit
import com.koduok.lists.model.RegistryEditType
import com.koduok.lists.model.RegistryEditType.*
import com.koduok.lists.model.RegistryId
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class RegistryEditViewModel(
    private val type: RegistryEditType,
    private val service: RegistryEditService,
    private val registryService: RegistryService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        prepare()
    }

    fun onTitleChanged(title: String) = updateState { state.withRegistryTitle(title) }
    fun onDescriptionChanged(description: String) = updateState { state.withRegistryDescription(description) }
    fun onVisibilityToggled() = updateState { state.withRegistryToggledVisibility() }
    fun onTemplateToggled() = updateState { state.withRegistryToggledTemplate() }
    fun onSaveClick() = save()

    fun onTryAgainClick() {
        if (state.registryEditState.valueOrNull == null) {
            prepare()
        } else {
            updateState { state.saveIdle() }
        }
    }

    fun onNewEntryClick() {
        analytics.track(CreateEntryEvent(type))
        updateState { state.withNewEntryDialog() }
    }

    fun onEditEntryClick(entryEdit: EntryEdit) {
        analytics.track(EditEntryEvent(type, entryEdit))
        updateState { state.withEditEntryDialog(entryEdit) }
    }

    fun onDialogDismiss() = updateState { state.copy(dialogContent = null) }
    fun onSaveEntry(entryEdit: EntryEdit) = updateState { state.withEntryEdit(entryEdit).copy(dialogContent = null) }
    fun onClickEntryMoveUp(entryEdit: EntryEdit) = updateState { state.withEntryEditUp(entryEdit) }
    fun onClickEntryMoveDown(entryEdit: EntryEdit) = updateState { state.withEntryEditDown(entryEdit) }
    fun onClickEntryDelete(entryEdit: EntryEdit) {
        analytics.track(DeleteEntryEvent(type, entryEdit))
        updateState { state.withEntryDelete(entryEdit) }
    }

    private fun prepare() = launchUniqueIfNotRunning("prepare") {
        updateState { state.registryEditLoading() }
        runCatching {
            when (type) {
                is CopyRegistry -> {
                    val registry = registryService.getRegistry(type.copyRegistryId) ?: throw NotFoundError
                    RegistryEdit.from(type, registry)
                }

                is EditRegistry -> {
                    val registry = service.getRegistry(type.registryId) ?: throw NotFoundError
                    RegistryEdit.from(type, registry)
                }

                NewRegistry -> RegistryEdit(type)
            }
        }.onSuccess {
            updateState { state.registryEditLoaded(it) }
        }.onFailureLogNonFatal("Failed to prepare registry edit") {
            updateState { state.registryEditFailed(it) }
        }
    }

    private fun save() = launchUniqueIfNotRunning("save") {
        val registryEdit = state.registryEditState.valueOrNull ?: return@launchUniqueIfNotRunning

        updateState { state.saveLoading() }
        runCatching { service.saveRegistry(registryEdit) }.onSuccess {
            val event = when (type) {
                is CopyRegistry -> RegistryCopiedEvent(it, registryEdit)
                is EditRegistry -> RegistryEditedEvent(it, registryEdit)
                NewRegistry -> RegistryCreatedEvent(it, registryEdit)
            }
            analytics.track(event)
        }.onSuccess { effect(OpenRegistry(it)) }.onFailureLogNonFatal("Failed to save registry") { updateState { state.saveFailed(it) } }
    }

    data class State(
        internal val registryEditState: LoadState<RegistryEdit> = LoadState.Loading(),
        internal val saveState: LoadState<Unit> = LoadState.Loaded(Unit),
        val dialogContent: DialogContent? = null,
    ) {
        val screenState: LoadState<RegistryEdit> = LoadState.merge(registryEditState, saveState) { registryEdit, save ->
            if (save != null) registryEdit else null
        }

        internal fun registryEditLoading() = copy(registryEditState = LoadState.Loading())
        internal fun registryEditLoaded(registryEdit: RegistryEdit) = copy(registryEditState = registryEdit.asLoaded())
        internal fun registryEditFailed(error: Throwable) = copy(registryEditState = error.asFailed())

        internal fun saveIdle() = copy(saveState = LoadState.Loaded(Unit))
        internal fun saveLoading() = copy(saveState = LoadState.Loading())
        internal fun saveFailed(error: Throwable) = copy(saveState = error.asFailed())

        internal fun withRegistryTitle(title: String) = state { withTitle(title) }
        internal fun withRegistryDescription(description: String) = state { withDescription(description) }
        internal fun withRegistryToggledVisibility() = state { withToggledVisibility() }
        internal fun withRegistryToggledTemplate() = state { withToggledTemplate() }

        internal fun withNewEntryDialog() = copy(dialogContent = DialogContent.EditEntryDialog(EntryEdit.new()))
        internal fun withEditEntryDialog(entryEdit: EntryEdit) = copy(dialogContent = DialogContent.EditEntryDialog(entryEdit))

        internal fun withEntryEdit(entryEdit: EntryEdit) = state { withEntryEdit(entryEdit) }
        internal fun withEntryEditUp(entryEdit: EntryEdit) = state { withEntryEditUp(entryEdit) }
        internal fun withEntryEditDown(entryEdit: EntryEdit) = state { withEntryEditDown(entryEdit) }
        internal fun withEntryDelete(entryEdit: EntryEdit) = state { withEntryDelete(entryEdit) }

        private fun state(update: RegistryEdit.() -> RegistryEdit) = registryEditState.valueOrNull?.update()?.let { registryEditLoaded(it) } ?: this@State
    }

    sealed class Effect {
        data class OpenRegistry(val registryId: RegistryId) : Effect()
    }

    sealed class DialogContent {
        data class EditEntryDialog(val entryEdit: EntryEdit) : DialogContent()
    }
}

internal fun Module.registryEditViewModel() {
    factoryOf(::RegistryEditViewModel)
}
