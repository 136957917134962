package com.koduok.lists.analytics.events

import com.koduok.lists.analytics.AnalyticsEvent
import com.koduok.lists.model.RegistryEdit
import com.koduok.lists.model.RegistryId

data class RegistryCreatedEvent(val registryId: RegistryId, val registryEdit: RegistryEdit) : AnalyticsEvent {
    override val name = "registry_created"
    override val parameters = registryId.analytics + registryEdit.analytics
}
