@file:Suppress("UNCHECKED_CAST")

package com.koduok.lists.navigation

import com.arkivanov.decompose.ComponentContext
import com.koduok.lists.feature.forgotPassword.forgotPasswordScreen
import com.koduok.lists.feature.forgotPassword.resetPasswordScreen
import com.koduok.lists.feature.home.homeScreen
import com.koduok.lists.feature.profile.profileScreen
import com.koduok.lists.feature.register.registerScreen
import com.koduok.lists.feature.registry.registryScreen
import com.koduok.lists.feature.registryEdit.registryEditScreen
import com.koduok.lists.feature.terms.termsScreen
import com.koduok.lists.routes.Route

object AllScreens {
    private val factories: Map<String, ScreenBuilder<Route>> = listOf(
        homeScreen,
        forgotPasswordScreen,
        resetPasswordScreen,
        registerScreen,
        profileScreen,
        registryEditScreen,
        registryScreen,
        termsScreen,
    ).let { it as List<ScreenBuilder<Route>> }.associateBy { it.screenName }

    fun build(route: Route, componentContext: ComponentContext): Screen {
        return factories[route::class.simpleName]?.build(route, componentContext) ?: throw RuntimeException("No factory registered for $route")
    }
}
