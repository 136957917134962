@file:OptIn(ExperimentalCoroutinesApi::class)

package com.koduok.lists.feature

import com.arkivanov.essenty.instancekeeper.InstanceKeeper
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.*
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

abstract class ViewModel<State, Effect>(initialState: State) : InstanceKeeper.Instance {
    private val viewModelScope = CoroutineScope(SupervisorJob() + Dispatchers.Main/* TODO getMainDispatcher()*/)
    private val effectsFlow = MutableSharedFlow<Effect>(extraBufferCapacity = Int.MAX_VALUE)
    private val stateChangeInputsFlow = MutableSharedFlow<StateChange<State>>(extraBufferCapacity = Int.MAX_VALUE)
    private val stateFlow = MutableStateFlow(initialState)
    private val uniqueJobs by lazy { hashMapOf<Any, Job>() }
    val effects: SharedFlow<Effect> = effectsFlow.asSharedFlow()
    val states: StateFlow<State> = stateFlow.asStateFlow()
    val state: State get() = stateFlow.value

    init {
        launchInViewModel {
            stateChangeInputsFlow
                .flatMapConcat { flow(it.stateChanges) }
                .collect { state -> stateFlow.update { state } }
        }
    }

    protected fun updateState(stateChange: suspend () -> State) {
        stateChangeInputsFlow.tryEmit(StateChange { emit(stateChange()) })
    }

    protected fun statesFlow(stateChangeFlow: suspend FlowCollector<State>.() -> Unit) {
        stateChangeInputsFlow.tryEmit(StateChange(stateChangeFlow))
    }

    protected fun effect(effect: Effect) {
        if (effectsFlow.subscriptionCount.value > 0) {
            effectsFlow.tryEmit(effect)
        } else {
            launchInViewModel {
                effectsFlow.subscriptionCount
                    .filter { it > 0 }
                    .take(1)
                    .collect { effectsFlow.tryEmit(effect) }
            }
        }
    }

    /**
     * Launches a new coroutine using [viewModelScope]. This is mostly used in `init` block. If you use it in some other place,
     * check maybe [launchUnique] or [launchUniqueIfNotRunning] is more appropriate.
     */
    protected fun launchInViewModel(
        context: CoroutineContext = EmptyCoroutineContext,
        start: CoroutineStart = CoroutineStart.DEFAULT,
        block: suspend CoroutineScope.() -> Unit,
    ): Job = viewModelScope.launch(context, start, block)

    protected fun asyncInViewModel(
        context: CoroutineContext = EmptyCoroutineContext,
        start: CoroutineStart = CoroutineStart.DEFAULT,
        block: suspend CoroutineScope.() -> Unit,
    ) = viewModelScope.async(context, start, block)

    /**
     * Does nothing if job with the same [uniqueJobId] is already running. Otherwise does [launchUnique].
     *
     * Example: If refresh is called multiple times, but refresh is already in progress, this is a good function to use.
     */
    protected fun launchUniqueIfNotRunning(uniqueJobId: Any, block: suspend CoroutineScope.() -> Unit): Job? {
        val currentJob = uniqueJobs[uniqueJobId]
        return if (currentJob == null || currentJob.isCompleted) launchUnique(uniqueJobId, block) else null
    }

    /**
     * Cancels current job with the same [uniqueJobId] if one is already running then launches new coroutine via [launchInViewModel].
     */
    protected fun launchUnique(uniqueJobId: Any, block: suspend CoroutineScope.() -> Unit): Job {
        val currentJob = uniqueJobs[uniqueJobId]
        currentJob?.cancel()

        val job = launchInViewModel { block() }
        job.invokeOnCompletion {
            val jobForUniqueId = uniqueJobs[uniqueJobId]
            if (job == jobForUniqueId) {
                uniqueJobs.remove(uniqueJobId)
            }
        }
        uniqueJobs[uniqueJobId] = job
        return job
    }

    protected fun cancelUnique(uniqueJobId: Any) {
        uniqueJobs.remove(uniqueJobId)?.cancel()
    }

//    override fun onDestroy() {
//        viewModelScope.cancel()
//        uniqueJobs.clear()
//    }

    private data class StateChange<State>(val stateChanges: suspend FlowCollector<State>.() -> Unit)
}

//internal expect fun getMainDispatcher(): CoroutineDispatcher
