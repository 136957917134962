package com.koduok.lists.ext

import kotlin.random.Random

fun fTextWord() = words.random()
fun fTextPhrase() = phrases.random()
fun fTextSentenceShort() = sentencesShort.random()
fun fTextSentence() = sentences.random()
fun fText2Sentences() = (sentencesShort + sentences).let { it.random() + " " + it.random() }
fun fTextId() = Random.nextInt().toString()

private val words
    get() = listOf(
        "Cras",
        "Donec",
        "Quisque",
        "Proin",
        "Etiam",
        "Cras",
        "Consequat",
        "Eleifend",
        "Dignissim",
        "Pellentesque",
        "Bibendum",
        "Porttitor",
        "Accumsan",
    )

private val phrases
    get() = listOf(
        "Cras viverra",
        "Donec consequat",
        "Quisque eleifend",
        "Proin dictum lacus",
        "Etiam ut tellus",
        "Cras sit amet",
    )

private val sentencesShort
    get() = listOf(
        "Cras viverra metus.",
        "Donec consequat neque sit amet.",
        "Quisque eleifend dui consectetur.",
        "Proin dictum lacus at leo.",
        "Etiam ut tellus vitae.",
        "Cras sit amet leo nec.",
    )

private val sentences
    get() = listOf(
        "Fusce suscipit leo ut ex tristique, fermentum dignissim metus feugiat.",
        "Cras vehicula ante in porttitor imperdiet.",
        "Etiam ut lorem facilisis, faucibus justo eu, sollicitudin felis.",
        "Morbi euismod dolor in luctus porttitor.",
        "Pellentesque luctus nisl eu nisl lobortis feugiat a id lectus.",
        "Phasellus commodo dolor vitae tellus bibendum, et ornare enim accumsan.",
        "Nullam a est eget enim posuere pellentesque eget in risus.",
        "Vivamus sagittis libero sed felis vulputate, ut semper lorem aliquam.",
    )
