package com.koduok.lists.ext

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import com.koduok.lists.errors.*
import com.koduok.lists.model.ResetPasswordError

@Composable
@ReadOnlyComposable
fun Throwable.displayTitle() = when (this) {
    is ResetPasswordError.IllegalEntry -> "Taip negalima!"
    is WrongEmailPasswordLoginError -> "Nepavyko prisijungti"
    is PasswordDoesNotMatchError -> "Slaptažodis nesutampa"
    is RateLimitError -> "Atsiprašome, pas mus įvyko klaida"
    is BadEmailError -> "Blogas el. pašto adresas"
    is UserAlreadyRegisteredError -> "Šis el. pašto adresas jau užregistruotas"
    is NewPasswordShouldBeDifferentError -> "Naujas slaptažodis turi nesutapti su senu"
    else -> toString()
}

@Composable
@ReadOnlyComposable
fun Throwable.displayMessage() = when (this) {
    is ResetPasswordError.IllegalEntry -> "Pakeisti slaptažodį galima tik paspaudus ant nuorodos kurią gavai į el. paštą."
    is WrongEmailPasswordLoginError -> "Blogas el. pašto adresas arba slaptažodis."
    is PasswordDoesNotMatchError -> "Įsitikink, kad įvesti slaptažodžiai yra vienodi."
    is RateLimitError -> "Susisiekite su mumis ir praneškite, o mes pabandysime sutvarkyti problemą kuo greičiau. Ačiū už supratimą."
    is BadEmailError -> "Įsitikinkite, kad gerai įvedėte savo el. pašto adresą ir bandykite dar kartą."
    is UserAlreadyRegisteredError -> "Galbūt esate prisijungę su Google ar Facebook naudodami šį el. pašto adresa?"
    is NewPasswordShouldBeDifferentError -> "Sugalvokite kitą slaptažodį"
    else -> toString()
}
