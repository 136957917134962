package com.koduok.lists.analytics.events

import com.koduok.lists.analytics.AnalyticsEvent
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.model.RegistryEditType

data class DeleteEntryEvent(val type: RegistryEditType, val entryEdit: EntryEdit) : AnalyticsEvent {
    override val name = "delete_entry"
    override val parameters = type.analytics + entryEdit.analytics
}
