package com.koduok.lists.logging

import io.github.aakira.napier.Napier

object Logger {
    fun debug(message: String) {
        Napier.d(message)
    }

    fun error(message: String, error: Throwable? = null) {
        Napier.e(message, error)
    }
}
