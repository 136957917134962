package com.koduok.lists.model

import com.koduok.lists.ext.urlDomain
import com.koduok.lists.ext.uuid

data class EntryEdit(
    val editEntryId: EditEntryId,
    val url: String = "",
    val title: String = "",
    val imageUrl: String = "",
    val price: String = "",
) {
    companion object {
        fun new() = from(null, registryCopy = false)
        fun from(entry: Entry?, registryCopy: Boolean) = EntryEdit(
            editEntryId = entry
                ?.id
                ?.takeIf { !registryCopy }
                ?.let { StoredEditEntryId(it.value) }
                ?: NewEditEntryId(uuid().toString()),
            url = entry?.url.orEmpty(),
            title = entry?.title.orEmpty(),
            imageUrl = entry?.image?.url.orEmpty(),
            price = entry?.price?.toPlainAmountString().orEmpty(),
        )
    }

    val isValid = title.isNotBlank()

    fun withUrl(value: String) = copy(url = value)
    fun withTitle(value: String) = copy(title = value)
    fun withImageUrl(value: String) = copy(imageUrl = value)
    fun withPrice(value: String) = copy(price = value)
    fun withScrapeResult(scrapeResult: ScrapeResult) = copy(
        title = scrapeResult.title ?: title,
        imageUrl = scrapeResult.image?.url ?: imageUrl,
        price = scrapeResult.price?.toPlainAmountString() ?: price,
    )

    val analytics
        get() = mapOf(
            "entry_edit_id" to editEntryId.value,
            "entry_domain" to url.urlDomain,
            "entry_price" to price,
        )
}

sealed class EditEntryId {
    abstract val value: String
}

data class StoredEditEntryId(override val value: String) : EditEntryId()
data class NewEditEntryId(override val value: String) : EditEntryId()
