package com.koduok.lists.feature.register

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.rounded.Email
import androidx.compose.material.icons.rounded.Password
import androidx.compose.material.icons.rounded.Visibility
import androidx.compose.material.icons.rounded.VisibilityOff
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.TermsAndPrivacyText
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.content.FullScreenSmallContentBox
import com.koduok.lists.component.content.SmallContentSurface
import com.koduok.lists.component.form.Form
import com.koduok.lists.component.textField.PasswordTransformation
import com.koduok.lists.component.textField.TextField
import com.koduok.lists.component.topBar.TopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.register.RegisterViewModel.Effect.*
import com.koduok.lists.model.Action
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.LoginFlowRoute
import com.koduok.lists.routes.RegisterRoute
import com.koduok.lists.routes.TermsRoute
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons

val registerScreen
    get() = screen<RegisterRoute> {
        val router = LocalRouter.current
        val viewModel = rememberViewModel<RegisterViewModel>()
        val state by viewModel.collectAsStateAndEffects {
            when (it) {
                Close -> router.pop()
                CloseLoginFlow -> router.popWhile { route -> route is LoginFlowRoute }
                is OpenTerms -> router.push(TermsRoute(it.type))
            }
        }

        RegisterScreen(
            state = state,
            onBackClick = viewModel::onBackClick,
            onEmailChange = viewModel::onEmailChanged,
            onPasswordChange = viewModel::onPasswordChanged,
            onToggleShowPassword = viewModel::onToggleShowPassword,
            onRegisterClick = viewModel::onRegisterClick,
            onTryAgainClick = viewModel::onTryAgainClick,
            onClickTermsAndConditions = viewModel::onClickTermsAndConditions,
            onClickPrivacyPolicy = viewModel::onClickPrivacyPolicy,
        )
    }

@Composable
private fun RegisterScreen(
    state: RegisterViewModel.State,
    onBackClick: () -> Unit,
    onEmailChange: (String) -> Unit,
    onPasswordChange: (String) -> Unit,
    onToggleShowPassword: () -> Unit,
    onRegisterClick: () -> Unit,
    onTryAgainClick: () -> Unit,
    onClickPrivacyPolicy: () -> Unit,
    onClickTermsAndConditions: () -> Unit,
) {
    Scaffold(
        topBar = { TopAppBar(title = {}, navigationIcon = { TopBarBackButton(onBackClick) }) },
        containerColor = AppColors.surfaceVariant,
        contentColor = AppColors.onSurfaceVariant,
    ) {
        FullContent(state.registerState, Action(onTryAgainClick), Modifier.padding(it), idle = {
            FullScreenSmallContentBox {
                SmallContentSurface {
                    RegisterForm(
                        email = state.email,
                        password = state.password,
                        onEmailChange = onEmailChange,
                        onPasswordChange = onPasswordChange,
                        onToggleShowPassword = onToggleShowPassword,
                        onRegisterClick = onRegisterClick,
                        onClickTermsAndConditions = onClickTermsAndConditions,
                        onClickPrivacyPolicy = onClickPrivacyPolicy,
                        showPassword = state.showPassword,
                        registerEnabled = state.registerEnabled,
                        passwordMinLength = state.passwordMinLength
                    )
                }
            }
        })
    }
}

@Composable
private fun RegisterForm(
    email: String,
    password: String,
    onEmailChange: (String) -> Unit,
    onPasswordChange: (String) -> Unit,
    onToggleShowPassword: () -> Unit,
    onRegisterClick: () -> Unit,
    onClickPrivacyPolicy: () -> Unit,
    onClickTermsAndConditions: () -> Unit,
    showPassword: Boolean,
    registerEnabled: Boolean,
    passwordMinLength: Int,
    modifier: Modifier = Modifier,
) = Form(
    title = "Registruokis!",
    message = "Įvesk savo el. pašto adresą ir nepatingėk sukurti saugų slaptažodį.",
    fields = {
        TermsAndPrivacyText(
            onClickPrivacyPolicy = onClickPrivacyPolicy,
            onClickTermsAndConditions = onClickTermsAndConditions,
            modifier = Modifier.padding(bottom = 16.dp),
        )
        TextField(
            email,
            onEmailChange,
            modifier = Modifier.fillMaxWidth(),
            leadingIcon = { Icon(AppIcons.Email, "El. paštas") },
            singleLine = true,
            label = { Text("El. paštas") },
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Email),
        )
        TextField(password,
            onPasswordChange,
            modifier = Modifier.fillMaxWidth().padding(top = 8.dp),
            leadingIcon = { Icon(AppIcons.Password, "Slaptažodis") },
            trailingIcon = {
                IconButton(onToggleShowPassword) {
                    Icon(if (showPassword) AppIcons.VisibilityOff else AppIcons.Visibility, null)
                }
            },
            singleLine = true,
            label = { Text("Slaptažodis") },
            keyboardOptions = KeyboardOptions(autoCorrectEnabled = false),
            visualTransformation = if (showPassword) VisualTransformation.None else PasswordTransformation,
            supportingText = { Text("Slaptažodis turi būti bent $passwordMinLength simbolių arba ilgesnis") })
    },
    actions = {
        Button(onRegisterClick, Modifier.fillMaxWidth(), enabled = registerEnabled) { Text("Registruotis") }
    },
    modifier,
)
