package com.koduok.lists.ext

import com.benasher44.uuid.UuidHasher
import com.benasher44.uuid.nameBasedUuidOf
import com.benasher44.uuid.uuid4
import com.benasher44.uuid.uuidFrom
import org.kotlincrypto.hash.md.MD5

fun uuidFromText(text: String) = nameBasedUuidOf(uuidFrom("00000000-0000-0000-0000-000000000000"), text, Md5UuidHasher)
fun uuid() = uuid4()

private object Md5UuidHasher : UuidHasher {
    private val md5 by lazy { MD5() }

    override val version: Int get() = 3

    override fun digest(): ByteArray = md5.digest()
    override fun update(input: ByteArray) = md5.update(input)
}