package com.koduok.lists.navigation

import androidx.compose.runtime.Composable
import com.arkivanov.decompose.ComponentContext
import com.koduok.lists.routes.Route

abstract class Screen(componentContext: ComponentContext) : ComponentContext by componentContext {
    @Composable
    abstract fun ScreenUi()
}

inline fun <reified R : Route> screen(
    noinline content: @Composable ComponentContext.(R) -> Unit,
): ScreenBuilder<R> {
    return ScreenBuilder(R::class.simpleName!!, content)
}

class ScreenBuilder<R : Route>(
    val screenName: String,
    private val screenUi: @Composable ComponentContext.(R) -> Unit,
) {
    fun build(
        route: R,
        componentContext: ComponentContext,
    ): Screen = object : Screen(componentContext) {
        @Composable
        override fun ScreenUi() = screenUi(route)
    }
}
