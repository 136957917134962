package com.koduok.lists.feature.registry

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.*
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.*
import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.feature.login.LoginViewModel.LoginReason
import com.koduok.lists.feature.registry.RegistryViewModel.Effect
import com.koduok.lists.feature.registry.RegistryViewModel.Effect.*
import com.koduok.lists.feature.registry.RegistryViewModel.State
import com.koduok.lists.model.*
import com.koduok.lists.model.EntryAction.*
import com.koduok.lists.model.RegistryEditType.CopyRegistry
import com.koduok.lists.model.RegistryEditType.EditRegistry
import kotlinx.coroutines.flow.collectLatest
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class RegistryViewModel(
    private val registryId: RegistryId,
    private val service: RegistryService,
    private val appUserService: AppUserService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        launchInViewModel {
            appUserService.appUser().collectLatest {
                updateState { state.copy(appUserState = it.asLoaded()) }
            }
        }

        refresh()
    }

    fun onClickRetry() {
        if (state.requestState.errorOrNull != null) {
            updateState { state.requestIdle() }
        } else {
            refresh()
        }
    }

    fun onClickBack() = effect(Close)

    fun onClickEntry(displayEntry: DisplayEntry) {
        val url = displayEntry.entry.url ?: return
        analytics.track(OpenEntryEvent(registryId, displayEntry.entry))
        effect(OpenUrl(url))
    }

    fun onClickEntryAction(displayEntry: DisplayEntry) = launchUniqueIfNotRunning("reserve") {
        if (state.loadingEntryId != null) return@launchUniqueIfNotRunning

        updateState { state.withLoadingEntry(displayEntry.entry.id) }

        when (displayEntry.action) {
            Reserve -> reserve(displayEntry.entry)
            Release -> release(displayEntry.entry)
            Reserved -> Unit
            None -> Unit
        }

        updateState { state.withLoadingEntry(null) }
    }

    fun onClickEdit() {
        val registry = state.screenState.valueOrNull?.registry ?: return
        analytics.track(EditRegistryEvent(registry))
        effect(OpenRegistryEdit(EditRegistry(registryId)))
    }

    fun onClickDelete() = updateState { state.askDeleteRegistry() }

    fun onClickConfirmDelete() = launchUniqueIfNotRunning("delete registry") {
        val registry = state.screenState.valueOrNull?.registry ?: return@launchUniqueIfNotRunning
        analytics.track(DeleteRegistryEvent(registry))

        updateState { state.requestLoading() }
        runCatching { service.deleteRegistry(registryId) }
            .onSuccess { state.requestIdle() }
            .onSuccess { effect(Close) }
            .onFailureLogNonFatal("Failed to delete registry $registryId") {
                updateState { state.requestFailed(it) }
            }

        effect(OpenRegistryEdit(EditRegistry(registryId)))
    }

    fun onClickCancelDelete() = updateState { state.clearDialog() }

    fun onClickCopy() {
        val displayData = state.screenState.valueOrNull ?: return
        val registry = displayData.registry ?: return
        val appUser = displayData.appUser

        if (appUser.isLoggedIn) {
            analytics.track(RegistryCopyRequested(registry))
            effect(OpenRegistryEdit(CopyRegistry(registryId)))
        } else {
            effect(OpenLogin(LoginReason.CopyRegistry))
        }
    }

    private suspend fun reserve(entry: Entry) {
        runCatching { service.reserve(entry.id) }
            .onSuccess { updateState { state.withReservedEntry(it) } }
            .onSuccess { analytics.track(ReserveEntryEvent(it)) }
            .onFailureLogNonFatal("Failed to reserve entry ${entry.id}")
    }

    private suspend fun release(entry: Entry) {
        runCatching { service.release(entry.id) }
            .onSuccess { updateState { state.withReleasedEntry(it) } }
            .onSuccess { analytics.track(UnReserveEntryEvent(it)) }
            .onFailureLogNonFatal("Failed to release entry ${entry.id}")
    }

    fun refresh() = launchUniqueIfNotRunning("refresh") {
        updateState { state.loading() }

        runCatching { service.getRegistry(registryId) }
            .onSuccess { updateState { state.loaded(it) } }
            .onFailureLogNonFatal("Failed to load registry ${registryId.value}") { updateState { state.failed(it) } }
    }

    data class State(
        private val appUserState: LoadState<AppUser> = LoadState.Loading(),
        private val registryState: LoadState<Registry?> = LoadState.Loading(),
        internal val requestState: LoadState<Unit> = LoadState.Idle,
        private val reservedEntryIds: Set<EntryId> = emptySet(),
        val loadingEntryId: EntryId? = null,
        val dialogContent: DialogContent? = null,
    ) {
        val screenState = LoadState.merge(appUserState, registryState, requestState) { appUser, registry, _ ->
            if (appUser != null && registry != null) {
                DisplayData(registry.value, appUser.value, reservedEntryIds).asLoadedValue()
            } else {
                null
            }
        }

        internal fun loading() = copy(registryState = registryState.asLoading())
        internal fun loaded(registry: Registry?) = copy(registryState = registry.asLoaded())
        internal fun failed(error: Throwable) = copy(registryState = registryState.asFailed(error))

        internal fun requestIdle() = copy(requestState = LoadState.Idle)
        internal fun requestLoading() = copy(requestState = requestState.asLoading())
        internal fun requestFailed(error: Throwable) = copy(requestState = requestState.asFailed(error))

        internal fun withLoadingEntry(entryId: EntryId?) = copy(loadingEntryId = entryId)

        internal fun withReservedEntry(entry: Entry) = copy(
            registryState = registryState.mapValue { it?.withReplacedEntry(entry) },
            reservedEntryIds = reservedEntryIds + entry.id
        )

        internal fun withReleasedEntry(entry: Entry) = copy(
            registryState = registryState.mapValue { it?.withReplacedEntry(entry) },
            reservedEntryIds = reservedEntryIds - entry.id,
        )

        internal fun askDeleteRegistry() = copy(dialogContent = DialogContent.AskDeleteRegistry)
        internal fun clearDialog() = copy(dialogContent = null)
    }

    sealed class Effect {
        data object Close : Effect()
        data class OpenUrl(val url: String) : Effect()
        data class OpenRegistryEdit(val editType: RegistryEditType) : Effect()
        data class OpenLogin(val reason: LoginReason) : Effect()
    }

    data class DisplayData(
        val registry: Registry?,
        internal val appUser: AppUser,
        private val reservedEntryIds: Set<EntryId>,
    ) {
        val canEdit = registry?.users.orEmpty().any { it.userId == appUser.idOrNull && it.role == RegistryRole.Admin }
        val displayEntries = registry
            ?.entries
            ?.map {
                DisplayEntry(
                    entry = it,
                    action = when {
                        registry.isTemplate -> None
                        !it.reserved -> Reserve
                        reservedEntryIds.contains(it.id) || (appUser.isLoggedIn && it.reservedUserId == appUser.idOrNull) -> Release
                        else -> Reserved
                    },
                )
            }
            .orEmpty()
    }

    sealed class DialogContent {
        data object AskDeleteRegistry : DialogContent()
    }
}

internal fun Module.registryViewModel() {
    factoryOf(::RegistryViewModel)
}