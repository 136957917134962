package com.koduok.lists.model

import kotlinx.serialization.Serializable
import kotlin.random.Random

@Serializable
data class Money(
    val amount: String,
    val currency: Currency,
) {
    companion object {
        private val numberRegex = Regex("-?\\d+(\\.\\d+)?")

        fun from(value: String?): Money? {
            if (value == null) return null

            val normalized = value.trim().replace(",", ".")

            val amountResult = numberRegex.find(normalized)
            val amount = amountResult?.value

            if (amount == null || amount.toDoubleOrNull() == null) {
                return null
            }

            val currency = Currency.fromOrDefault(normalized.removeRange(amountResult.range).trim())

            return Money(
                amount = amount, currency = currency
            )
        }
    }

    fun toPlainAmountString() = amount
    fun toPlainString() = "${currency.symbol} $amount"
}

fun fMoney() = Money(
    amount = Random.nextDouble(0.09, 1234.0).toString().let {
        val (whole, fraction) = it.split(".")
        whole + "." + fraction.take(2)
    }, currency = fCurrency()
)
