package com.koduok.lists.feature.registryEdit

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.DialogProperties
import com.koduok.lists.component.dialog.Dialog
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberGetInstance
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.Effect.Close
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.Effect.Save
import com.koduok.lists.model.EntryEdit
import org.koin.core.parameter.parametersOf

@Composable
fun EntryEditDialog(
    entryEdit: EntryEdit,
    onDismissRequest: () -> Unit,
    onSave: (EntryEdit) -> Unit,
) = Dialog(
    onDismissRequest = onDismissRequest,
    dialogProperties = DialogProperties(dismissOnClickOutside = false)
) {
//    val scope = rememberCoroutineScope()
//    val clipboardManager = LocalClipboardManager.current
    val viewModel = rememberGetInstance<EntryEditViewModel> { parametersOf(entryEdit) }
    val state by viewModel.collectAsStateAndEffects {
        when (it) {
            Close -> onDismissRequest()
            is Save -> onSave(it.entryEdit)
        }
    }

//    LifecycleResumeEffect(Unit) {
//        scope.launch {
//            val clipboardEntry = clipboardManager.getText()
//            viewModel.onClipboardUrlChange(clipboardEntry?.text)
//        }
//
//        onPauseOrDispose { }
//    }

    EditEntryView(
        state.entryEdit,
        scrapeLoading = state.scrapeIsLoading,
        canSave = state.canSave,
        onUrlChange = viewModel::onUrlChange,
        onTitleChange = viewModel::onTitleChange,
        onImageUrlChange = viewModel::onImageUrlChange,
        onPriceChange = viewModel::onPriceChange,
        onClickSave = viewModel::save,
        onClickCancel = viewModel::cancel,
        modifier = Modifier.fillMaxWidth().verticalScroll(rememberScrollState()).padding(16.dp)
    )
}
