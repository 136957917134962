package com.koduok.lists.component.topBar

import androidx.compose.material.icons.automirrored.rounded.ArrowBack
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import com.koduok.lists.theme.AppIconsAutoMirrored

@Composable
fun TopBarBackButton(
    onClick: () -> Unit,
) = IconButton(onClick) { Icon(AppIconsAutoMirrored.ArrowBack, "Go back") }