package com.koduok.lists.component.topBar

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.Expanded
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.PreferExpanded
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.LocalWindowSizeClass

@Composable
fun TopAppBarActionButton(
    onClick: () -> Unit,
    type: TopAppBarActionButtonType,
    label: String,
    modifier: Modifier = Modifier,
    icon: @Composable () -> Unit,
) {
    val showLabel = type == Expanded || (type == PreferExpanded && LocalWindowSizeClass.current.widthSizeClass != WindowWidthSizeClass.Compact)
    if (showLabel) {
        TextButton(onClick, modifier) {
            Box(Modifier.size(24.dp)) {
                icon()
            }
            Spacer(Modifier.width(8.dp))
            Text(label)
        }
    } else {
        IconButton(onClick, modifier, colors = IconButtonDefaults.iconButtonColors(contentColor = AppColors.primary)) {
            Box(Modifier.size(24.dp)) {
                icon()
            }
        }
    }
}

enum class TopAppBarActionButtonType {
    Expanded, PreferExpanded, Collapsed
}