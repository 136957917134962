package com.koduok.lists.component.content

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.koduok.lists.feature.LoadState
import com.koduok.lists.model.Action

@Composable
fun <T> Content(
    state: LoadState<T>,
    modifier: Modifier = Modifier,
    idle: @Composable BoxScope.() -> Unit = {},
    loading: @Composable BoxScope.(LoadState.Loading<T>) -> Unit = {
        if (it.loadedValue == null) {
            LoadingContent()
        } else {
            FullLoadingContent()
        }
    },
    failed: @Composable BoxScope.(LoadState.Failed<T>) -> Unit = {
        if (it.loadedValue == null) {
            FailedContent(it.error)
        } else {
            FullFailedContent(it.error)
        }
    },
    loaded: @Composable BoxScope.(T) -> Unit = {},
) = Box(modifier) {
    val loadedValue = state.loadedValue

    if (state is LoadState.Idle) {
        idle()
    }

    if (loadedValue != null) {
        loaded(loadedValue.value)
    }

    if (state is LoadState.Failed<T> && state.show) {
        failed(state)
    }

    if (state is LoadState.Loading<T> && state.show) {
        loading(state)
    }
}

@Composable
fun <T> Content(
    state: LoadState<T>,
    primaryAction: Action?,
    modifier: Modifier = Modifier,
    idle: @Composable BoxScope.() -> Unit = {},
    loading: @Composable BoxScope.(LoadState.Loading<T>) -> Unit = {
        if (it.loadedValue == null) {
            LoadingContent()
        } else {
            FullLoadingContent()
        }
    },
    loaded: @Composable BoxScope.(T) -> Unit = {},
) = Content(
    state = state,
    modifier = modifier,
    idle = idle,
    loading = loading,
    failed = {
        if (it.loadedValue == null) {
            FailedContent(it.error, primaryAction = primaryAction)
        } else {
            FullFailedContent(it.error, primaryAction = primaryAction)
        }
    },
    loaded = loaded,
)

@Composable
fun <T> FullContent(
    state: LoadState<T>,
    modifier: Modifier = Modifier,
    idle: @Composable BoxScope.() -> Unit = {},
    loading: @Composable BoxScope.(LoadState.Loading<T>) -> Unit = { FullLoadingContent() },
    failed: @Composable BoxScope.(LoadState.Failed<T>) -> Unit = { FullFailedContent(it.error) },
    loaded: @Composable BoxScope.(T) -> Unit = {},
) = Content(
    state = state,
    modifier = Modifier.fillMaxSize() then modifier,
    idle = idle,
    loading = loading,
    failed = failed,
    loaded = loaded,
)

@Composable
fun <T> FullContent(
    state: LoadState<T>,
    primaryAction: Action?,
    modifier: Modifier = Modifier,
    idle: @Composable BoxScope.() -> Unit = {},
    loading: @Composable BoxScope.(LoadState.Loading<T>) -> Unit = { FullLoadingContent() },
    loaded: @Composable BoxScope.(T) -> Unit = {},
) = Content(
    state = state,
    modifier = Modifier.fillMaxSize() then modifier,
    idle = idle,
    loading = loading,
    failed = { FullFailedContent(it.error, primaryAction = primaryAction) },
    loaded = loaded,
)
