package com.koduok.lists.component.content

import androidx.compose.foundation.layout.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppDimens

@Composable
fun FullScreenSmallContentBox(
    modifier: Modifier = Modifier,
    content: @Composable BoxScope.() -> Unit,
) = Box(
    Modifier.fillMaxSize() then modifier,
    contentAlignment = Alignment.Center, // TODO On small screens should be top
    content = {
        Box(
            Modifier.widthIn(max = AppDimens.maxSmallContentWidth).padding(horizontal = 8.dp, vertical = 8.dp), // TODO On small screens no padding?
            content = content,
        )
    }
)
