package com.koduok.lists.api

import com.koduok.lists.model.ApiEnvironment
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.util.*
import io.ktor.util.reflect.*
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf

fun Module.apiClient() = singleOf(::ApiClient)

class ApiClient(private val apiEnvironment: ApiEnvironment) {

    private val httpClient by lazy {
        HttpClient {
            install(DefaultRequest) {
                url(
                    scheme = apiEnvironment.scheme,
                    host = apiEnvironment.host,
                    port = apiEnvironment.port,
                )
                headers {
                    appendIfNameAbsent(HttpHeaders.ContentType, "application/json")
                }
            }
            install(ContentNegotiation) {
                json()
            }
            install(Logging) {
                logger = object : Logger {
                    override fun log(message: String) {
                        com.koduok.lists.logging.Logger.debug(message)
                    }
                }
                level = LogLevel.ALL // TODO Set it based on build type
            }
        }
    }

    suspend fun <Body, Response> internalRequest(
        method: HttpMethod,
        path: String,
        responseTypeInfo: TypeInfo,
        bodyTypeInfo: TypeInfo,
        parameters: Map<String, List<Any?>>,
        headers: Map<String, Any?>,
        body: Body?,
    ): Response = runCatching {
        httpClient.request(path) {
            this.method = method

            headers.forEach {
                header(it.key, it.value)
            }

            parameters.forEach { values ->
                values.value.forEach {
                    parameter(values.key, it)
                }
            }

            if (body != null) {
                setBody(body, bodyTypeInfo)
            }
        }.body<Response>(responseTypeInfo)
    }.getOrThrow()
}

suspend inline fun <reified Response> ApiClient.get(
    path: String,
    parameters: Map<String, List<Any?>> = emptyMap(),
    headers: Map<String, Any?> = emptyMap(),
): Response {
    return internalRequest(
        method = HttpMethod.Get,
        path = path,
        responseTypeInfo = typeInfo<Response>(),
        bodyTypeInfo = typeInfo<Unit>(),
        parameters = parameters,
        headers = headers,
        body = null,
    )
}

suspend inline fun <reified Response, reified Body> ApiClient.post(
    path: String,
    body: Body? = null,
    parameters: Map<String, List<Any?>> = emptyMap(),
    headers: Map<String, Any?> = emptyMap(),
): Response {
    return internalRequest(
        method = HttpMethod.Post,
        path = path,
        responseTypeInfo = typeInfo<Response>(),
        bodyTypeInfo = typeInfo<Body>(),
        parameters = parameters,
        headers = headers,
        body = body,
    )
}
