package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyGridScope
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material3.Text
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.feature.registry.RegistryItem
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppType

internal fun LazyGridScope.homeScreenFeaturedRegistries(
    featuredRegistries: List<Registry>,
    onClickRegistry: (RegistryId) -> Unit,
) {
    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        Column(Modifier.padding(vertical = 32.dp, horizontal = 16.dp), horizontalAlignment = Alignment.CenterHorizontally) {
            Text(
                "Dovanų sąrašo idėjos".uppercase(),
                style = AppType.displaySmall,
                textAlign = TextAlign.Center,
                color = AppColors.primary
            )

            Spacer(Modifier.height(16.dp))

            Text(
                "Nori dovanų gauti atrinktas dovanas, tačiau neturi laiko susikurti dovanų sąrašą? Pasinaudok jau sukurtais dovanų sąrašais. Tau tereikia tik nukopijuoti sąrašą, jį peržiūrėti ir atnaujinti pagal save.",
                style = AppType.titleMedium,
                textAlign = TextAlign.Center,
                color = AppColors.primary
            )
        }
    }

    items(featuredRegistries) { registry ->
        RegistryItem(
            onClick = { onClickRegistry(registry.id) },
            registry = registry,
            modifier = Modifier.fillMaxWidth().aspectRatio(0.75f),
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {}
}
