package com.koduok.lists.component.topBar

import androidx.compose.foundation.layout.RowScope
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.TopAppBarScrollBehavior
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.koduok.lists.theme.AppColors

@Composable
fun TopAppBar(
    title: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    scrollBehavior: TopAppBarScrollBehavior? = null,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable RowScope.() -> Unit = {},
) {
    androidx.compose.material3.TopAppBar(
        title,
        modifier,
        navigationIcon,
        actions,
        scrollBehavior = scrollBehavior,
        colors = TopAppBarDefaults.topAppBarColors(
            scrolledContainerColor = AppColors.surfaceVariant,
            containerColor = AppColors.surfaceVariant,
            navigationIconContentColor = AppColors.onSurfaceVariant,
            titleContentColor = AppColors.onSurfaceVariant,
            actionIconContentColor = AppColors.onSurfaceVariant,
        ),
    )
}

@Composable
fun BackgroundTopAppBar(
    title: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    scrollBehavior: TopAppBarScrollBehavior? = null,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable RowScope.() -> Unit = {},
) {
    androidx.compose.material3.TopAppBar(
        title,
        modifier,
        navigationIcon,
        actions,
        scrollBehavior = scrollBehavior,
        colors = TopAppBarDefaults.topAppBarColors(
            scrolledContainerColor = AppColors.surfaceVariant,
            containerColor = AppColors.background,
            navigationIconContentColor = AppColors.onBackground,
            titleContentColor = AppColors.onBackground,
            actionIconContentColor = AppColors.onBackground,
        ),
    )
}
