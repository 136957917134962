package com.koduok.lists.feature.registryEdit

import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.asLoaded
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.Effect
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.Effect.Close
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.Effect.Save
import com.koduok.lists.feature.registryEdit.EntryEditViewModel.State
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.model.ScrapeResult
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class EntryEditViewModel(
    entryEdit: EntryEdit,
    private val service: EntryEditService,
) : ViewModel<State, Effect>(State(entryEdit)) {

    private val urlRegex by lazy { Regex("(http|ftp|https):\\/\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-])") }

    fun onUrlChange(url: String) {
        updateState { state.withUrl(url) }
        launchUnique("url change") {
            updateState { state.scrapeLoading() }
            runCatching { service.scrape(url) }
                .onSuccess { updateState { state.scrapeLoaded(it) } }
                .onFailureLogNonFatal("Failed to scrape $url") { updateState { state.scrapeFailed(it) } }
        }
    }

    fun onClipboardUrlChange(url: String?) {
        if (url != null && state.entryEdit.url.isEmpty() && urlRegex.matches(url)) {
            onUrlChange(url)
        }
    }

    fun onTitleChange(title: String) = updateState { state.withTitle(title) }
    fun onImageUrlChange(imageUrl: String) = updateState { state.withImageUrl(imageUrl) }
    fun onPriceChange(price: String) = updateState { state.withPrice(price) }

    fun save() = effect(Save(state.entryEdit))
    fun cancel() = effect(Close)

    data class State(
        val entryEdit: EntryEdit,
        val scrapeState: LoadState<ScrapeResult> = LoadState.Idle,
    ) {
        val canSave = entryEdit.isValid
        val scrapeIsLoading = scrapeState is LoadState.Loading

        internal fun withUrl(value: String) = copy(entryEdit = entryEdit.withUrl(value))
        internal fun withTitle(value: String) = copy(entryEdit = entryEdit.withTitle(value))
        internal fun withImageUrl(value: String) = copy(entryEdit = entryEdit.withImageUrl(value))
        internal fun withPrice(value: String) = copy(entryEdit = entryEdit.withPrice(value))

        internal fun scrapeLoading() = copy(scrapeState = LoadState.Loading())
        internal fun scrapeFailed(error: Throwable) = copy(scrapeState = error.asFailed())
        internal fun scrapeLoaded(scrapeResult: ScrapeResult) = copy(
            scrapeState = scrapeResult.asLoaded(),
            entryEdit = entryEdit.withScrapeResult(scrapeResult)
        )
    }

    sealed class Effect {
        data class Save(val entryEdit: EntryEdit) : Effect()
        data object Close : Effect()
    }
}

internal fun Module.entryEditViewModel() {
    factoryOf(::EntryEditViewModel)
}