package com.koduok.lists.theme

import androidx.compose.material3.windowsizeclass.WindowSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

val LocalDimens = staticCompositionLocalOf { Dimens() }

inline val AppDimens @Composable @ReadOnlyComposable get() = LocalDimens.current

data class Dimens(
    val maxContentWidth: Dp = 1200.dp,
    val maxSmallContentWidth: Dp = 480.dp,
    val featuredImageHeight: Dp = 240.dp,
    val gridItemMinWidth: Dp = 170.dp,
)

@Composable
fun themeDimens(windowSizeClass: WindowSizeClass): Dimens {
    return when (windowSizeClass.widthSizeClass) {
        WindowWidthSizeClass.Compact -> Dimens()
        else -> Dimens(
            featuredImageHeight = 360.dp,
            gridItemMinWidth = 220.dp,
        )
    }
}
