package com.koduok.lists.feature.home

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material.icons.rounded.Star
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.media.Image
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.*
import lists.shared_compose.generated.resources.*

@Composable
internal fun GuestHomeScreen(
    featuredRegistries: List<Registry>,
    onClickNewRegistry: () -> Unit,
    onClickRegistry: (RegistryId) -> Unit,
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
) {
    BoxWithConstraints {
        val maxContentWidth = AppDimens.maxContentWidth
        val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 8.dp
        val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 16.dp)

        LazyVerticalGrid(
            GridCells.Adaptive(AppDimens.gridItemMinWidth),
            Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            contentPadding = contentPadding
        ) {
            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HeaderSection(onClickNewRegistry)
            }

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                InfoSection(onClickNewRegistry)
            }

            homeScreenFeaturedRegistries(featuredRegistries, onClickRegistry)

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                TestimonialsSection()
            }

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HomeScreenFooter(onClickSocialFacebook, onClickSocialInstagram)
            }
        }
    }
}

@Composable
private fun HeaderSection(onClickNewRegistry: () -> Unit) = Box(contentAlignment = Alignment.Center) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            "Dovanų sąrašas",
            style = AppType.displayLarge,
            textAlign = TextAlign.Center,
            color = AppColors.primary
        )

        Text(
            "Gauk dovanas, kurios Tave džiugins",
            style = AppType.titleLarge,
            textAlign = TextAlign.Center,
            color = AppColors.primary
        )

        Button(onClickNewRegistry, Modifier.padding(top = 16.dp, bottom = 24.dp)) {
            Text("Sukurk savo sąrašą".uppercase())
        }

        Row(Modifier.height(AppDimens.featuredImageHeight).fillMaxWidth(), horizontalArrangement = Arrangement.spacedBy(8.dp)) {
            val count = when (LocalWindowSizeClass.current.widthSizeClass) {
                WindowWidthSizeClass.Compact -> 2
                WindowWidthSizeClass.Medium -> 3
                else -> 4
            }
            listOf(Res.drawable.home1, Res.drawable.home2, Res.drawable.home3, Res.drawable.home4).take(count).forEach {
                Image(it, Modifier.fillMaxHeight().weight(1f), contentScale = ContentScale.Crop)
            }
        }
    }
}

@Composable
private fun InfoSection(onClickNewRegistry: () -> Unit) {
    val primaryColor = AppColors.primary
    Box(
        modifier = Modifier.drawWithCache {
            val topLeft = Offset(-9999999f, 0f)
            val newSize = Size(9999999f * 2f, size.height)

            onDrawBehind {
                drawRect(primaryColor, topLeft = topLeft, size = newSize)
            }
        },
        contentAlignment = Alignment.Center,
    ) {
        Row(horizontalArrangement = Arrangement.spacedBy(8.dp), verticalAlignment = Alignment.CenterVertically) {
            val largeScreen = LocalWindowSizeClass.current.widthSizeClass == WindowWidthSizeClass.Expanded
            if (largeScreen) {
                Image(Res.drawable.featured, Modifier.weight(1f).padding(32.dp), contentScale = ContentScale.Fit)
            }
            Column(
                Modifier.weight(1f).padding(vertical = 32.dp, horizontal = if (largeScreen) 0.dp else 16.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                CompositionLocalProvider(LocalContentColor provides AppColors.onPrimary) {
                    Text("Sukurk norimų dovanų sąrašą".uppercase(), style = AppType.displaySmall, textAlign = TextAlign.Center)
                    Spacer(Modifier.height(24.dp))
                    Text(
                        "Gimtadieniams, krikštynoms, vestuvėms, Kalėdoms... Kodėl verta kurti dovanų sąrašus? Jūsų draugams ir šeimos nariams bus lengviau išrinkti tai, ko iš tiesų norite, o jums užtikrinti, kad gausite tai, ko jums tikrai reikia ir apie ką svajojate. Daugiau jokių nereikalingų dovanų!",
                        style = AppType.bodyLarge,
                        textAlign = TextAlign.Center
                    )
                    Spacer(Modifier.height(24.dp))
                    Button(
                        onClickNewRegistry,
                        Modifier.align(Alignment.CenterHorizontally),
                        colors = ButtonDefaults.buttonColors(containerColor = LocalContentColor.current, contentColor = AppColors.primary),
                    ) {
                        Text("Sukurk savo sąrašą".uppercase())
                    }
                }
            }
        }
    }
}

@Composable
private fun TestimonialsSection() {
    val primaryColor = AppColors.primary
    Box(
        modifier = Modifier.drawWithCache {
            val topLeft = Offset(-9999999f, 0f)
            val newSize = Size(9999999f * 2f, size.height)

            onDrawBehind {
                drawRect(primaryColor, topLeft = topLeft, size = newSize)
            }
        },
        contentAlignment = Alignment.Center,
    ) {
        CompositionLocalProvider(LocalContentColor provides AppColors.onPrimary) {
            val testimonials = remember {
                listOf(
                    TestimonialData(
                        "Labai patogu, ypač vaiko gimtadieniui",
                        "Ir svečiams nereikia sukti galvos, ir vaikas gauna tai, ko reikia ir nori.",
                        "Ada",
                        "@skaitaunuogimimo"
                    ),
                    TestimonialData(
                        "Dovanų sąrašas man labai pasiteisinusi platforma",
                        "Galite iš kart kopijuoti idėjas, prasitrinti, papildyti pagal poreikį ir siųsti dovanosiantiems. Svarbiausia, kad nupirkus dovaną galima aiškiai tai išskirti, tad nėra rizikos, kad dovanos susidubliuos.",
                        "Austėja",
                        "@lifetakestwo",
                    ),
                    TestimonialData(
                        "Ar žinot būdą kaip niekada negauti blogų dovanų?",
                        "Pasakyti žmonėms, ko tiksliai norėtumėt gauti dovanų. Paprasčiau nei paprasta!",
                        "Kristina",
                        "@kristina.razmi",
                    ),
                )
            }

            Column(Modifier.fillMaxWidth().padding(vertical = 32.dp), horizontalAlignment = Alignment.CenterHorizontally) {
                Text("Atsiliepimai", style = AppType.displaySmall)
                Spacer(Modifier.height(32.dp))
                val smallScreen = LocalWindowSizeClass.current.widthSizeClass == WindowWidthSizeClass.Compact
                if (smallScreen) {
                    Column(verticalArrangement = Arrangement.spacedBy(16.dp)) {
                        testimonials.forEach {
                            Testimonial(it, Modifier.fillMaxWidth().padding(vertical = 16.dp, horizontal = 24.dp))
                        }
                    }
                } else {
                    Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceEvenly) {
                        testimonials.forEach {
                            Testimonial(it, Modifier.weight(1f).padding(horizontal = 16.dp))
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun Testimonial(
    data: TestimonialData,
    modifier: Modifier = Modifier,
) = Column(modifier, horizontalAlignment = Alignment.CenterHorizontally) {
    Row {
        repeat(5) {
            Icon(AppIcons.Star, contentDescription = "", modifier = Modifier.size(24.dp))
        }
    }
    Spacer(Modifier.height(8.dp))
    Text(data.title.uppercase(), style = AppType.bodyLarge, fontWeight = FontWeight.SemiBold, textAlign = TextAlign.Center)
    Spacer(Modifier.height(16.dp))
    Text("\"${data.message}\"", textAlign = TextAlign.Center)
    Spacer(Modifier.height(16.dp))
    Text(data.author, fontWeight = FontWeight.SemiBold, textAlign = TextAlign.Center)
    Text(data.userTag, textAlign = TextAlign.Center)
}

private data class TestimonialData(
    val title: String,
    val message: String,
    val author: String,
    val userTag: String,
)

@Preview
@Composable
fun Preview() {
    Text("Asd")
}