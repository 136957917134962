package com.koduok.lists.model

import kotlinx.serialization.Serializable

interface Media

interface Image : Media

@Serializable
data class UrlImage(val url: String) : Image

@Serializable
data class SvgImage(val url: String) : Image

fun fImage() = fUrlImage()
fun fUrlImage() = UrlImage("https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png")
fun fUrlImageProfile() = UrlImage("https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png")
