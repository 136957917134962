package com.koduok.lists.feature.appUser

import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.appUser.AppUserViewModel.Effect
import com.koduok.lists.feature.appUser.AppUserViewModel.Effect.ShowLogin
import com.koduok.lists.feature.appUser.AppUserViewModel.Effect.ShowProfile
import com.koduok.lists.feature.appUser.AppUserViewModel.State
import com.koduok.lists.model.*
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.distinctUntilChanged
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class AppUserViewModel(
    private val service: AppUserService,
) : ViewModel<State, Effect>(State()) {

    init {
        launchInViewModel {
            service.appUser().distinctUntilChanged().collectLatest { appUser ->
                updateState { state.copy(appUser = appUser) }
            }
        }
    }

    fun onClick() {
        when (state.appUser) {
            is AuthenticatedAppUser -> effect(ShowProfile)
            FailedAppUser -> effect(ShowLogin)
            LoadingAppUser -> Unit
            NotAuthenticatedAppUser -> effect(ShowLogin)
        }
    }

    data class State(val appUser: AppUser = LoadingAppUser)

    sealed class Effect {
        data object ShowLogin : Effect()
        data object ShowProfile : Effect()
    }
}

internal fun Module.appUserViewModel() {
    factoryOf(::AppUserViewModel)
}
