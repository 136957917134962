package com.koduok.lists.feature.registry

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.ColorMatrix
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.Card
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.media.Image
import com.koduok.lists.ext.formatted
import com.koduok.lists.ext.hazeChildDefault
import com.koduok.lists.ext.urlDomain
import com.koduok.lists.model.DisplayEntry
import com.koduok.lists.model.EntryAction
import com.koduok.lists.model.EntryAction.*
import com.koduok.lists.model.fDisplayEntry
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.AppType
import dev.chrisbanes.haze.HazeDefaults
import dev.chrisbanes.haze.HazeState
import dev.chrisbanes.haze.haze

@Composable
fun EntryItem(
    displayEntry: DisplayEntry,
    onClick: (DisplayEntry) -> Unit,
    onClickAction: (DisplayEntry) -> Unit,
    isLoading: Boolean,
    modifier: Modifier = Modifier,
) = Column(modifier.background(AppColors.surfaceVariant, AppShapes.medium).clip(AppShapes.medium)) {
    Card(onClick = { onClick(displayEntry) },
        modifier = Modifier.fillMaxWidth().aspectRatio(1f),
        colors = CardDefaults.cardColors(containerColor = AppColors.surfaceVariant, contentColor = AppColors.onSurfaceVariant),
        shape = AppShapes.medium,
        content = {
            Box(Modifier.fillMaxSize()) {
                val hazeState = remember { HazeState() }

                Image(
                    displayEntry.entry.image,
                    Modifier.matchParentSize().haze(
                            hazeState,
                            HazeDefaults.style(backgroundColor = AppColors.surfaceVariant),
                        ),
                    contentScale = ContentScale.Crop,
                    colorFilter = if (displayEntry.entry.reserved) ColorFilter.colorMatrix(ColorMatrix().apply { setToSaturation(0f) }) else null
                )

                val domain = displayEntry.entry.url?.urlDomain
                if (domain != null) {
                    Text(
                        domain,
                        Modifier.align(Alignment.TopStart).padding(8.dp).background(AppColors.surfaceVariant, shape = AppShapes.extraSmall).padding(4.dp),
                        style = AppType.labelSmall,
                        fontWeight = FontWeight.SemiBold,
                    )
                }

                val price = displayEntry.entry.price
                if (price != null) {
                    Text(
                        price.formatted(),
                        Modifier.align(Alignment.BottomEnd).padding(8.dp).hazeChildDefault(hazeState, shape = AppShapes.extraSmall).padding(4.dp),
                        style = AppType.bodyMedium,
                        textAlign = TextAlign.Center,
                        fontWeight = FontWeight.SemiBold,
                        minLines = 1,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        color = if (displayEntry.entry.reserved) LocalContentColor.current.copy(0.5f) else LocalContentColor.current
                    )
                }
            }

        })

    Box(Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 16.dp), contentAlignment = Alignment.Center) {
        Text(
            "",
            style = AppType.bodyMedium,
            textAlign = TextAlign.Center,
            fontWeight = FontWeight.Medium,
            minLines = 2,
        )
        Text(
            displayEntry.entry.title,
            style = AppType.bodyMedium,
            textAlign = TextAlign.Center,
            fontWeight = FontWeight.Medium,
            maxLines = 2,
            overflow = TextOverflow.Ellipsis,
            color = if (displayEntry.entry.reserved) LocalContentColor.current.copy(0.5f) else LocalContentColor.current
        )
    }

    if (displayEntry.action.visible) {
        EntryActionButton(
            action = displayEntry.action,
            isLoading = isLoading,
            onClick = { onClickAction(displayEntry) },
        )
    } else {
        Spacer(Modifier.height(16.dp))
    }
}

@Composable
private fun EntryActionButton(
    action: EntryAction,
    isLoading: Boolean,
    onClick: () -> Unit,
) {
    val text = remember(action) {
        when (action) {
            Reserve -> "Rezervuoti"
            Release -> "Atšaukti"
            Reserved -> "Rezervuota"
            None -> null
        }
    }

    val color = when (action) {
        Release -> AppColors.error
        else -> Color.Unspecified
    }

    if (text != null) {
        TextButton(
            onClick = onClick,
            modifier = Modifier.fillMaxWidth(),
            loading = isLoading,
            shape = RectangleShape,
            enabled = action.enabled,
            colors = ButtonDefaults.textButtonColors(contentColor = color),
            content = { Text(text) },
        )
    }
}

@Preview
@Composable
private fun Preview() = PreviewContainer {
    Column(Modifier.padding(16.dp)) {
        EntryItem(displayEntry = fDisplayEntry(), onClick = {}, onClickAction = {}, isLoading = false, modifier = Modifier.width(200.dp)
        )
    }
}
