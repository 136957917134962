package com.koduok.lists.supabase.model

import com.koduok.lists.model.*
import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put

@Serializable
data class SupabaseRegistry(
    val id: String,
    val title: String,
    val description: String?,
    val visibility: String,
    @SerialName("is_template") val isTemplate: Boolean,
    @SerialName("copy_id") val copyId: String?,
    @SerialName("owner_id") val ownerId: String?,
    @SerialName("legacy_owner_email") val legacyOwnerEmail: String?,
    @SerialName("created_at") val createdAt: String,
    val entries: List<SupabaseEntry>? = null,
    @SerialName("registry_users") val registryUsers: List<SupabaseRegistryUser>? = null,
) {
    companion object {
        val columns get() = Columns.raw("id, title, description, visibility, is_template, copy_id, owner_id, legacy_owner_email, created_at, entries (${SupabaseEntry.columns.value}), registry_users (${SupabaseRegistryUser.columns.value})".trimIndent())

        suspend fun save(userId: UserId, registryEdit: RegistryEdit): RegistryId {
            val json = createJson(registryEdit)
            val supabaseRegistry = runSupabase { postgrest.from("registries").upsert(json) { select() }.decodeSingle<SupabaseRegistry>() }
            val registryId = RegistryId(supabaseRegistry.id)

            SupabaseEntry.save(registryId, registryEdit.entries, registryEdit.deleteEntryIds)
            SupabaseRegistryUser.save(userId, registryId, RegistryRole.Admin)

            return registryId
        }

        private fun createJson(registryEdit: RegistryEdit) = buildJsonObject {
            when (registryEdit.type) {
                is RegistryEditType.CopyRegistry -> put("copy_id", registryEdit.type.copyRegistryId.value)
                is RegistryEditType.EditRegistry -> put("id", registryEdit.type.registryId.value)
                RegistryEditType.NewRegistry -> Unit
            }
            put("title", registryEdit.title)

            val description = registryEdit.description.takeIf { it.isNotBlank() }
            if (description != null) {
                put("description", description)
            }

            put("visibility", registryEdit.visibility.value)
            put("is_template", registryEdit.isTemplate)
        }

    }

    fun toRegistry() = Registry(
        id = RegistryId(id),
        title = title,
        description = description,
        visibility = RegistryVisibility.from(visibility),
        isTemplate = isTemplate,
        copyId = copyId?.let { RegistryId(it) },
        ownerId = ownerId?.let { UserId(it) },
        entries = entries.orEmpty().map { it.toEntry() }.sortedBy { it.order },
        users = registryUsers.orEmpty().mapNotNull { it.toDomain() }
    )
}
