package com.koduok.lists.feature.forgotPassword

import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.Effect
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.Effect.Close
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.Effect.OpenGmail
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.State
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

internal fun Module.forgotPasswordViewModel() = factoryOf(::ForgotPasswordViewModel)

class ForgotPasswordViewModel(private val service: ForgotPasswordService) : ViewModel<State, Effect>(State()) {

    fun onCloseClick() = effect(Close)
    fun onGmailClick() = effect(OpenGmail)
    fun onEmailChanged(email: String) = updateState { state.copy(email = email) }
    fun onTryAgainClick() = updateState { state.idle() }
    fun onForgotPasswordClick() = launchUniqueIfNotRunning("forgotPassword") {
        updateState { state.loading() }

        runCatching { service.forgotPassword(state.email) }
            .onSuccess { updateState { state.success() } }
            .onFailureLogNonFatal("Forgot password failed") { updateState { state.failed(it) } }
    }

    data class State(
        val email: String = "",
        val forgotPasswordState: LoadState<Unit> = LoadState.Idle,
    ) {
        internal fun idle() = copy(forgotPasswordState = LoadState.Idle)
        internal fun loading() = copy(forgotPasswordState = LoadState.Loading())
        internal fun success() = copy(forgotPasswordState = LoadState.Loaded(Unit))
        internal fun failed(error: Throwable) = copy(forgotPasswordState = error.asFailed())
    }

    sealed class Effect {
        data object Close : Effect()
        data object OpenGmail : Effect() {
            val uri: String = "https://gmail.com"
        }
    }
}