package com.koduok.lists.feature.registryEdit

import com.koduok.lists.api.ApiClient
import com.koduok.lists.api.get
import com.koduok.lists.api.model.ScrapeResponse
import com.koduok.lists.model.ScrapeResult
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

internal fun Module.editEntryService() = factoryOf(::EntryEditRepository) bind EntryEditService::class

interface EntryEditService {
    suspend fun scrape(url: String): ScrapeResult
}

private class EntryEditRepository(private val client: ApiClient) : EntryEditService {
    override suspend fun scrape(url: String): ScrapeResult {
        return client.get<ScrapeResponse>("/scrape/$url").toDomain()
    }
}
