package com.koduok.lists.ext

import org.koin.core.Koin
import org.koin.core.parameter.ParametersDefinition
import org.koin.core.qualifier.Qualifier
import org.koin.mp.KoinPlatformTools

val koin: Koin get() = KoinPlatformTools.defaultContext().get()

inline fun <reified T : Any> getInstance(
    qualifier: Qualifier? = null,
    noinline parameters: ParametersDefinition? = null,
): T = koin.get(qualifier, parameters)
