package com.koduok.lists.component.content

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material.icons.rounded.ErrorOutline
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.koduok.lists.ext.displayMessage
import com.koduok.lists.ext.displayTitle
import com.koduok.lists.model.Action
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons

@Composable
fun FailedContent(
    error: Throwable,
    modifier: Modifier = Modifier,
    primaryAction: Action? = null,
) = MessageActions(
    modifier = modifier.background(AppColors.background),
    icon = AppIcons.ErrorOutline,
    iconTint = AppColors.error,
    title = error.displayTitle(),
    message = error.displayMessage(),
    primaryAction = primaryAction,
    secondaryAction = null,
)

@Composable
fun BoxScope.FullFailedContent(
    error: Throwable,
    modifier: Modifier = Modifier,
    primaryAction: Action? = null,
) = FailedContent(error, Modifier.matchParentSize() then modifier, primaryAction)
