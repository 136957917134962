package com.koduok.lists.analytics

import com.koduok.lists.model.UserId
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.FirebaseAnalyticsEvents
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.analytics.logEvent

class FirebaseAnalytics : Analytics {
    private val analytics = Firebase.analytics

    override fun track(event: AnalyticsEvent) {
        analytics.logEvent(event.name, event.parameters)
    }

    override fun setScreen(screenName: String) {
        analytics.logEvent(FirebaseAnalyticsEvents.SCREEN_VIEW) {
            param("screen_name", screenName)
            param("screen_class", "WebApp")
        }
    }

    override fun setUserId(userId: UserId?) {
        analytics.setUserId(userId?.value ?: "")
    }

    override fun setUserProperties(properties: Map<String, String>) {
        properties.forEach {
            analytics.setUserProperty(it.key, it.value)
        }
    }
}
