package com.koduok.lists.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppType

@Composable
fun SectionDivider(text: String) {
    Box(Modifier.padding(top = 32.dp, bottom = 16.dp), contentAlignment = Alignment.Center) {
        HorizontalDivider()
        Text(text, Modifier.background(AppColors.background).padding(horizontal = 8.dp), style = AppType.labelMedium)
    }
}