package com.koduok.lists.component.media

import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalInspectionMode
import coil3.compose.AsyncImage
import com.koduok.lists.ext.getInstance
import com.koduok.lists.model.ApiEnvironment
import com.koduok.lists.model.UrlImage
import com.koduok.lists.network.ImageProxy
import lists.shared_compose.generated.resources.Res
import lists.shared_compose.generated.resources.home1
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun Image(
    image: Any?,
    modifier: Modifier = Modifier,
    contentScale: ContentScale = ContentScale.Fit,
    colorFilter: ColorFilter? = null,
) {
    val isPreview = LocalInspectionMode.current
    var model by remember(image) {
        mutableStateOf(if (isPreview) Res.drawable.home1 else unwrap(image, addProxy = true))
    }

    val targetModel = model
    if (targetModel is DrawableResource) {
        androidx.compose.foundation.Image(
            painter = painterResource(targetModel),
            contentDescription = "",
            modifier = modifier,
            contentScale = contentScale,
            colorFilter = colorFilter,
        )
    } else {
        AsyncImage(
            model = model,
            contentDescription = "",
            modifier = modifier,
            contentScale = contentScale,
            colorFilter = colorFilter,
            onError = {
                val newModel = unwrap(image, addProxy = false)
                if (model != newModel) {
                    model = newModel
                }
            }
        )
    }
}

private fun unwrap(image: Any?, addProxy: Boolean): Any? = when (image) {
    is UrlImage -> {
        if (addProxy && image.url.isNotBlank()) addProxy(image.url) else image.url
    }

    is String -> {
        if (addProxy && image.isNotBlank()) addProxy(image) else image
    }

    else -> image
}

private fun addProxy(url: String) = getInstance<ApiEnvironment>().buildUrl(ImageProxy.PATH + url)
