package com.koduok.lists.feature.login

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.LoggedInEvent
import com.koduok.lists.api.ApiClient
import com.koduok.lists.api.model.FinishLoginRequest
import com.koduok.lists.api.post
import com.koduok.lists.model.ClientEnvironment
import com.koduok.lists.routes.LoginRoute2
import com.koduok.lists.routes.LoginUrlFragment
import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.gotrue.SessionSource
import io.github.jan.supabase.gotrue.auth
import io.github.jan.supabase.gotrue.parseSessionFromFragment
import io.github.jan.supabase.gotrue.providers.Facebook
import io.github.jan.supabase.gotrue.providers.Google
import io.github.jan.supabase.gotrue.providers.builtin.Email
import kotlinx.coroutines.delay
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface LoginService {
    suspend fun login(login: Login, redirect: LoginRoute2)
    suspend fun finishLogin(fragment: LoginUrlFragment)
}

private class LoginRepository(
    private val api: ApiClient,
    private val clientEnvironment: ClientEnvironment,
    private val analytics: Analytics,
) : LoginService {
    override suspend fun login(login: Login, redirect: LoginRoute2) {
        delay(2000)
        when (login) {
            is EmailLogin -> {
                runSupabase {
                    auth.signInWith(Email) {
                        email = login.email
                        password = login.password
                    }
                }
                finishLogin()
            }

            GoogleLogin -> runSupabase {
                auth.signInWith(Google, redirectUrl = redirect.url(clientEnvironment))
            }

            FacebookLogin -> runSupabase {
                auth.signInWith(Facebook, redirectUrl = redirect.url(clientEnvironment))
            }
        }
    }

    override suspend fun finishLogin(fragment: LoginUrlFragment) {
        runSupabase {
            val session = auth.parseSessionFromFragment(fragment.requireValue)
            val user = auth.retrieveUser(session.accessToken)
            val newSession = session.copy(user = user)
            auth.importSession(newSession, source = SessionSource.External)
        }

        finishLogin()

        runSupabase { auth.refreshCurrentSession() }
    }

    private suspend fun finishLogin() {
        analytics.track(LoggedInEvent)

        val body = FinishLoginRequest(jwt = runSupabase { auth.currentSessionOrNull()!!.accessToken })
        api.post<Unit, FinishLoginRequest>("/user/finish_login", body)
    }
}

sealed class Login {
    val analytics
        get() = mapOf(
            "provider" to when (this) {
                is EmailLogin -> "email"
                FacebookLogin -> "facebook"
                GoogleLogin -> "google"
            }
        )
}

data class EmailLogin(val email: String, val password: String) : Login()
data object GoogleLogin : Login()
data object FacebookLogin : Login()

internal fun Module.loginRepository() {
    factoryOf(::LoginRepository) bind LoginService::class
}
