package com.koduok.lists.errors

import io.github.jan.supabase.exceptions.HttpRequestException
import io.github.jan.supabase.exceptions.RestException
import io.ktor.client.plugins.*

fun Throwable.toError(): Throwable = when (this) {
    is HttpRequestTimeoutException -> NetworkError(this)
    is HttpRequestException -> NetworkError(this)
    is RestException -> {
        when {
            error.contains("invalid_grant", ignoreCase = true) -> WrongEmailPasswordLoginError
            error.contains("Email rate limit exceeded", ignoreCase = true) -> RateLimitError
            error.contains("Unable to validate email address: invalid format", ignoreCase = true) -> BadEmailError
            error.contains("User already registered", ignoreCase = true) -> UserAlreadyRegisteredError
            error.contains("New password should be different from the old password", ignoreCase = true) -> NewPasswordShouldBeDifferentError
            else -> UnknownBackendError(this)
        }
    }

    else -> this
}

fun Throwable.throwError() {
    throw toError()
}
