package com.koduok.lists.feature.forgotPassword

import com.koduok.lists.ext.PasswordRequirements
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.forgotPassword.ResetPasswordViewModel.Effect
import com.koduok.lists.feature.forgotPassword.ResetPasswordViewModel.Effect.OpenHome
import com.koduok.lists.feature.forgotPassword.ResetPasswordViewModel.State
import com.koduok.lists.model.ResetPassword
import com.koduok.lists.model.ResetPasswordError
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

internal fun Module.resetPasswordViewModel() = factoryOf(::ResetPasswordViewModel)

class ResetPasswordViewModel(
    private val resetPassword: ResetPassword,
    private val service: ForgotPasswordService,
) : ViewModel<State, Effect>(State(resetPassword)) {

    fun onPasswordChanged(password: String) = updateState { state.copy(password = password) }
    fun onRepeatPasswordChanged(password: String) = updateState { state.copy(repeatPassword = password) }

    fun onToggleShowPassword() {
        updateState { state.showPasswordToggled() }
    }

    fun onResetClick() = launchUniqueIfNotRunning("reset") {
        val pending = resetPassword as? ResetPassword.Pending ?: return@launchUniqueIfNotRunning

        updateState { state.loading() }

        runCatching { service.resetPassword(pending, state.password, state.repeatPassword) }
            .onSuccess { updateState { state.success() } }
            .onFailureLogNonFatal("Reset password failed") { updateState { state.failed(it) } }
    }

    fun onClickErrorPrimaryAction() {
        if (state.resetPasswordState.errorOrNull is ResetPasswordError) {
            effect(OpenHome)
        } else {
            updateState { state.idle() }
        }
    }

    fun onSuccessActionClick() = effect(OpenHome)

    data class State(
        val password: String = "",
        val repeatPassword: String = "",
        val showPassword: Boolean = false,
        val resetPasswordState: LoadState<Unit> = LoadState.Idle,
    ) {
        constructor(resetPassword: ResetPassword) : this(
            resetPasswordState = when (resetPassword) {
                is ResetPassword.Failed -> resetPassword.error.asFailed()
                is ResetPassword.Pending -> LoadState.Idle
                ResetPassword.Setup -> LoadState.Idle
            }
        )

        val passwordMinLength = PasswordRequirements.MIN_LENGTH
        val resetEnabled = password.length >= passwordMinLength && repeatPassword.isNotEmpty()

        internal fun idle() = copy(resetPasswordState = LoadState.Idle)
        internal fun loading() = copy(resetPasswordState = LoadState.Loading())
        internal fun success() = copy(resetPasswordState = LoadState.Loaded(Unit))
        internal fun failed(error: Throwable) = copy(resetPasswordState = error.asFailed())
        internal fun showPasswordToggled() = copy(showPassword = !showPassword)
    }

    sealed class Effect {
        data object OpenHome : Effect()
    }
}