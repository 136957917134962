package com.koduok.lists.supabase

import com.koduok.lists.errors.throwError
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.gotrue.Auth
import io.github.jan.supabase.logging.LogLevel
import io.github.jan.supabase.postgrest.Postgrest
import kotlin.time.Duration.Companion.seconds

val supabase = createSupabaseClient(
    supabaseUrl = "https://imuriyzharqofylzsvha.supabase.co",
    supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImltdXJpeXpoYXJxb2Z5bHpzdmhhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTAwODE1ODIsImV4cCI6MjAyNTY1NzU4Mn0.sf3xg_GSeOhZWFLS5uvnLn4QmiiscUTrlTr4f-KTqTI",
) {
    install(Auth)
    install(Postgrest)
    defaultLogLevel = LogLevel.DEBUG
    requestTimeout = 30.seconds
}

suspend fun <T> runSupabase(run: suspend SupabaseClient.() -> T): T {
    return runCatching { run(supabase) }.onFailure { it.throwError() }.getOrThrow()
}
