package com.koduok.lists.ext

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.collectAsState
import com.koduok.lists.feature.ViewModel
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

@Composable
fun <S> ViewModel<S, *>.collectAsState(
    context: CoroutineContext = EmptyCoroutineContext,
): State<S> = states.collectAsState(context)

@Composable
inline fun <S, E> ViewModel<S, E>.collectAsStateAndEffects(
    context: CoroutineContext = EmptyCoroutineContext,
    crossinline block: suspend (E) -> Unit,
): State<S> {
    OnEffect(block)
    return states.collectAsState(context)
}

@Composable
inline fun <E> ViewModel<*, E>.OnEffect(crossinline block: suspend (E) -> Unit) =
    LaunchedEffect(this) { effects.collect { block(it) } }
