package com.koduok.lists.model

import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
data class Currency(
    val code: CurrencyCode,
    val symbol: String,
) {
    companion object {
        val default get() = Currency(CurrencyCode("EUR"), "€")

        fun fromOrDefault(value: String?): Currency {
            if (value == null)
                return default

            return Currency(
                code = CurrencyCode(value.uppercase()),
                symbol = "€",
            )
        }
    }
}

@JvmInline
@Serializable
value class CurrencyCode(val value: String)

fun fCurrency() = Currency(
    code = fCurrencyCode(),
    symbol = "€",
)

fun fCurrencyCode() = CurrencyCode("EUR")