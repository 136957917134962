package com.koduok.lists.theme

import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Shapes
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.dp

inline val AppShapes @Composable @ReadOnlyComposable get() = MaterialTheme.shapes

internal val themeShapes get() = Shapes()

fun Shape.flatBottom() = (this as? RoundedCornerShape)?.copy(bottomStart = CornerSize(0.dp), bottomEnd = CornerSize(0.dp)) ?: this