package com.koduok.lists.feature.registryEdit

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.rounded.ContentPaste
import androidx.compose.material3.Icon
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.button.OutlinedButton
import com.koduok.lists.component.textField.TextField
import com.koduok.lists.ext.LocalClipboardManager
import com.koduok.lists.ext.optionalDraw
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.LocalWindowSizeClass
import kotlinx.coroutines.launch

@Composable
fun EditEntryView(
    entryEdit: EntryEdit,
    scrapeLoading: Boolean,
    canSave: Boolean,
    onUrlChange: (String) -> Unit,
    onTitleChange: (String) -> Unit,
    onImageUrlChange: (String) -> Unit,
    onPriceChange: (String) -> Unit,
    onClickSave: () -> Unit,
    onClickCancel: () -> Unit,
    modifier: Modifier = Modifier,
) = Column(modifier, verticalArrangement = Arrangement.spacedBy(32.dp), horizontalAlignment = Alignment.CenterHorizontally) {

    val smallScreen = LocalWindowSizeClass.current.widthSizeClass != WindowWidthSizeClass.Expanded

    if (smallScreen) {
        EntryEditPreviewItem(
            entryEdit,
            modifier = Modifier.width(200.dp)
        )
    }

    TextField(
        entryEdit.url,
        onUrlChange,
        Modifier.fillMaxWidth(),
        label = { Text("Nuoroda") },
        placeholder = { Text("https://...") },
        trailingIcon = { PasteIconButton(onUrlChange) }
    )

    LinearProgressIndicator(Modifier.fillMaxWidth().optionalDraw(scrapeLoading))

    Row(horizontalArrangement = Arrangement.spacedBy(16.dp), verticalAlignment = Alignment.CenterVertically) {
        Column(Modifier.weight(1f), verticalArrangement = Arrangement.spacedBy(8.dp)) {
            TextField(
                entryEdit.title,
                onTitleChange,
                Modifier.fillMaxWidth(),
                label = { Text("Pavadinimas*") },
                keyboardOptions = KeyboardOptions(capitalization = KeyboardCapitalization.Sentences),
                trailingIcon = { PasteIconButton(onTitleChange) }
            )

            TextField(
                entryEdit.imageUrl,
                onImageUrlChange,
                Modifier.fillMaxWidth(),
                label = { Text("Paveikslėlio nuoroda") },
                placeholder = { Text("https://...") },
                trailingIcon = { PasteIconButton(onImageUrlChange) }
            )

            TextField(
                entryEdit.price,
                onPriceChange,
                Modifier.fillMaxWidth(),
                maxLines = 1,
                label = { Text("Kaina") },
                placeholder = { Text("€ 0.00") },
                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Decimal),
                trailingIcon = { PasteIconButton(onPriceChange) }
            )
        }

        if (!smallScreen) {
            EntryEditPreviewItem(
                entryEdit,
                modifier = Modifier.width(200.dp)
            )
        }
    }

    Row(Modifier.align(Alignment.End), horizontalArrangement = Arrangement.spacedBy(12.dp)) {
        OutlinedButton(onClickCancel) { Text("Atmesti") }
        Button(onClickSave, enabled = canSave) { Text("Išsaugoti") }
    }
}

@Composable
private fun PasteIconButton(
    onPaste: (String) -> Unit,
) {
    val scope = rememberCoroutineScope()
    val clipboard = LocalClipboardManager.current

    IconButton(
        onClick = {
            scope.launch {
                val text = clipboard.getText()?.text
                if (text != null) {
                    onPaste(text)
                }
            }
        }
    ) {
        Icon(AppIcons.ContentPaste, contentDescription = "Įklijuoti")
    }
}
