package com.koduok.lists.feature.registryEdit

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.rounded.AddBox
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.Surface
import com.koduok.lists.component.Switch
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.textField.BasicTextField
import com.koduok.lists.component.topBar.BackgroundTopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.registryEdit.RegistryEditViewModel.DialogContent.EditEntryDialog
import com.koduok.lists.feature.registryEdit.RegistryEditViewModel.Effect.OpenRegistry
import com.koduok.lists.model.Action
import com.koduok.lists.model.EntryEdit
import com.koduok.lists.model.RegistryEditType
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.RegistryEditRoute
import com.koduok.lists.routes.RegistryRoute
import com.koduok.lists.theme.*
import org.koin.core.parameter.parametersOf

val registryEditScreen
    get() = screen<RegistryEditRoute> {
        val router = LocalRouter.current
        val viewModel = rememberViewModel<RegistryEditViewModel> { parametersOf(it.editType) }
        val state by viewModel.collectAsStateAndEffects { effect ->
            when (effect) {
                is OpenRegistry -> if (router.any { route -> route is RegistryRoute && route.registryId == effect.registryId }) {
                    router.setResult<RegistryEditRoute.Result>(RegistryEditRoute.Result.Success)
                    router.popWhile { route -> route !is RegistryRoute || route.registryId != effect.registryId }
                } else {
                    router.replace(RegistryRoute(effect.registryId))
                }
            }
        }

        RegistryEditScreen(
            state = state,
            onClickBack = router::pop,
            onTryAgainClick = viewModel::onTryAgainClick,
            onTitleChanged = viewModel::onTitleChanged,
            onDescriptionChanged = viewModel::onDescriptionChanged,
            onTemplateToggle = viewModel::onTemplateToggled,
            onVisibilityToggle = viewModel::onVisibilityToggled,
            onNewEntryClick = viewModel::onNewEntryClick,
            onEditEntryClick = viewModel::onEditEntryClick,
            onSaveClick = viewModel::onSaveClick,
            onClickEntryMoveUp = viewModel::onClickEntryMoveUp,
            onClickEntryMoveDown = viewModel::onClickEntryMoveDown,
            onClickEntryDelete = viewModel::onClickEntryDelete,
        )

        when (val dialogContent = state.dialogContent) {
            is EditEntryDialog -> EntryEditDialog(
                dialogContent.entryEdit,
                onDismissRequest = viewModel::onDialogDismiss,
                onSave = viewModel::onSaveEntry,
            )

            null -> Unit
        }
    }

@Composable
private fun RegistryEditScreen(
    state: RegistryEditViewModel.State,
    onClickBack: () -> Unit,
    onTryAgainClick: () -> Unit,
    onTitleChanged: (String) -> Unit,
    onDescriptionChanged: (String) -> Unit,
    onTemplateToggle: () -> Unit,
    onVisibilityToggle: () -> Unit,
    onNewEntryClick: () -> Unit,
    onEditEntryClick: (entryEdit: EntryEdit) -> Unit,
    onClickEntryMoveUp: (EntryEdit) -> Unit,
    onClickEntryMoveDown: (EntryEdit) -> Unit,
    onClickEntryDelete: (EntryEdit) -> Unit,
    onSaveClick: () -> Unit,
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior()
    Scaffold(
        modifier = Modifier.nestedScroll(scrollBehavior.nestedScrollConnection),
        topBar = {
            BackgroundTopAppBar(
                scrollBehavior = scrollBehavior,
                title = {
                    Text(
                        when (state.screenState.valueOrNull?.type) {
                            is RegistryEditType.CopyRegistry -> "Kopijuoti sąrašą"
                            is RegistryEditType.EditRegistry -> "Redaguoti sąrašą"
                            is RegistryEditType.NewRegistry -> "Naujas sąrašas"
                            null -> ""
                        }
                    )
                },
                navigationIcon = { TopBarBackButton(onClickBack) })
        }
    ) { padding ->
        FullContent(state.screenState, Action(onTryAgainClick), Modifier.padding(padding)) { registryEdit ->
            BoxWithConstraints {
                val maxContentWidth = AppDimens.maxContentWidth
                val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 16.dp
                val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 16.dp)

                Column {
                    LazyVerticalGrid(
                        GridCells.Adaptive(AppDimens.gridItemMinWidth),
                        Modifier.fillMaxWidth().weight(1f),
                        verticalArrangement = Arrangement.spacedBy(8.dp),
                        horizontalArrangement = Arrangement.spacedBy(8.dp),
                        contentPadding = contentPadding
                    ) {
                        item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                            Column {
                                BasicTextField(
                                    registryEdit.title,
                                    onTitleChanged,
                                    Modifier.fillMaxWidth(),
                                    keyboardOptions = KeyboardOptions(capitalization = KeyboardCapitalization.Sentences),
                                    textStyle = AppType.headlineMedium,
                                    placeholder = "Pavadinimas",
                                )
                                Spacer(Modifier.height(8.dp))
                                BasicTextField(
                                    registryEdit.description,
                                    onDescriptionChanged,
                                    Modifier.fillMaxWidth(),
                                    keyboardOptions = KeyboardOptions(capitalization = KeyboardCapitalization.Sentences),
                                    textStyle = AppType.bodyLarge,
                                    placeholder = "Aprašymas",
                                )
                                Spacer(Modifier.height(24.dp))
//                            Surface(
//                                Modifier.fillMaxWidth(),
//                                color = AppColors.surfaceVariant,
//                                contentColor = AppColors.onSurfaceVariant,
//                                shape = AppShapes.large
//                            ) {
//                                Column(Modifier.padding(16.dp)) {
//                                    Switch(
//                                        "Privatus",
//                                        "Sąrašas pasiekiamas tik su nuoroda, kuria jūs pasidalinsite",
//                                        registryEdit.visibility == RegistryVisibility.Private,
//                                        onVisibilityToggle,
//                                    )
//                                    Spacer(Modifier.height(4.dp))
//                                    Switch(
//                                        "Šablonas",
//                                        "Dovanų rezervacija negalima",
//                                        registryEdit.isTemplate,
//                                        onTemplateToggle,
//                                    )
//                                }
//                            }
                            }

                        }

                        items(registryEdit.entries) { entryEdit ->
                            EntryEditItem(
                                entryEdit,
                                onClick = onEditEntryClick,
                                onClickMoveUp = onClickEntryMoveUp,
                                onClickMoveDown = onClickEntryMoveDown,
                                onClickDelete = onClickEntryDelete,
                                Modifier.fillMaxHeight(),
                            )
                        }
                    }

                    Surface(
                        Modifier.padding(horizontal = horizontalPadding),
                        color = AppColors.surfaceVariant,
                        contentColor = AppColors.onSurfaceVariant,
                        shape = AppShapes.large.flatBottom()
                    ) {
                        Row(
                            Modifier.heightIn(min = 56.dp).fillMaxWidth().padding(horizontal = 16.dp, vertical = 8.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            TextButton(onNewEntryClick) {
                                Icon(AppIcons.AddBox, "Pridėti naują dovaną")
                                Spacer(Modifier.width(8.dp))
                                Text("Pridėti naują dovaną")
                            }
                            Spacer(Modifier.weight(1f))
                            Button(onSaveClick, enabled = registryEdit.isValid) {
                                Text("Išsaugoti sąrašą")
                            }
                        }
                    }

                }

            }
        }
    }
}

@Composable
private fun Switch(
    text: String,
    explanation: String,
    checked: Boolean,
    onCheckedChange: () -> Unit,
    modifier: Modifier = Modifier,
) = Row(modifier, verticalAlignment = Alignment.CenterVertically) {
    Switch(checked, onCheckedChange = { onCheckedChange() })
    Spacer(Modifier.width(8.dp))
    Column {
        Text(text)
        Text(explanation, style = AppType.labelSmall)
    }
}
