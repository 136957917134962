package com.koduok.lists.ext

import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.buildAnnotatedString
import kotlinx.browser.window
import kotlinx.coroutines.await

class WebClipboardManager : ClipboardManager {
    override suspend fun getText(): AnnotatedString? {
        return runCatching { window.navigator.clipboard.readText().await().takeIf { it.isNotEmpty() }?.let { buildAnnotatedString { append(it) } } }.getOrNull()
    }

    override suspend fun setText(text: AnnotatedString?) {
        runCatching { window.navigator.clipboard.writeText(text?.text ?: "").await() }
    }
}
