package com.koduok.lists.theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Typography
import androidx.compose.material3.windowsizeclass.WindowSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import lists.shared_compose.generated.resources.*
import org.jetbrains.compose.resources.Font

inline val AppType @Composable @ReadOnlyComposable get() = MaterialTheme.typography

val AppFontFamily: FontFamily
    @Composable get() {
        val black = Font(Res.font.LibreFranklin_Black, FontWeight.Black)
        val blackItalic = Font(Res.font.LibreFranklin_Black, FontWeight.Black, FontStyle.Italic)
        val bold = Font(Res.font.LibreFranklin_Bold, FontWeight.Bold)
        val boldItalic = Font(Res.font.LibreFranklin_BoldItalic, FontWeight.Bold, FontStyle.Italic)
        val extraBold = Font(Res.font.LibreFranklin_ExtraBold, FontWeight.ExtraBold)
        val extraBoldItalic = Font(Res.font.LibreFranklin_ExtraBold, FontWeight.ExtraBold, FontStyle.Italic)
        val extraLight = Font(Res.font.LibreFranklin_ExtraLight, FontWeight.ExtraLight)
        val extraLightItalic = Font(Res.font.LibreFranklin_ExtraLightItalic, FontWeight.ExtraLight, FontStyle.Italic)
        val regular = Font(Res.font.LibreFranklin_Regular, FontWeight.Normal)
        val italic = Font(Res.font.LibreFranklin_Regular, FontWeight.Normal, FontStyle.Italic)
        val light = Font(Res.font.LibreFranklin_Light, FontWeight.Light)
        val lightItalic = Font(Res.font.LibreFranklin_Light, FontWeight.Light, FontStyle.Italic)
        val medium = Font(Res.font.LibreFranklin_Medium, FontWeight.Medium)
        val mediumItalic = Font(Res.font.LibreFranklin_Medium, FontWeight.Medium, FontStyle.Italic)
        val semiBold = Font(Res.font.LibreFranklin_SemiBold, FontWeight.SemiBold)
        val semiBoldItalic = Font(Res.font.LibreFranklin_SemiBoldItalic, FontWeight.SemiBold, FontStyle.Italic)
        val thin = Font(Res.font.LibreFranklin_Thin, FontWeight.Thin)
        val thinItalic = Font(Res.font.LibreFranklin_ThinItalic, FontWeight.Thin, FontStyle.Italic)

        return FontFamily(
            black,
            blackItalic,
            bold,
            boldItalic,
            extraBold,
            extraBoldItalic,
            extraLight,
            extraLightItalic,
            regular,
            italic,
            light,
            lightItalic,
            medium,
            mediumItalic,
            semiBold,
            semiBoldItalic,
            thin,
            thinItalic
        )
    }

@Composable
fun themeTypography(windowSizeClass: WindowSizeClass): Typography {
    return when (windowSizeClass.widthSizeClass) {
        WindowWidthSizeClass.Compact -> smallTypography(AppFontFamily)
        else -> defaultTypography(AppFontFamily)
    }
}

private fun defaultTypography(fontFamily: FontFamily): Typography {
    return fontFamilyTypography(fontFamily).let {
        it.copy(
            displayLarge = it.displayLarge.copy(fontWeight = FontWeight.Medium)
        )
    }
}

private fun smallTypography(fontFamily: FontFamily): Typography {
    return fontFamilyTypography(fontFamily).let {
        it.copy(
            displayLarge = it.displayLarge.copy(fontWeight = FontWeight.Medium, fontSize = 40.sp),
            titleLarge = it.titleLarge.copy(fontSize = 18.sp)
        )
    }
}

private fun fontFamilyTypography(fontFamily: FontFamily): Typography {
    return Typography().let {
        it.copy(
            displayLarge = it.displayLarge.copy(fontFamily = fontFamily),
            displayMedium = it.displayMedium.copy(fontFamily = fontFamily),
            displaySmall = it.displaySmall.copy(fontFamily = fontFamily),
            headlineLarge = it.headlineLarge.copy(fontFamily = fontFamily),
            headlineMedium = it.headlineMedium.copy(fontFamily = fontFamily),
            headlineSmall = it.headlineSmall.copy(fontFamily = fontFamily),
            titleLarge = it.titleLarge.copy(fontFamily = fontFamily),
            titleMedium = it.titleMedium.copy(fontFamily = fontFamily),
            titleSmall = it.titleSmall.copy(fontFamily = fontFamily),
            bodyLarge = it.bodyLarge.copy(fontFamily = fontFamily),
            bodyMedium = it.bodyMedium.copy(fontFamily = fontFamily),
            bodySmall = it.bodySmall.copy(fontFamily = fontFamily),
            labelLarge = it.labelLarge.copy(fontFamily = fontFamily),
            labelMedium = it.labelMedium.copy(fontFamily = fontFamily),
            labelSmall = it.labelSmall.copy(fontFamily = fontFamily),
        )
    }
}