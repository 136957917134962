package com.koduok.lists.feature.appUser

import com.koduok.lists.model.*
import com.koduok.lists.model.AuthenticationProvider.*
import com.koduok.lists.supabase.supabase
import io.github.jan.supabase.gotrue.SessionStatus
import io.github.jan.supabase.gotrue.auth
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.map
import kotlinx.serialization.json.jsonPrimitive
import org.koin.core.module.Module
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind

interface AppUserService {
    fun appUser(): Flow<AppUser>
    suspend fun getAppUser(): AppUser = appUser().first()
    suspend fun appUserIdOrNull(): UserId? = getAppUser().idOrNull
    suspend fun requireAppUserId(): UserId = getAppUser().requireId
}

private class AppUserRepository : AppUserService {

    override fun appUser(): Flow<AppUser> = supabase.auth.sessionStatus.map { sessionStatus ->
        when (sessionStatus) {
            is SessionStatus.Authenticated -> {
                val user = sessionStatus.session.user
                if (user != null) {
                    val authenticationProviders = user.identities
                        .orEmpty()
                        .mapNotNull {
                            when (it.provider) {

                                "email" -> EmailProvider(
                                    email = Email(it.identityData.getValue("email").jsonPrimitive.content),
                                    emailConfirmed = user.emailConfirmedAt != null
                                )

                                "google" -> GoogleProvider(
                                    email = Email(it.identityData.getValue("email").jsonPrimitive.content),
                                    name = it.identityData.getValue("name").jsonPrimitive.content,
                                    picture = it.identityData["avatar_url"]?.jsonPrimitive?.content?.let { url -> UrlImage(url) }
                                )

                                "facebook" -> FacebookProvider(
                                    email = Email(it.identityData.getValue("email").jsonPrimitive.content),
                                    name = it.identityData.getValue("name").jsonPrimitive.content,
                                    picture = it.identityData["avatar_url"]?.jsonPrimitive?.content?.let { url -> UrlImage(url) }
                                )

                                else -> null
                            }
                        }

                    AuthenticatedAppUser(
                        id = UserId(user.id),
                        providers = authenticationProviders,
                    )
                } else {
                    FailedAppUser
                }
            }

            SessionStatus.LoadingFromStorage -> LoadingAppUser
            SessionStatus.NetworkError -> FailedAppUser
            is SessionStatus.NotAuthenticated -> NotAuthenticatedAppUser
        }
    }

}

internal fun Module.appUserRepository() {
    singleOf(::AppUserRepository) bind AppUserService::class
}
