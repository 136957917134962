@file:OptIn(ExperimentalDecomposeApi::class, ExperimentalDecomposeApi::class)

package com.koduok.lists.navigation

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import com.koduok.lists.ext.LocalComponentContext
import com.koduok.lists.routes.HomeRoute
import com.koduok.lists.routes.NotFoundRoute
import com.koduok.lists.routes.Route
import kotlinx.serialization.serializer

class NavigationHost(
    componentContext: ComponentContext,
    deepLink: Path,
    webHistoryController: WebHistoryController,
    screenFactory: (Route, ComponentContext) -> Screen,
) : ComponentContext by componentContext {
    private val navigation = StackNavigation<Route>()
    private val stack = childStack(
        source = navigation,
        serializer = null,
        handleBackButton = true,
        initialStack = { getInitialStack(deepLink, webHistoryController.historyPaths) },
        childFactory = { route, componentContext -> screenFactory(route, componentContext) },
    )
    private val router = Router(navigation, stack)

    private fun getInitialStack(deepLink: Path, webHistoryPaths: List<String>): List<Route> {
        return webHistoryPaths
            .map { Route.from(Path(it)) }
            .filter { it !is NotFoundRoute }
            .distinct()
            .takeIf { it.isNotEmpty() }
            ?: listOfNotNull(HomeRoute(), Route.from(deepLink).takeIf { it !is NotFoundRoute }).distinct()
    }

    init {
        webHistoryController.attach(
            navigator = navigation,
            stack = stack,
            serializer = serializer(),
            getPath = { it.path },
            getConfiguration = { Route.from(Path(it)) },
        )
    }

    @Composable
    fun AppNavigation() {
        CompositionLocalProvider(LocalRouter provides router) {
            Children(
                stack,
                Modifier.fillMaxSize(),
                content = { child ->
                    CompositionLocalProvider(LocalComponentContext provides child.instance) {
                        child.instance.ScreenUi()
                    }
                },
            )
        }
    }
}
