package com.koduok.lists.analytics.events

import com.koduok.lists.analytics.AnalyticsEvent

data class ClickedSocialEvent(private val socialNetwork: SocialNetwork) : AnalyticsEvent {
    override val name = "clicked_social"
    override val parameters = mapOf("social_network" to socialNetwork.analytics)

    enum class SocialNetwork(val analytics: String) {
        Facebook("facebook"), Instagram("instagram")
    }
}