package com.koduok.lists.feature.registryEdit

import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.feature.registry.RegistryService
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryEdit
import com.koduok.lists.model.RegistryId
import com.koduok.lists.supabase.model.SupabaseRegistry
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface RegistryEditService {
    suspend fun getRegistry(registryId: RegistryId): Registry?
    suspend fun saveRegistry(registryEdit: RegistryEdit): RegistryId
}

private class RegistryEditRepository(
    private val appUserService: AppUserService,
    private val registryService: RegistryService,
) : RegistryEditService {
    override suspend fun getRegistry(registryId: RegistryId): Registry? {
        val registry = registryService.getRegistry(registryId) ?: return null

        val userId = appUserService.requireAppUserId()
        if (!registry.isAdmin(userId)) return null

        return registry
    }

    override suspend fun saveRegistry(registryEdit: RegistryEdit): RegistryId {
        val userId = appUserService.requireAppUserId()
        return SupabaseRegistry.save(userId, registryEdit)
    }
}

internal fun Module.registryEditRepository() {
    factoryOf(::RegistryEditRepository) bind RegistryEditService::class
}
