@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package lists.shared_compose.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val facebook: DrawableResource by 
      lazy { init_facebook() }

  public val featured: DrawableResource by 
      lazy { init_featured() }

  public val home1: DrawableResource by 
      lazy { init_home1() }

  public val home2: DrawableResource by 
      lazy { init_home2() }

  public val home3: DrawableResource by 
      lazy { init_home3() }

  public val home4: DrawableResource by 
      lazy { init_home4() }

  public val instagram: DrawableResource by 
      lazy { init_instagram() }

  public val logo: DrawableResource by 
      lazy { init_logo() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("facebook", CommonMainDrawable0.facebook)
  map.put("featured", CommonMainDrawable0.featured)
  map.put("home1", CommonMainDrawable0.home1)
  map.put("home2", CommonMainDrawable0.home2)
  map.put("home3", CommonMainDrawable0.home3)
  map.put("home4", CommonMainDrawable0.home4)
  map.put("instagram", CommonMainDrawable0.instagram)
  map.put("logo", CommonMainDrawable0.logo)
}

internal val Res.drawable.facebook: DrawableResource
  get() = CommonMainDrawable0.facebook

private fun init_facebook(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/drawable/facebook.xml", -1, -1),
    )
)

internal val Res.drawable.featured: DrawableResource
  get() = CommonMainDrawable0.featured

private fun init_featured(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:featured",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.DensityQualifier.XXXHDPI,
    ),
    "composeResources/lists.shared_compose.generated.resources/drawable-xxxhdpi/featured.png", -1, -1),
    )
)

internal val Res.drawable.home1: DrawableResource
  get() = CommonMainDrawable0.home1

private fun init_home1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:home1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.DensityQualifier.XXXHDPI,
    ),
    "composeResources/lists.shared_compose.generated.resources/drawable-xxxhdpi/home1.jpeg", -1, -1),
    )
)

internal val Res.drawable.home2: DrawableResource
  get() = CommonMainDrawable0.home2

private fun init_home2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:home2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.DensityQualifier.XXXHDPI,
    ),
    "composeResources/lists.shared_compose.generated.resources/drawable-xxxhdpi/home2.jpeg", -1, -1),
    )
)

internal val Res.drawable.home3: DrawableResource
  get() = CommonMainDrawable0.home3

private fun init_home3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:home3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.DensityQualifier.XXXHDPI,
    ),
    "composeResources/lists.shared_compose.generated.resources/drawable-xxxhdpi/home3.jpeg", -1, -1),
    )
)

internal val Res.drawable.home4: DrawableResource
  get() = CommonMainDrawable0.home4

private fun init_home4(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:home4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.DensityQualifier.XXXHDPI,
    ),
    "composeResources/lists.shared_compose.generated.resources/drawable-xxxhdpi/home4.jpeg", -1, -1),
    )
)

internal val Res.drawable.instagram: DrawableResource
  get() = CommonMainDrawable0.instagram

private fun init_instagram(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/drawable/instagram.xml", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/drawable/logo.xml", -1, -1),
    )
)
