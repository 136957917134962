package com.koduok.lists.navigation

import androidx.compose.runtime.staticCompositionLocalOf
import com.arkivanov.decompose.router.stack.*
import com.arkivanov.decompose.value.Value
import com.koduok.lists.routes.Route

val LocalRouter = staticCompositionLocalOf<Router> { throw IllegalStateException("LocalRouter not initialized") }

class Router(
    private val nav: StackNavigation<Route>,
    val stack: Value<ChildStack<Route, Screen>>,
) {
    fun push(route: Route) = nav.push(route)
    fun replace(route: Route) = nav.replaceCurrent(route)
    fun replaceAll(vararg route: Route) = nav.replaceAll(*route)
    fun pop(onComplete: (isSuccess: Boolean) -> Unit = {}) = nav.pop(onComplete)
    fun popWhile(predicate: (Route) -> Boolean) = nav.popWhile(predicate)
    fun any(predicate: (Route) -> Boolean) = stack.value.items.any { predicate(it.configuration) }

    // If caller expects an interface or a parent class, make sure to specify the type. e.g. setResult<ExpectedType>(result)
    inline fun <reified Result> setResult(result: Result) = stack.value.setResult(result)

    inline fun <reified Result> setResultAndPop(result: Result) {
        setResult(result)
        pop()
    }
}

fun <Result> ChildStack<Route, Screen>.setResult(result: Result) {
    backStack.forEach {
        val requests = it.instance.instanceKeeper.getRequestsOrNull()
        requests?.setResult(result)
    }
}
