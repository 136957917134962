package com.koduok.lists.supabase.model

import com.koduok.lists.errors.throwError
import com.koduok.lists.model.RegistryId
import com.koduok.lists.model.RegistryRole
import com.koduok.lists.model.RegistryUser
import com.koduok.lists.model.UserId
import com.koduok.lists.supabase.supabase
import io.github.jan.supabase.postgrest.postgrest
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put

@Serializable
data class SupabaseRegistryUser(
    @SerialName("registry_id") val registryId: String,
    @SerialName("user_id") val userId: String,
    val role: String,
) {
    companion object {
        val columns get() = Columns.raw("registry_id, user_id, role")

        suspend fun save(userId: UserId, registryId: RegistryId, role: RegistryRole) {
            val json = buildJsonObject {
                put("registry_id", registryId.value)
                put("user_id", userId.value)
                put("role", role.value)
            }

            runCatching { supabase.postgrest.from("registry_users").upsert(json) }
                .onFailure { it.throwError() }
                .getOrThrow()
        }
    }

    fun toDomain(): RegistryUser? {
        val role = RegistryRole.from(role)

        return if (role != null) {
            RegistryUser(
                userId = UserId(userId),
                role = role,
            )
        } else {
            null
        }
    }
}
