package com.koduok.lists.routes

import com.koduok.lists.model.ClientEnvironment
import com.koduok.lists.model.RegistryEditType
import com.koduok.lists.model.RegistryId
import com.koduok.lists.model.ResetPassword
import com.koduok.lists.navigation.Path
import kotlinx.serialization.Serializable

@Serializable
sealed class Route {
    abstract val path: String

    fun url(clientEnvironment: ClientEnvironment) = clientEnvironment.buildUrl(path)

    companion object {
        fun from(path: Path): Route {
            return listOf(
                { HomeRoute.fromOrNull(path) },
                { ForgotPasswordRoute.fromOrNull(path) },
                { ResetPasswordRoute.fromOrNull(path) },
                { RegisterRoute.fromOrNull(path) },
                { ProfileRoute.fromOrNull(path) },
                { RegistryEditRoute.fromOrNull(path) },
                { RegistryRoute.fromOrNull(path) },
                { TermsRoute.fromOrNull(path) },
            ).firstNotNullOfOrNull { it() } ?: NotFoundRoute
        }
    }
}

interface ResultRoute<Result>

@Serializable
data object NotFoundRoute : Route() {
    override val path: String get() = "/nowhere"
}

@Serializable
data class HomeRoute(override val fragment: LoginUrlFragment = LoginUrlFragment()) : Route(), LoginRoute2 {
    override val path: String get() = "/"

    companion object {
        fun fromOrNull(path: Path): HomeRoute? = when {
            path[0].isNullOrEmpty() -> HomeRoute(LoginUrlFragment(path.fragment))
            else -> null
        }
    }
}

interface LoginFlowRoute

interface LoginRoute2 {
    val fragment: LoginUrlFragment

    fun url(clientEnvironment: ClientEnvironment): String
}

@Serializable
data object ForgotPasswordRoute : Route(), LoginFlowRoute {
    override val path: String get() = "/forgot_password"

    fun fromOrNull(path: Path): ForgotPasswordRoute? = when (path[0]) {
        "forgot_password" -> ForgotPasswordRoute
        else -> null
    }
}

@Serializable
data class ResetPasswordRoute(val resetPassword: ResetPassword) : Route(), LoginFlowRoute {
    override val path: String get() = "/reset_password"

    companion object {
        fun fromOrNull(path: Path): ResetPasswordRoute? = when (path[0]) {
            "reset_password" -> ResetPassword.from(path)?.let { ResetPasswordRoute(it) }
            else -> null
        }
    }
}

@Serializable
data object RegisterRoute : Route(), LoginFlowRoute {
    override val path: String get() = "/register"

    fun fromOrNull(path: Path): RegisterRoute? = when (path[0]) {
        "register" -> RegisterRoute
        else -> null
    }
}

@Serializable
data class ProfileRoute(
    override val fragment: LoginUrlFragment = LoginUrlFragment(),
) : Route(), LoginRoute2 {
    override val path: String get() = "/profile"

    companion object {
        fun fromOrNull(path: Path): ProfileRoute? = when (path[0]) {
            "profile" -> ProfileRoute(LoginUrlFragment(path.fragment))
            else -> null
        }
    }
}

@Serializable
data class RegistryEditRoute(val editType: RegistryEditType) : Route(), ResultRoute<RegistryEditRoute.Result> {
    override val path: String
        get() = when (editType) {
            is RegistryEditType.CopyRegistry -> "/registry/${editType.copyRegistryId.value}/copy"
            is RegistryEditType.EditRegistry -> "/registry/${editType.registryId.value}/edit"
            RegistryEditType.NewRegistry -> "/registry/new"
        }

    companion object {
        fun fromOrNull(path: Path): RegistryEditRoute? {
            return when (path[0]) {
                "registry", "party" -> when (val registryId = path[1]) {
                    "new" -> RegistryEditRoute(RegistryEditType.NewRegistry)
                    null -> null
                    else -> when (path[2]) {
                        "edit" -> RegistryEditRoute(RegistryEditType.EditRegistry(RegistryId(registryId)))
                        "copy" -> RegistryEditRoute(RegistryEditType.CopyRegistry(RegistryId(registryId)))
                        else -> null
                    }
                }

                else -> null
            }
        }
    }

    sealed class Result {
        data object Success : Result()
    }
}

@Serializable
data class RegistryRoute(val registryId: RegistryId, override val fragment: LoginUrlFragment = LoginUrlFragment()) : Route(), LoginRoute2 {
    override val path: String get() = "/registry/${registryId.value}"

    companion object {
        fun fromOrNull(path: Path): RegistryRoute? {
            return when (path[0]) {
                "registry", "party" -> when {
                    path[1] == "new" -> null
                    path[1] == null -> null
                    path[2]?.isNotEmpty() == true -> null
                    else -> RegistryRoute(RegistryId(path[1]!!), LoginUrlFragment(path.fragment))
                }

                else -> null
            }
        }
    }
}

@Serializable
data class LoginUrlFragment(val value: String? = null) {
    val requireValue get() = requireNotNull(value)

    val isValid = !value.isNullOrEmpty()
}

@Serializable
data class TermsRoute(val routeType: Type) : Route() {
    override val path: String get() = "/terms"

    companion object {
        fun fromOrNull(path: Path): TermsRoute? = when (path[0]) {
            "terms" -> TermsRoute(
                when (path.queryParam("type")) {
                    "privacy" -> Type.Privacy
                    else -> Type.Terms
                }
            )

            else -> null
        }
    }

    enum class Type { Terms, Privacy }
}