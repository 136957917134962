package com.koduok.lists.feature.profile

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.LoggedOutEvent
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.*
import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.feature.profile.ProfileViewModel.Effect
import com.koduok.lists.feature.profile.ProfileViewModel.Effect.Close
import com.koduok.lists.feature.profile.ProfileViewModel.Effect.OpenChangePassword
import com.koduok.lists.feature.profile.ProfileViewModel.ModalContent.SignOutConfirmationModalContent
import com.koduok.lists.feature.profile.ProfileViewModel.State
import com.koduok.lists.model.AuthenticatedAppUser
import com.koduok.lists.model.FailedAppUser
import com.koduok.lists.model.LoadingAppUser
import com.koduok.lists.model.NotAuthenticatedAppUser
import kotlinx.coroutines.flow.collectLatest
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class ProfileViewModel(
    private val appUserService: AppUserService,
    private val service: ProfileService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        launchInViewModel {
            appUserService.appUser().collectLatest {
                when (it) {
                    is AuthenticatedAppUser -> updateState { state.withAppUser(it) }
                    LoadingAppUser -> Unit
                    FailedAppUser -> effect(Close)
                    NotAuthenticatedAppUser -> effect(Close)
                }
            }
        }
    }

    fun onSignOutClick() = updateState { state.askSignOutConfirmation() }
    fun onClickConfirmSignOut() = signOut()
    fun onClickCancelSignOut() = updateState { state.dismissModal() }

    private fun signOut() = launchUniqueIfNotRunning("sign_out") {
        updateState { state.requestLoading() }
        runCatching { service.signOut() }
            .onSuccess { analytics.track(LoggedOutEvent) }
            .onSuccess { effect(Close) }
            .onFailureLogNonFatal("Failed to sign out") { updateState { state.requestFailed(it) } }
    }

    fun onClickErrorPrimaryAction() = updateState { state.requestIdle() }

    fun onClickEmail() {
        val appUser = state.appUserState.valueOrNull ?: return
        if (appUser.emailProvider != null) {
            effect(OpenChangePassword)
        }
    }

    data class State(
        internal val appUserState: LoadState<AuthenticatedAppUser> = LoadState.Loading(),
        internal val requestState: LoadState<Unit> = LoadState.Idle,
        val modalContent: ModalContent? = null,
    ) {
        val screenState = LoadState.merge(appUserState, requestState) { appUser, _ ->
            appUser?.value?.let { DisplayData(it, showChangePassword = it.emailProvider != null).asLoadedValue() }
        }

        internal fun withAppUser(appUser: AuthenticatedAppUser) = copy(appUserState = appUser.asLoaded())
        internal fun requestIdle() = copy(requestState = LoadState.Idle, modalContent = null)
        internal fun requestLoading() = copy(requestState = LoadState.Loading(), modalContent = null)
        internal fun requestFailed(error: Throwable) = copy(requestState = error.asFailed(), modalContent = null)
        internal fun askSignOutConfirmation() = copy(modalContent = SignOutConfirmationModalContent)
        internal fun dismissModal() = copy(modalContent = null)
    }

    sealed class Effect {
        data object Close : Effect()
        data object OpenChangePassword : Effect()
    }

    sealed class ModalContent {
        data object SignOutConfirmationModalContent : ModalContent()
    }

    data class DisplayData(
        val appUser: AuthenticatedAppUser,
        val showChangePassword: Boolean,
    )
}

internal fun Module.profileViewModel() {
    factoryOf(::ProfileViewModel)
}
