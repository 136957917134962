package com.koduok.lists.model

import com.koduok.lists.ext.*
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
data class Entry(
    val id: EntryId,
    val title: String,
    val url: String?,
    val image: UrlImage?,
    val reserved: Boolean,
    val reservedUserId: UserId?,
    val price: Money?,
    val order: Int,
) {
    val analytics
        get() = mapOf(
            "entry_id" to id.value,
            "entry_domain" to url?.urlDomain.orEmpty(),
            "entry_price" to price?.toPlainAmountString().orEmpty(),
            "entry_currency" to price?.currency?.code?.value.orEmpty(),
            "entry_reserved" to reserved,
        )
}

data class DisplayEntry(
    val entry: Entry,
    val action: EntryAction,
)

enum class EntryAction {
    Reserve,
    Release,
    Reserved,
    None,
    ;

    val visible get() = this != None
    val enabled get() = this != Reserved
}

@JvmInline
@Serializable
value class EntryId(val value: String)

fun fDisplayEntry() = DisplayEntry(
    entry = fEntry(),
    action = EntryAction.entries.random(),
)

fun fEntry() = Entry(
    id = fEntryId(),
    title = fTextPhrase(),
    url = "https://www.google.com",
    image = fUrlImage(),
    reserved = fBoolean(),
    reservedUserId = fUserId(),
    price = fMoney(),
    order = fInt(),
)

fun fEntryId() = EntryId(fTextId())
