package com.koduok.lists.ext

import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.text.AnnotatedString

val LocalClipboardManager = staticCompositionLocalOf<ClipboardManager> { error("Not initialized") }

interface ClipboardManager {
    suspend fun getText(): AnnotatedString?
    suspend fun setText(text: AnnotatedString?)
}
