package com.koduok.lists.ext

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import com.koduok.lists.theme.AppShapes
import dev.chrisbanes.haze.HazeDefaults
import dev.chrisbanes.haze.HazeState
import dev.chrisbanes.haze.hazeChild

@Composable
fun Modifier.hazeChildDefault(state: HazeState, shape: Shape = AppShapes.medium) = this then Modifier.hazeChild(
    state = state,
    shape = shape,
    style = HazeDefaults.style(tint = Color.White.copy(0.4f), noiseFactor = 0.3f)
)
