@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package lists.shared_compose.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val LibreFranklin_Black: FontResource by 
      lazy { init_LibreFranklin_Black() }

  public val LibreFranklin_BlackItalic: FontResource by 
      lazy { init_LibreFranklin_BlackItalic() }

  public val LibreFranklin_Bold: FontResource by 
      lazy { init_LibreFranklin_Bold() }

  public val LibreFranklin_BoldItalic: FontResource by 
      lazy { init_LibreFranklin_BoldItalic() }

  public val LibreFranklin_ExtraBold: FontResource by 
      lazy { init_LibreFranklin_ExtraBold() }

  public val LibreFranklin_ExtraBoldItalic: FontResource by 
      lazy { init_LibreFranklin_ExtraBoldItalic() }

  public val LibreFranklin_ExtraLight: FontResource by 
      lazy { init_LibreFranklin_ExtraLight() }

  public val LibreFranklin_ExtraLightItalic: FontResource by 
      lazy { init_LibreFranklin_ExtraLightItalic() }

  public val LibreFranklin_Italic: FontResource by 
      lazy { init_LibreFranklin_Italic() }

  public val LibreFranklin_Light: FontResource by 
      lazy { init_LibreFranklin_Light() }

  public val LibreFranklin_LightItalic: FontResource by 
      lazy { init_LibreFranklin_LightItalic() }

  public val LibreFranklin_Medium: FontResource by 
      lazy { init_LibreFranklin_Medium() }

  public val LibreFranklin_MediumItalic: FontResource by 
      lazy { init_LibreFranklin_MediumItalic() }

  public val LibreFranklin_Regular: FontResource by 
      lazy { init_LibreFranklin_Regular() }

  public val LibreFranklin_SemiBold: FontResource by 
      lazy { init_LibreFranklin_SemiBold() }

  public val LibreFranklin_SemiBoldItalic: FontResource by 
      lazy { init_LibreFranklin_SemiBoldItalic() }

  public val LibreFranklin_Thin: FontResource by 
      lazy { init_LibreFranklin_Thin() }

  public val LibreFranklin_ThinItalic: FontResource by 
      lazy { init_LibreFranklin_ThinItalic() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("LibreFranklin_Black", CommonMainFont0.LibreFranklin_Black)
  map.put("LibreFranklin_BlackItalic", CommonMainFont0.LibreFranklin_BlackItalic)
  map.put("LibreFranklin_Bold", CommonMainFont0.LibreFranklin_Bold)
  map.put("LibreFranklin_BoldItalic", CommonMainFont0.LibreFranklin_BoldItalic)
  map.put("LibreFranklin_ExtraBold", CommonMainFont0.LibreFranklin_ExtraBold)
  map.put("LibreFranklin_ExtraBoldItalic", CommonMainFont0.LibreFranklin_ExtraBoldItalic)
  map.put("LibreFranklin_ExtraLight", CommonMainFont0.LibreFranklin_ExtraLight)
  map.put("LibreFranklin_ExtraLightItalic", CommonMainFont0.LibreFranklin_ExtraLightItalic)
  map.put("LibreFranklin_Italic", CommonMainFont0.LibreFranklin_Italic)
  map.put("LibreFranklin_Light", CommonMainFont0.LibreFranklin_Light)
  map.put("LibreFranklin_LightItalic", CommonMainFont0.LibreFranklin_LightItalic)
  map.put("LibreFranklin_Medium", CommonMainFont0.LibreFranklin_Medium)
  map.put("LibreFranklin_MediumItalic", CommonMainFont0.LibreFranklin_MediumItalic)
  map.put("LibreFranklin_Regular", CommonMainFont0.LibreFranklin_Regular)
  map.put("LibreFranklin_SemiBold", CommonMainFont0.LibreFranklin_SemiBold)
  map.put("LibreFranklin_SemiBoldItalic", CommonMainFont0.LibreFranklin_SemiBoldItalic)
  map.put("LibreFranklin_Thin", CommonMainFont0.LibreFranklin_Thin)
  map.put("LibreFranklin_ThinItalic", CommonMainFont0.LibreFranklin_ThinItalic)
}

internal val Res.font.LibreFranklin_Black: FontResource
  get() = CommonMainFont0.LibreFranklin_Black

private fun init_LibreFranklin_Black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Black.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_BlackItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_BlackItalic

private fun init_LibreFranklin_BlackItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_BlackItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-BlackItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Bold: FontResource
  get() = CommonMainFont0.LibreFranklin_Bold

private fun init_LibreFranklin_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Bold.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_BoldItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_BoldItalic

private fun init_LibreFranklin_BoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_BoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-BoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_ExtraBold: FontResource
  get() = CommonMainFont0.LibreFranklin_ExtraBold

private fun init_LibreFranklin_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-ExtraBold.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_ExtraBoldItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_ExtraBoldItalic

private fun init_LibreFranklin_ExtraBoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_ExtraBoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-ExtraBoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_ExtraLight: FontResource
  get() = CommonMainFont0.LibreFranklin_ExtraLight

private fun init_LibreFranklin_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-ExtraLight.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_ExtraLightItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_ExtraLightItalic

private fun init_LibreFranklin_ExtraLightItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_ExtraLightItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-ExtraLightItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Italic: FontResource
  get() = CommonMainFont0.LibreFranklin_Italic

private fun init_LibreFranklin_Italic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Italic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Light: FontResource
  get() = CommonMainFont0.LibreFranklin_Light

private fun init_LibreFranklin_Light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Light.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_LightItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_LightItalic

private fun init_LibreFranklin_LightItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_LightItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-LightItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Medium: FontResource
  get() = CommonMainFont0.LibreFranklin_Medium

private fun init_LibreFranklin_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Medium.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_MediumItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_MediumItalic

private fun init_LibreFranklin_MediumItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_MediumItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-MediumItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Regular: FontResource
  get() = CommonMainFont0.LibreFranklin_Regular

private fun init_LibreFranklin_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Regular.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_SemiBold: FontResource
  get() = CommonMainFont0.LibreFranklin_SemiBold

private fun init_LibreFranklin_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-SemiBold.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_SemiBoldItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_SemiBoldItalic

private fun init_LibreFranklin_SemiBoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_SemiBoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-SemiBoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_Thin: FontResource
  get() = CommonMainFont0.LibreFranklin_Thin

private fun init_LibreFranklin_Thin(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-Thin.ttf", -1, -1),
    )
)

internal val Res.font.LibreFranklin_ThinItalic: FontResource
  get() = CommonMainFont0.LibreFranklin_ThinItalic

private fun init_LibreFranklin_ThinItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:LibreFranklin_ThinItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/lists.shared_compose.generated.resources/font/LibreFranklin-ThinItalic.ttf", -1, -1),
    )
)
