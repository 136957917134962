package com.koduok.lists.feature.profile

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.automirrored.rounded.Logout
import androidx.compose.material.icons.rounded.Password
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.content.FullScreenSmallContentBox
import com.koduok.lists.component.content.SmallContentSurface
import com.koduok.lists.component.dialog.AlertDialog
import com.koduok.lists.component.media.Image
import com.koduok.lists.component.topBar.TopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.asLoaded
import com.koduok.lists.feature.profile.ProfileViewModel.Effect.Close
import com.koduok.lists.feature.profile.ProfileViewModel.Effect.OpenChangePassword
import com.koduok.lists.feature.profile.ProfileViewModel.ModalContent.SignOutConfirmationModalContent
import com.koduok.lists.model.Action
import com.koduok.lists.model.fAuthenticatedAppUser
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.ForgotPasswordRoute
import com.koduok.lists.routes.HomeRoute
import com.koduok.lists.routes.ProfileRoute
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppIconsAutoMirrored
import org.koin.core.parameter.parametersOf

val profileScreen
    get() = screen<ProfileRoute> {
        val router = LocalRouter.current
        val viewModel = rememberViewModel<ProfileViewModel> { parametersOf(it.fragment) }
        val state by viewModel.collectAsStateAndEffects {
            when (it) {
                Close -> router.replaceAll(HomeRoute())
                OpenChangePassword -> router.push(ForgotPasswordRoute)
            }
        }

        when (state.modalContent) {
            SignOutConfirmationModalContent -> AlertDialog(
                onDismissRequest = viewModel::onClickCancelSignOut,
                confirmAction = Action(viewModel::onClickConfirmSignOut, "Atsijungti"),
                dismissAction = Action(viewModel::onClickCancelSignOut, "Likti prisijungus"),
                icon = AppIconsAutoMirrored.Logout,
                title = "Ar tikrai norite atsijungti?",
                message = "Bet kada galite prisijungti vėl ir rasite savo sukurtus sąrašus."
            )

            null -> Unit
        }

        ProfileScreen(
            state = state,
            onBackClick = router::pop,
            onSignOutClick = viewModel::onSignOutClick,
            onClickErrorPrimaryAction = viewModel::onClickErrorPrimaryAction,
            onClickChangePassword = viewModel::onClickEmail,
        )
    }

@Composable
private fun ProfileScreen(
    state: ProfileViewModel.State,
    onBackClick: () -> Unit,
    onSignOutClick: () -> Unit,
    onClickErrorPrimaryAction: () -> Unit,
    onClickChangePassword: () -> Unit,
) {
    Scaffold(
        topBar = { TopAppBar(title = {}, navigationIcon = { TopBarBackButton(onBackClick) }) },
        containerColor = AppColors.surfaceVariant,
        contentColor = AppColors.onSurfaceVariant,
    ) {
        FullContent(
            state.screenState,
            Action(onClickErrorPrimaryAction),
            Modifier.padding(it),
        ) { displayData ->
            val appUser = displayData.appUser
            FullScreenSmallContentBox {
                SmallContentSurface {
                    Column {
                        ListItem(
                            leadingContent = {
                                Image(
                                    appUser.picture, Modifier.size(24.dp).clip(CircleShape), contentScale = ContentScale.Crop
                                )
                            },
                            headlineContent = { Text(appUser.name ?: appUser.email?.value ?: "") },
                            supportingContent = if (appUser.name != null && appUser.email != null) {
                                { Text(appUser.email?.value.orEmpty()) }
                            } else {
                                null
                            },
                        )

                        if (displayData.showChangePassword) {
                            HorizontalDivider()
                            ListItem(
                                modifier = Modifier.clickable(onClick = onClickChangePassword),
                                leadingContent = { Icon(AppIcons.Password, null, Modifier.size(24.dp)) },
                                headlineContent = { Text("Keisti slaptažodį") },
                            )
                        }

                        HorizontalDivider(Modifier.padding(bottom = 16.dp))

                        TextButton(
                            onSignOutClick, Modifier.fillMaxWidth(), colors = ButtonDefaults.textButtonColors(contentColor = AppColors.error)
                        ) {
                            Text("Atsijungti")
                        }
                    }
                }
            }

        }
    }
}

@Preview
@Composable
private fun Preview() = PreviewContainer {
    ProfileScreen(
        state = ProfileViewModel.State(appUserState = fAuthenticatedAppUser().asLoaded()),
        onBackClick = {},
        onSignOutClick = {},
        onClickErrorPrimaryAction = {},
        onClickChangePassword = {}
    )
}
