@file:OptIn(ExperimentalDecomposeApi::class, ExperimentalCoilApi::class)

import androidx.compose.ui.window.CanvasBasedWindow
import coil3.ImageLoader
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.setSingletonImageLoaderFactory
import coil3.memory.MemoryCache
import coil3.request.CachePolicy
import coil3.request.crossfade
import coil3.util.DebugLogger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.FirebaseAnalytics
import com.koduok.lists.ext.WebClipboardManager
import com.koduok.lists.model.ClientEnvironment
import com.koduok.lists.navigation.AllScreens
import com.koduok.lists.navigation.NavigationHost
import com.koduok.lists.navigation.Path
import com.koduok.lists.routes.Route
import com.koduok.lists.sharedClientModule
import com.koduok.lists.sharedModule
import com.koduok.lists.theme.Theme
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import io.github.aakira.napier.DebugAntilog
import io.github.aakira.napier.Napier
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.koin.core.context.startKoin
import org.koin.core.module.dsl.singleOf
import org.koin.dsl.bind
import org.koin.dsl.module

// Deploy:
// ./gradlew :webApp:jsBrowserProductionWebpack
// ./gradlew :webApp:jsBrowserDistribution
// cd webApp
// firebase deploy
// firebase hosting:channel:deploy beta
//./gradlew :webApp:jsBrowserRun -t
fun main() {
    Napier.base(DebugAntilog())
    Firebase.initialize(
        null,
        FirebaseOptions(
            applicationId = "1:1082287670321:web:48c29f87b8956607c78110",
            apiKey = "AIzaSyDEgx0fwagJInTyIUd2KWOyMR92tI8kjls",
            storageBucket = "party-plan-1337.appspot.com",
            projectId = "party-plan-1337",
            gcmSenderId = "1082287670321",
            authDomain = "dovanusarasas.lt"
        ),
    )

    startKoin {
        modules(
            sharedModule,
            sharedClientModule,
            module {
                singleOf(::FirebaseAnalytics) bind Analytics::class
                single<ClientEnvironment> {
                    ClientEnvironment(
                        scheme = window.location.protocol.removeSuffix(":"),
                        host = window.location.hostname,
                        port = window.location.port.toIntOrNull(),
                    )
                }
            }
        )
    }

    val rootComponentContext = DefaultComponentContext(lifecycle = LifecycleRegistry())
    val webHistoryController = DefaultWebHistoryController()

    val navigationHost = NavigationHost(
        componentContext = rootComponentContext,
        deepLink = Path(window.location.pathname + window.location.search + window.location.hash),
        webHistoryController = webHistoryController,
        screenFactory = { route: Route, componentContext: ComponentContext -> AllScreens.build(route, componentContext) }
    )

    val clipboardManager = WebClipboardManager()

    onWasmReady {
        CanvasBasedWindow("Dovanų sąrašas", canvasElementId = "ComposeTarget") {
            setSingletonImageLoaderFactory { context ->
                ImageLoader
                    .Builder(context)
                    .memoryCachePolicy(CachePolicy.ENABLED)
                    .memoryCache {
                        MemoryCache.Builder()
                            .maxSizePercent(context, 0.5)
                            .strongReferencesEnabled(true)
                            .build()
                    }
//                .diskCachePolicy(CachePolicy.ENABLED)
//                .diskCache {
//                    DiskCache.Builder()
//                        .directory(FileSystem.SYSTEM_TEMPORARY_DIRECTORY / "lists_image_cache")
//                        .maxSizeBytes(512L * 1024 * 1024) // 512MB
//                        .build()
//                }
                    .networkCachePolicy(CachePolicy.ENABLED)
                    .crossfade(true)
                    .logger(DebugLogger())
                    .build()
            }

            Theme(clipboardManager = clipboardManager) {
                navigationHost.AppNavigation()
            }
        }
    }
}

//fun main() {
//    startKoin {
//        modules(
//            sharedModule,
//            webModule,
//        )
//    }
//
//    val lifecycle = LifecycleRegistry()
//    val navHost = NavHost(
//        componentContext = DefaultComponentContext(lifecycle = lifecycle),
//        initialStack = { listOf(HomeRoute) },
//        deepLink = Path(window.location.pathname + window.location.search),
//        screenFactory = AllScreens::build,
//        routeFactory = AllScreens::createRoute,
//        webHistoryController = DefaultWebHistoryController(),
//    )
//
//    lifecycle.attachToDocument()
//
//    renderComposable(rootElementId = "root") {
//        Style(AppStylesheet)
//
//        var width by remember { mutableIntStateOf(window.innerWidth) }
//        var height by remember { mutableIntStateOf(window.innerHeight) }
//        window.addEventListener(
//            "resize",
//            callback = {
//                width = window.innerWidth
//                height = window.innerHeight
//            },
//        )
//
//        WebTheme(width, height) {
//            val appColors = AppColors
//            Span(
//                attrs = {
//                    style {
//                        Variables.transparentInputHoverColor.set(this, appColors.primary.withAlpha(0.1f).ui)
//                        Variables.transparentInputActiveColor.set(this, appColors.primary.withAlpha(0.3f).ui)
//                        Variables.outlineColor.set(this, appColors.outline.ui)
//                    }
//                }
//            ) {
//                navHost.AppNavigation()
//            }
//        }
//    }
//}
//
//private fun LifecycleRegistry.attachToDocument() {
//    fun onVisibilityChanged() {
//        if (document.visibilityState == "visible") {
//            resume()
//        } else {
//            stop()
//        }
//    }
//
//    onVisibilityChanged()
//
//    document.addEventListener(type = "visibilitychange", callback = { onVisibilityChanged() })
//}
//

//private fun LifecycleRegistry.attachToDocument() {
//    fun onVisibilityChanged(event: Event) {
////        if (document.visibilityState == "visible") {
////            resume()
////        } else {
////            stop()
////        }
//    }
//
//    onVisibilityChanged()
//
//    document.addEventListener(type = "visibilitychange", callback = { onVisibilityChanged() })
//}

//private val Document.visibilityState: String
//    get() = toJsReference().get(). asDynamic().visibilityState.unsafeCast<String>()
