package com.koduok.lists.component.dialog

import androidx.compose.foundation.layout.imePadding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.window.DialogProperties
import com.koduok.lists.component.Surface
import com.koduok.lists.theme.AppShapes

@Composable
fun Dialog(
    onDismissRequest: () -> Unit,
    dialogProperties: DialogProperties = DialogProperties(),
    content: @Composable () -> Unit,
) {
    androidx.compose.ui.window.Dialog(
        onDismissRequest = onDismissRequest,
        properties = dialogProperties,
        content = {
            Surface(Modifier.imePadding(), shape = AppShapes.large) {
                content()
            }
        }
    )
}