package com.koduok.lists.component.textField

import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.input.OffsetMapping
import androidx.compose.ui.text.input.TransformedText
import androidx.compose.ui.text.input.VisualTransformation

object PasswordTransformation : VisualTransformation {
    override fun filter(text: AnnotatedString) =
        TransformedText(buildAnnotatedString { append("*".repeat(text.text.length)) }, OffsetMapping.Identity)
}
