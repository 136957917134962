package com.koduok.lists.feature.home

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.ClickedSocialEvent
import com.koduok.lists.analytics.events.ClickedSocialEvent.SocialNetwork.Facebook
import com.koduok.lists.analytics.events.ClickedSocialEvent.SocialNetwork.Instagram
import com.koduok.lists.analytics.events.CreateRegistryEvent
import com.koduok.lists.analytics.events.OpenRegistryEvent
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.*
import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.feature.home.HomeViewModel.DisplayData.GuestDisplayData
import com.koduok.lists.feature.home.HomeViewModel.DisplayData.UserDisplayData
import com.koduok.lists.feature.home.HomeViewModel.Effect
import com.koduok.lists.feature.home.HomeViewModel.Effect.*
import com.koduok.lists.feature.home.HomeViewModel.State
import com.koduok.lists.feature.login.LoginViewModel
import com.koduok.lists.feature.login.LoginViewModel.LoginReason.CreateRegistry
import com.koduok.lists.model.*
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class HomeViewModel(
    private val appUserService: AppUserService,
    private val service: HomeService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        launchInViewModel {
            appUserService
                .appUser()
                .filter { it !is LoadingAppUser }
                .distinctUntilChanged()
                .collectLatest {
                    statesFlow {
                        emit(state.withAppUser(it))
                        refresh()
                    }
                }
        }
    }

    fun onResume() {
        if (state.appUserState.valueOrNull != null) {
            refresh()
        }
    }

    private fun refresh() = launchUnique("refresh") {
        val featuredLoad = asyncInViewModel {
            updateState { state.featuredLoading() }
            runCatching { service.getFeaturedRegistries() }
                .onSuccess { updateState { state.featuredLoaded(it) } }
                .onFailureLogNonFatal("Failed to get featured registries") { updateState { state.featuredFailed(it) } }
        }

        val usersLoad = asyncInViewModel {
            val appUser = state.appUserState.valueOrNull

            if (appUser.isLoggedIn) {
                updateState { state.userRegistriesLoading() }
                runCatching { service.getAppUserRegistries() }
                    .onSuccess { updateState { state.userRegistriesLoaded(it) } }
                    .onFailureLogNonFatal("Failed to get app user registries") {
                        updateState { state.userRegistriesFailed(it) }
                    }
            }
        }

        featuredLoad.await()
        usersLoad.await()
    }

    fun onClickCreateRegistry() = launchUniqueIfNotRunning("create_registry") {
        if (state.appUserState.valueOrNull?.isLoggedIn == true) {
            analytics.track(CreateRegistryEvent)
            effect(ShowEditRegistry(RegistryEditType.NewRegistry))
        } else {
            effect(OpenLogin(CreateRegistry))
        }
    }

    fun onClickRegistry(registryId: RegistryId) {
        analytics.track(OpenRegistryEvent(registryId))
        effect(ShowRegistry(registryId))
    }

    fun onClickSocialFacebook() {
        analytics.track(ClickedSocialEvent(Facebook))
        effect(OpenUrl("https://www.facebook.com/profile.php?id=61553352842524"))
    }

    fun onClickSocialInstagram() {
        analytics.track(ClickedSocialEvent(Instagram))
        effect(OpenUrl("https://www.instagram.com/dovanusarasas/"))
    }

    data class State(
        internal val appUserState: LoadState<AppUser> = LoadState.Loading(),
        internal val featuredRegistriesState: LoadState<List<Registry>> = LoadState.Loading(),
        internal val userRegistriesState: LoadState<List<Registry>> = LoadState.Idle,
    ) {
        val screenState: LoadState<DisplayData> = when {
            appUserState.valueOrNull == null -> LoadState.Loading()

            appUserState.valueOrNull.isLoggedIn -> LoadState.merge(
                userRegistriesState,
                featuredRegistriesState
            ) { users, featured ->
                if (users != null && featured != null) {
                    (UserDisplayData(users.value, featured.value)).asLoadedValue()
                } else {
                    null
                }
            }

            else -> featuredRegistriesState.mapValue {
                if (it != null) {
                    GuestDisplayData(it)
                } else {
                    null
                }
            }
        }

        internal fun withAppUser(appUser: AppUser) = copy(appUserState = appUser.asLoaded())

        internal fun featuredLoading() = copy(featuredRegistriesState = featuredRegistriesState.asLoading())
        internal fun featuredLoaded(registries: List<Registry>) = copy(featuredRegistriesState = registries.asLoaded())
        internal fun featuredFailed(error: Throwable) =
            copy(featuredRegistriesState = featuredRegistriesState.asFailed(error))

        internal fun userRegistriesLoading() = copy(userRegistriesState = userRegistriesState.asLoading())
        internal fun userRegistriesLoaded(registries: List<Registry>) =
            copy(userRegistriesState = registries.asLoaded())

        internal fun userRegistriesFailed(error: Throwable) =
            copy(userRegistriesState = userRegistriesState.asFailed(error))
    }

    sealed class Effect {
        data class ShowEditRegistry(val registryEditType: RegistryEditType) : Effect()
        data class ShowRegistry(val registryId: RegistryId) : Effect()
        data class OpenLogin(val reason: LoginViewModel.LoginReason) : Effect()
        data class OpenUrl(val url: String) : Effect()
    }

    sealed class DisplayData {
        abstract val featuredRegistries: List<Registry>

        data class UserDisplayData(
            val userRegistries: List<Registry>,
            override val featuredRegistries: List<Registry>,
        ) : DisplayData()

        data class GuestDisplayData(
            override val featuredRegistries: List<Registry>,
        ) : DisplayData()
    }
}

internal fun Module.homeViewModel() {
    factoryOf(::HomeViewModel)
}
