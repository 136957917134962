package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.*
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.feature.registry.RegistryItem
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppDimens
import com.koduok.lists.theme.AppType

@Composable
internal fun UserHomeScreen(
    userRegistries: List<Registry>,
    featuredRegistries: List<Registry>,
    onClickNewRegistry: () -> Unit,
    onClickRegistry: (RegistryId) -> Unit,
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
) {
    BoxWithConstraints {
        val maxContentWidth = AppDimens.maxContentWidth
        val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 8.dp
        val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 16.dp)

        LazyVerticalGrid(
            GridCells.Adaptive(AppDimens.gridItemMinWidth),
            Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            contentPadding = contentPadding
        ) {
            homeScreenUserRegistries(userRegistries, onClickRegistry, onClickNewRegistry)
            homeScreenFeaturedRegistries(featuredRegistries, onClickRegistry)

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HomeScreenFooter(onClickSocialFacebook, onClickSocialInstagram)
            }
        }
    }
}

private fun LazyGridScope.homeScreenUserRegistries(
    userRegistries: List<Registry>,
    onClickRegistry: (RegistryId) -> Unit,
    onClickNewRegistry: () -> Unit,
) {
    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        Column(Modifier.padding(vertical = 32.dp, horizontal = 16.dp), horizontalAlignment = Alignment.CenterHorizontally) {
            Text(
                "Tavo sąrašai",
                style = AppType.displayMedium,
                textAlign = TextAlign.Center,
                color = AppColors.primary
            )

            Spacer(Modifier.height(24.dp))

            Button(onClickNewRegistry) {
                Text("Kurti sąrašą".uppercase())
            }

            Spacer(Modifier.height(24.dp))
        }
    }

    items(userRegistries) { registry ->
        RegistryItem(
            onClick = { onClickRegistry(registry.id) },
            registry = registry,
            modifier = Modifier.fillMaxWidth().aspectRatio(0.75f),
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {}
}

