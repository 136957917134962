package com.koduok.lists.component.dialog

import androidx.compose.foundation.layout.size
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.DialogProperties
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.model.Action

@Composable
fun AlertDialog(
    onDismissRequest: () -> Unit,
    confirmAction: Action,
    modifier: Modifier = Modifier,
    dismissAction: Action? = null,
    icon: ImageVector? = null,
    title: String? = null,
    message: String? = null,
) = AlertDialog(
    onDismissRequest,
    modifier = modifier,
    properties = DialogProperties(),
    confirmButton = { Button(confirmAction.action) { Text(confirmAction.label) } },
    dismissButton = dismissAction?.let { { TextButton(it.action) { Text(it.label) } } },
    icon = icon?.let { { Icon(it, null, Modifier.size(56.dp)) } },
    title = title?.let { { Text(it) } },
    text = message?.let { { Text(it) } },
//    shape =,
//    containerColor = AppColors.background,
//    iconContentColor = AppColors.primary,
//    titleContentColor = AppColors.onBackground,
//    textContentColor = AppColors.onBackground,
)