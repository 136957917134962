package com.koduok.lists.component.content

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.koduok.lists.theme.AppColors

@Composable
fun LoadingContent(modifier: Modifier = Modifier) {
    Box(modifier.background(AppColors.background), contentAlignment = Alignment.Center) {
        CircularProgressIndicator()
    }
}

@Composable
fun BoxScope.FullLoadingContent(modifier: Modifier = Modifier) = LoadingContent(Modifier.matchParentSize() then modifier)
