package com.koduok.lists.model

import kotlinx.serialization.Serializable

@Serializable
data class RegistryUser(
    val userId: UserId,
    val role: RegistryRole,
)

enum class RegistryRole(val value: String) {
    Admin("admin")
    ;

    companion object {
        fun from(value: String) = entries.find { it.value == value }
    }
}

fun fRegistryUser() = RegistryUser(
    userId = fUserId(),
    role = fRegistryRole(),
)

fun fRegistryRole() = RegistryRole.entries.random()
