package com.koduok.lists.analytics.events

import com.koduok.lists.analytics.AnalyticsEvent
import com.koduok.lists.model.Entry
import com.koduok.lists.model.RegistryId

data class OpenEntryEvent(val registryId: RegistryId, val entry: Entry) : AnalyticsEvent {
    override val name = "open_entry"
    override val parameters = registryId.analytics + entry.analytics
}
