package com.koduok.lists.theme

import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.windowsizeclass.WindowSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import com.koduok.lists.ext.ClipboardManager
import com.koduok.lists.ext.LocalClipboardManager

val LocalWindowSizeClass = staticCompositionLocalOf<WindowSizeClass> { error("Not set") }

@Composable
fun Theme(
    dark: Boolean = false,
    clipboardManager: ClipboardManager?,
    content: @Composable () -> Unit,
) {
    val windowSizeClass = calculateWindowSizeClass()
    val targetClipboardManager = remember(clipboardManager) {
        clipboardManager ?: object : ClipboardManager {
            override suspend fun getText(): AnnotatedString? = null
            override suspend fun setText(text: AnnotatedString?) = Unit
        }
    }

    MaterialTheme(
        colorScheme = if (dark) themeDarkColorScheme else themeLightColorScheme,
        shapes = themeShapes,
        typography = themeTypography(windowSizeClass),
    ) {
        CompositionLocalProvider(
            LocalWindowSizeClass provides windowSizeClass,
            LocalTextStyle provides LocalTextStyle.current.merge(TextStyle(fontFamily = AppFontFamily)),
            LocalDimens provides themeDimens(windowSizeClass),
            LocalClipboardManager provides targetClipboardManager
        ) {
            content()
        }
    }
}
