package com.koduok.lists.component.form

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppType

@Composable
fun Form(
    title: String,
    message: String,
    fields: @Composable ColumnScope.() -> Unit,
    actions: @Composable ColumnScope.() -> Unit,
    modifier: Modifier = Modifier,
) = Column(modifier, horizontalAlignment = Alignment.CenterHorizontally) {

    Text(
        title,
        Modifier.padding(horizontal = 16.dp),
        textAlign = TextAlign.Center,
        style = AppType.headlineLarge,
        fontWeight = FontWeight.SemiBold
    )

    Text(
        message,
        Modifier.padding(start = 16.dp, end = 16.dp, top = 8.dp, bottom = 24.dp),
        textAlign = TextAlign.Center,
        style = AppType.bodyMedium,
    )

    fields()
    Spacer(Modifier.height(24.dp))
    actions()
}
