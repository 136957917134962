package com.koduok.lists.feature.forgotPassword

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.automirrored.rounded.ForwardToInbox
import androidx.compose.material.icons.rounded.Email
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.text.input.KeyboardType
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.content.FullScreenSmallContentBox
import com.koduok.lists.component.content.FullScreenSmallContentMessage
import com.koduok.lists.component.content.SmallContentSurface
import com.koduok.lists.component.form.Form
import com.koduok.lists.component.textField.TextField
import com.koduok.lists.component.topBar.TopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.Effect.Close
import com.koduok.lists.feature.forgotPassword.ForgotPasswordViewModel.Effect.OpenGmail
import com.koduok.lists.model.Action
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.ForgotPasswordRoute
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppIconsAutoMirrored

val forgotPasswordScreen
    get() = screen<ForgotPasswordRoute> {
        val router = LocalRouter.current
        val uriHandler = LocalUriHandler.current
        val viewModel = rememberViewModel<ForgotPasswordViewModel>()
        val state by viewModel.collectAsStateAndEffects {
            when (it) {
                Close -> router.pop()
                is OpenGmail -> uriHandler.openUri(it.uri)
            }
        }

        ForgotPasswordScreen(
            state = state,
            onEmailChange = viewModel::onEmailChanged,
            onBackClick = router::pop,
            onForgotClick = viewModel::onForgotPasswordClick,
            onTryAgainClick = viewModel::onTryAgainClick,
            onCloseClick = viewModel::onCloseClick,
            onGmailClick = viewModel::onGmailClick,
        )
    }

@Composable
private fun ForgotPasswordScreen(
    state: ForgotPasswordViewModel.State,
    onEmailChange: (String) -> Unit,
    onBackClick: () -> Unit,
    onForgotClick: () -> Unit,
    onTryAgainClick: () -> Unit,
    onCloseClick: () -> Unit,
    onGmailClick: () -> Unit,
) {
    Scaffold(
        topBar = { TopAppBar(title = {}, navigationIcon = { TopBarBackButton(onBackClick) }) },
        containerColor = AppColors.surfaceVariant,
        contentColor = AppColors.onSurfaceVariant,
    ) {
        FullContent(
            state = state.forgotPasswordState,
            primaryAction = Action(onTryAgainClick),
            modifier = Modifier.padding(it),
            idle = {
                FullScreenSmallContentBox {
                    ForgotPasswordForm(
                        email = state.email,
                        onEmailChange = onEmailChange,
                        onForgotClick = onForgotClick,
                    )
                }
            },
            loaded = {
                FullScreenSmallContentMessage(
                    icon = AppIconsAutoMirrored.ForwardToInbox,
                    iconTint = AppColors.primary,
                    title = "Instrukcijos išsiųstos sėkmingai",
                    message = "Atsidaryk savo el. pašto programą ir paspausk ant pateiktos nuorodos",
                    primaryAction = Action(onCloseClick, "Gerai"),
                    secondaryAction = Action(onGmailClick, "Atidaryti Gmail")
                )
            }
        )
    }
}

@Composable
private fun ForgotPasswordForm(
    email: String,
    onEmailChange: (String) -> Unit,
    onForgotClick: () -> Unit,
    modifier: Modifier = Modifier,
) = SmallContentSurface(modifier) {
    Form(
        title = "Pamiršai slaptažodį?",
        message = "Įvesk savo el. pašto adresą, kurį nurodei registracijos metu ir gauk tolesnę slaptažodžio pakeitimo instrukciją.",
        fields = {
            TextField(
                email,
                onEmailChange,
                Modifier.fillMaxWidth(),
                leadingIcon = { Icon(AppIcons.Email, "El. paštas") },
                singleLine = true,
                label = { Text("El. paštas") },
                keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Email),
            )
        },
        actions = {
            Button(onForgotClick, Modifier.fillMaxWidth()) { Text("Gauti instrukciją") }
        }
    )
}
