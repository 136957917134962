package com.koduok.lists.feature.terms

import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.koduok.lists.component.topBar.TopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.TermsRoute

val termsScreen
    get() = screen<TermsRoute> {
        val router = LocalRouter.current
        TermsScreen(it.routeType, onClickBack = router::pop)
    }

@Composable
private fun TermsScreen(
    type: TermsRoute.Type,
    onClickBack: () -> Unit,
) {
    Scaffold(
        topBar = {
            TopAppBar(
                title = {
                    Text(
                        when (type) {
                            TermsRoute.Type.Terms -> "Naudojimosi sąlygos"
                            TermsRoute.Type.Privacy -> "Privatumo politika"
                        }
                    )
                },
                navigationIcon = { TopBarBackButton(onClickBack) }
            )
        },
        content = {
            val text = when (type) {
                TermsRoute.Type.Terms -> buildString {
                    append("Bendrosios nuostatos ir platformos naudojimo taisyklės\n")
                    append("\n")
                    append("Kiekvieną kartą naudodamiesi Portalu turite būti susipažinę su šiomis Sąlygomis, ypač, jeigu naudojatės patarimais susijusiais su fizine ar psichine sveikata. Naudodamiesi šia Svetaine patvirtinate, jog esate susipažinę su šiomis Sąlygomis ir įsipareigojate besąlygiškai laikytis jų. Platforma yra skirta vartotojams sudaryti norimų prekių sąrašą su nuorodomis į kitas svetaines ir juo pasidalinti su kitais platformos vartotojais. Vartotojams suteikiama galimybė matyti, kurios norimos prekės yra laisvos ir kurios rezervuotos. Platforma neprisiima atsakomybės užtikrinti šių statusų fakto atitikimą - tai pačių vartotojų atsakomybė. Platforma neįsipareigoja užtikrinti pateiktų kainų tikrumo, tai yra trečiųjų šalių pateikta arba vartotojų sukurta informacija. Platforma pasilieka teisę deaktyvuoti vartotoją ar pašalinti sąrašą kilus įtarimui, jog jis neatitinka teisės aktų ar moralės normų.\n")
                    append("\n")
                    append("\n")
                    append("Prieinamumas ir atsakomybė\n")
                    append("\n")
                    append("Nors dedame visas pastangas, kad Svetainė būtų prieinama visada, mes neprisiimame atsakomybės, jei dėl techninių priežasčių bus negalima užtikrinti nepertraukiamo Portalo prieinamumo.\n")
                    append("\n")
                    append("\n")
                    append("Klientų aptarnavimas\n")
                    append("\n")
                    append("Iškilus klausimams ir (arba) problemoms, susijusioms su Portalu, galite susisiekti el. paštu dovanusarasas@gmail.com. Mes padarysime viską, ką galime, ir atsakysime kaip įmanoma greičiau.")
                }

                TermsRoute.Type.Privacy -> buildString {
                    append("Redaguota ir galioja nuo: 2023-11-11\n")
                    append("\n")
                    append("1. Bendrosios nuostatos\n")
                    append("\n")
                    append("Ši duomenų apsaugos politika taikoma internetiniam portalui www.dovanusarasas.lt („Portalas“), kurį valdo MB Produktyviai. Vienas iš svarbiausių mūsų prioritetų yra mūsų lankytojų privatumas. Šiame privatumo politikos dokumente pateikiama informacija, kurią renka ir registruoja Dovanų sąrašas, tipai ir kaip mes ją naudojame. Visus duomenis renkame, saugome ir tvarkome, vadovaudamiesi Europos Sąjungos Bendruoju Duomenų Apsaugos Reglamentu Nr. 2016/679, kitų teisės aktų nuostatomis ir neperduodame jokioms trečiosioms šalims, nebent tai padaryti būtume įpareigoti teisės aktais arba jeigu tai būtų būtina mūsų paslaugoms teikti.\n")
                    append("\n")
                    append("\n")
                    append("2. Sutikimas\n")
                    append("\n")
                    append("Perskaitę šį dokumentą, jūs sužinosite, kaip saugomi ir kam naudojami jūsų pateikti asmens duomenys. Naudodamiesi mūsų svetaine, jūs sutinkate su mūsų privatumo politika ir sutinkate su jos sąlygomis. . Portalas gali būti atnaujinamas ir pildomas naujomis funkcijomis, todėl atitinkamai atnaujinamos ir šios nuostatos. Jeigu kiltų klausimų dėl Privatumo politikos, susisiekite su mumis el. paštu dovanusarasas@gmail.com. Šios Privatumo politikos nuostatos galioja visiems Portalo lankytojams, taip pat veiksmams, kuriuos jie gali atlikti Portale.\n")
                    append("\n")
                    append("\n")
                    append("3. Renkama informacija\n")
                    append("\n")
                    append("Asmeninė informacija, kurią prašoma pateikti, ir priežastys, dėl kurių prašoma ją pateikti, jums bus paaiškintos tuo metu, kai prašysime pateikti asmeninę informaciją.\n")
                    append("\n")
                    append("\n")
                    append("4. Vartotojo registracija\n")
                    append("\n")
                    append("Tam, kad galėtumėte naudotis dovanų sąrašo sukūrimo funkcija,, jums reikalinga Dovanų sąrašo paskyra. Šiam tikslui Portale turite užsiregistruoti kaip vartotojas. Tam, kad galėtumėte naudotis mūsų Portalo funkcija sukurti ir išsaugoti dovanų sąrašą, jums reikalinga Dovanų Sąrašo paskyra, kurią galite susikurti per “Google” paskyrą.\n")
                    append("\n")
                    append("\n")
                    append("5. Duomenų rinkimas ir tvarkymas\n")
                    append("\n")
                    append("\n")
                    append("\n")
                    append("5.1. Vartotojo registracija\n")
                    append("\n")
                    append("\n")
                    append("\n")
                    append("5.1.1. Registracija per jūsų “Google“ paskyrą\n")
                    append("\n")
                    append("Jeigu registruojatės per „Google“ paskyrą, iš mūsų Portalo būsite nukreipti „Google“ svetainę ir paprašyti įvesti savo „Google“ paskyros prisijungimo duomenis. Jums įvedus „Google“ prisijungimo duomenis, iš „Google“ mes gauname toliau nurodytus jūsų „Facebook“ arba „Google“ paskyros duomenis: Vardą, Pavardę, Elektroninio pašto adresą.\n")
                    append("\n")
                    append("\n")
                    append("5.2. Kaip naudojame jūsų informaciją\n")
                    append("\n")
                    append("Surinktą informaciją naudojame įvairiais būdais, įskaitant:\n")
                    append("• Teikti, valdyti ir prižiūrėti mūsų svetainę;\n")
                    append("• Tobulinti, pritaikyti ir plėsti mūsų svetainę;\n")
                    append("• Suprasti ir analizuoti, kaip naudojatės mūsų svetaine;\n")
                    append("• Naujų produktų, paslaugų, funkcijų ir funkcionalumo kūrimui;\n")
                    append("• Bendrauti su jumis tiesiogiai arba per vieną iš mūsų partnerių, įskaitant klientų aptarnavimo, atnaujinimų ir kitos su svetaine susijusios informacijos teikimo, rinkodaros ir reklamos tikslais;\n")
                    append("• Siųsti jums el. Laiškus;\n")
                    append("• Ieškoti sukčiavimo atvejų ir užkirsti jiems kelią;\n")
                    append("\n")
                    append("\n")
                    append("6. CCPA privatumo teisės\n")
                    append("\n")
                    append("Pagal CCPA, be kitų teisių nustatymus, vartotojai turi teisę:\n")
                    append("• reikalauti, kad vartotojo asmens duomenis renkanti įmonė atskleistų, kokių kategorijų ir kokius konkrečius asmens duomenis apie vartotojus ji surinko.\n")
                    append("• reikalauti, kad įmonė ištrintų visus įmonės surinktus vartotojo asmens duomenis.\n")
                    append("• reikalauti, kad įmonė, kuri parduoda vartotojo asmens duomenis, neparduotų vartotojo asmens duomenų.\n")
                    append("Jei pateikiate užklausą, atsakymą turime pateikti per mėnesį. Jei norite pasinaudoti bet kuria iš šių teisių, susisiekite su mumis dovanusarasas@gmail.com\n")
                    append("\n")
                    append("\n")
                    append("7. BDAR duomenų apsaugos teisės\n")
                    append("\n")
                    append("Norėtume įsitikinti, kad žinote visas savo teises, susijusias su duomenų apsauga. Kiekvienas naudotojas turi šias teises:\n")
                    append("• Teisė susipažinti - turite teisę prašyti savo asmens duomenų kopijų. Už šią paslaugą galime imti nedidelį mokestį.\n")
                    append("• Teisė į ištaisymą - turite teisę prašyti, kad ištaisytume bet kokią informaciją, kuri, jūsų manymu, yra netiksli. Taip pat turite teisę prašyti papildyti, jūsų manymu, neišsamią informaciją.\n")
                    append("• Teisė ištrinti duomenis - tam tikromis sąlygomis turite teisę prašyti, kad ištrintume jūsų asmens duomenis.\n")
                    append("• Teisė apriboti duomenų tvarkymą - tam tikromis sąlygomis turite teisę prašyti, kad apribotume jūsų asmens duomenų tvarkymą.\n")
                    append("• Teisė nesutikti su duomenų tvarkymu - tam tikromis sąlygomis turite teisę nesutikti, kad tvarkytume jūsų asmens duomenis.\n")
                    append("• Teisė į duomenų perkėlimą - turite teisę prašyti, kad tam tikromis sąlygomis perduotume surinktus duomenis kitai organizacijai arba tiesiogiai jums.\n")
                    append("Jei pateikiate užklausą, atsakymą turime pateikti per mėnesį. Jei norite pasinaudoti bet kuria iš šių teisių, susisiekite su mumis.\n")
                    append("Kontaktai iškilus klausimams\n")
                    append("dovanusarasas@gmail.com\n")
                }
            }
            Text(text, Modifier.verticalScroll(rememberScrollState()).padding(it))
        }
    )
}
