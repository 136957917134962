package com.koduok.lists.api.model

import com.koduok.lists.model.Money
import com.koduok.lists.model.ScrapeResult
import com.koduok.lists.model.UrlImage
import kotlinx.serialization.Serializable

@Serializable
data class ScrapeResponse(
    val title: String?,
    val imageUrl: String?,
    val price: String?,
) {
    fun toDomain() = ScrapeResult(
        title = title,
        image = imageUrl?.let { UrlImage(it) },
        price = Money.from(price),
    )
}
