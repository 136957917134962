package com.koduok.lists.feature.registry

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.media.Image
import com.koduok.lists.model.Registry
import com.koduok.lists.model.fRegistry
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.AppType
import com.koduok.lists.theme.LocalWindowSizeClass
import dev.chrisbanes.haze.HazeDefaults
import dev.chrisbanes.haze.HazeState
import dev.chrisbanes.haze.haze
import dev.chrisbanes.haze.hazeChild

@Composable
fun RegistryItem(
    onClick: () -> Unit,
    registry: Registry,
    modifier: Modifier = Modifier,
) {
    val hazeState = remember { HazeState() }
    Card(
        onClick,
        modifier,
        shape = RectangleShape,
        colors = CardDefaults.cardColors(containerColor = AppColors.surfaceVariant, contentColor = AppColors.onSurfaceVariant),
    ) {
        Box(modifier, contentAlignment = Alignment.Center) {
            Image(
                registry.image,
                Modifier.matchParentSize().haze(hazeState, HazeDefaults.style(backgroundColor = AppColors.surfaceVariant)),
                contentScale = ContentScale.Crop
            )
            val smallScreen = LocalWindowSizeClass.current.widthSizeClass == WindowWidthSizeClass.Compact
            val padding = if (smallScreen) 16.dp else 24.dp
            Box(
                Modifier.padding(padding)
                    .hazeChild(hazeState, shape = AppShapes.small, style = HazeDefaults.style(tint = Color.White.copy(0.4f), noiseFactor = 0.3f)),
                contentAlignment = Alignment.Center
            ) {
                Text(
                    "",
                    Modifier.fillMaxWidth().padding(padding),
                    style = AppType.titleMedium,
                    fontWeight = FontWeight.Medium,
                    textAlign = TextAlign.Center,
                    color = AppColors.primary,
                    maxLines = 3,
                    minLines = 3,
                    overflow = TextOverflow.Ellipsis
                )

                Text(
                    registry.title,
                    Modifier.fillMaxWidth().padding(padding),
                    style = AppType.titleMedium,
                    fontWeight = FontWeight.Medium,
                    textAlign = TextAlign.Center,
                    color = AppColors.primary,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis
                )
            }
        }
    }
}

@Composable
@Preview
private fun Preview() = PreviewContainer {
    RegistryItem(
        onClick = {},
        registry = fRegistry(),
    )
}
