package com.koduok.lists.feature.appUser

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.rounded.AccountCircle
import androidx.compose.material.icons.rounded.Person
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.media.Image
import com.koduok.lists.component.topBar.TopAppBarActionButton
import com.koduok.lists.component.topBar.TopAppBarActionButtonType
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.Collapsed
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.Expanded
import com.koduok.lists.ext.LocalComponentContext
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.appUser.AppUserViewModel.Effect.ShowLogin
import com.koduok.lists.feature.appUser.AppUserViewModel.Effect.ShowProfile
import com.koduok.lists.feature.login.LoginViewModel
import com.koduok.lists.feature.login.LoginViewModel.LoginReason.UserInitiated
import com.koduok.lists.model.AppUser
import com.koduok.lists.model.fAuthenticatedAppUser
import com.koduok.lists.model.fGoogleProvider
import com.koduok.lists.model.fUrlImageProfile
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.routes.ProfileRoute
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons

@Composable
fun AppUserButton(
    loginViewModel: LoginViewModel,
    type: TopAppBarActionButtonType,
    modifier: Modifier = Modifier,
) {
    val router = LocalRouter.current
    val viewModel = LocalComponentContext.current.rememberViewModel<AppUserViewModel>()

    val state by viewModel.collectAsStateAndEffects {
        when (it) {
            ShowLogin -> loginViewModel.showLogin(UserInitiated)
            ShowProfile -> router.push(ProfileRoute())
        }
    }

    if (state.appUser.isLoggedIn) {
        ProfileButton(viewModel::onClick, state.appUser, type, modifier)
    } else {
        LogInButton(viewModel::onClick, type, modifier)
    }
}

@Composable
private fun LogInButton(
    onClick: () -> Unit,
    type: TopAppBarActionButtonType,
    modifier: Modifier = Modifier,
) = TopAppBarActionButton(onClick, type, "Prisijungti", modifier) {
    Icon(AppIcons.Person, "Prisijungti")
}

@Composable
private fun ProfileButton(
    onClick: () -> Unit,
    appUser: AppUser,
    type: TopAppBarActionButtonType,
    modifier: Modifier = Modifier,
) = TopAppBarActionButton(onClick, type, "Profilis", modifier) {
    val picture = appUser.pictureOrNull
    if (picture != null) {
        Image(picture, Modifier.clip(CircleShape).border(1.dp, AppColors.primary, CircleShape), contentScale = ContentScale.Crop)
    } else {
        Icon(AppIcons.AccountCircle, "Profilis")
    }
}

@Preview
@Composable
private fun Preview() = PreviewContainer {
    Column {
        LogInButton(onClick = {}, Expanded)
        LogInButton(onClick = {}, Collapsed)
        ProfileButton(onClick = {}, fAuthenticatedAppUser(providers = listOf(fGoogleProvider(picture = fUrlImageProfile()))), Expanded)
        ProfileButton(onClick = {}, fAuthenticatedAppUser(providers = listOf(fGoogleProvider(picture = fUrlImageProfile()))), Collapsed)
        ProfileButton(onClick = {}, fAuthenticatedAppUser(providers = listOf(fGoogleProvider(picture = null))), Expanded)
        ProfileButton(onClick = {}, fAuthenticatedAppUser(providers = listOf(fGoogleProvider(picture = null))), Collapsed)
    }
}
