package com.koduok.lists.model

import kotlinx.serialization.Serializable

data class RegistryEdit(
    val type: RegistryEditType,
    val title: String = "",
    val description: String = "",
    val isTemplate: Boolean = false,
    val visibility: RegistryVisibility = RegistryVisibility.Private,
    val entries: List<EntryEdit> = emptyList(),
    val deleteEntryIds: List<EditEntryId> = emptyList(),
) {
    companion object {
        fun from(type: RegistryEditType, registry: Registry) = RegistryEdit(
            type = type,
            title = if (type is RegistryEditType.CopyRegistry) "" else registry.title,
            description = if (type is RegistryEditType.CopyRegistry) "" else registry.description.orEmpty(),
            isTemplate = if (type is RegistryEditType.CopyRegistry) false else registry.isTemplate,
            visibility = if (type is RegistryEditType.CopyRegistry) RegistryVisibility.Private else registry.visibility,
            entries = registry.entries.map { EntryEdit.from(it, registryCopy = type is RegistryEditType.CopyRegistry) },
        )
    }

    val isValid = title.isNotBlank()

    fun withTitle(title: String) = copy(title = title)
    fun withDescription(description: String) = copy(description = description)
    fun withToggledVisibility() =
        copy(visibility = if (visibility == RegistryVisibility.Private) RegistryVisibility.Public else RegistryVisibility.Private)

    fun withToggledTemplate() = copy(isTemplate = !isTemplate)
    fun withEntryEdit(entryEdit: EntryEdit): RegistryEdit {
        return if (entries.any { it.editEntryId == entryEdit.editEntryId }) {
            copy(entries = entries.map { if (it.editEntryId == entryEdit.editEntryId) entryEdit else it })
        } else {
            copy(entries = listOf(entryEdit) + entries)
        }
    }

    fun withEntryEditUp(entryEdit: EntryEdit): RegistryEdit {
        val mutableEntries = entries.toMutableList()
        val index = mutableEntries.indexOfFirst { it.editEntryId == entryEdit.editEntryId }
        val entryToMove = mutableEntries.removeAt(index)
        mutableEntries.add((index - 1).coerceAtLeast(0), entryToMove)
        return copy(entries = mutableEntries)
    }

    fun withEntryEditDown(entryEdit: EntryEdit): RegistryEdit {
        val mutableEntries = entries.toMutableList()
        val index = mutableEntries.indexOfFirst { it.editEntryId == entryEdit.editEntryId }
        val entryToMove = mutableEntries.removeAt(index)
        mutableEntries.add((index + 1).coerceAtMost(entries.size - 1), entryToMove)
        return copy(entries = mutableEntries)
    }

    fun withEntryDelete(entryEdit: EntryEdit): RegistryEdit {
        return copy(
            entries = entries.filter { it.editEntryId != entryEdit.editEntryId },
            deleteEntryIds = if (entryEdit.editEntryId is StoredEditEntryId) deleteEntryIds + entryEdit.editEntryId else deleteEntryIds,
        )
    }

    val analytics get() = mapOf("has_description" to description.isNotBlank()) + type.analytics
}

@Serializable
sealed class RegistryEditType {
    val analytics
        get() = when (this) {
            is CopyRegistry -> mapOf("copy_registry_id" to copyRegistryId.value)
            is EditRegistry -> registryId.analytics
            NewRegistry -> emptyMap()
        }

    @Serializable
    data object NewRegistry : RegistryEditType()

    @Serializable
    data class CopyRegistry(val copyRegistryId: RegistryId) : RegistryEditType()

    @Serializable
    data class EditRegistry(val registryId: RegistryId) : RegistryEditType()
}
